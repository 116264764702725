import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonButton,
  IonRouterLink,
  IonImg,
} from "@ionic/react";
// import ExploreContainer from "../components/ExploreContainer";
import "./Landing.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

const Landing = () => {
  return (
    <IonPage>
      {/* <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Get Started</IonTitle>
        </IonToolbar>
      </IonHeader> */}
      <IonContent className="ion-padding">
        {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Landing</IonTitle>
          </IonToolbar>
        </IonHeader> */}
        <div class="background-image">
          <div className="container">
            {/* <h1 style={{ fontWeight: "700" }}>Zestfully</h1> */}

            <IonImg
              style={{
                width: "250px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src="https://my-recipa-app-images.s3.us-west-1.amazonaws.com/app-assets/zestfully-logo.jpg"
            ></IonImg>

            <h3>
              Save time and money with delicous personalized recipes that help
              you achieve your health goals.
            </h3>
            {/* <strong>
              Save time and money with delivious personalized recipes that help
              you achive your health goals.
            </strong> */}
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p> */}
            <div className="ion-padding">
              <IonRouterLink routerLink="/register">
                <IonButton color="primary">Sign Up</IonButton>
              </IonRouterLink>
              <IonRouterLink routerLink="/login">
                <IonButton color="secondary">Log in</IonButton>
              </IonRouterLink>
            </div>
          </div>
        </div>
        {/* <ExploreContainer /> */}
      </IonContent>
    </IonPage>
  );
};

export default Landing;
