import { useHistory, useLocation } from "react-router-dom";

import styled from "styled-components";

import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonProgressBar,
  IonSegment,
  IonSegmentButton,
  handleChange,
  IonFabButton,
  IonFab,
  IonRouterLink,
  IonIcon,
} from "@ionic/react";

import { add } from "ionicons/icons";

import { useAppContext } from "../context/appContext";

const IonFabStyled = styled(IonFab)`
  // @media (min-width: 875px) {
  //   margin-right: 25px;
  // }
`;

const Fab = () => {
  const { isLoading, discoverSelectedPage, handleChange, clearRecipeValues } =
    useAppContext();

  const history = useHistory();

  const location = useLocation();
  const isFromMealPlanPage = location.pathname.includes("/meal-plan");

  //   const updateDiscoverSelectedPage = (e) => {
  //     handleChange({
  //       name: "discoverSelectedPage",
  //       value: e.target.value,
  //     });

  //     history.push(e.target.value);
  //   };

  return (
    <>
      <div style={{ marginBottom: "70px" }}> </div>
      {/* <IonFabStyled slot="fixed" vertical="bottom" horizontal="end"> */}

      <IonFabStyled slot="fixed" vertical="bottom" horizontal="end">
        <IonRouterLink
          routerLink={
            isFromMealPlanPage
              ? "/my/meal-plan/create-recipe-or-food"
              : "/my/discover/collection/create-recipe-or-food"
          }
          onClick={() => clearRecipeValues()}
        >
          <IonFabButton>
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
        </IonRouterLink>
      </IonFabStyled>
    </>
  );
};

export default Fab;
