import { useAppContext } from "../context/appContext";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import styled from "styled-components";

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonImg,
  IonList,
  IonCol,
  IonButton,
  IonSegment,
  IonSegmentButton,
  IonChip,
  IonItem,
  IonText,
  IonLabel,
  IonIcon,
  IonRouterLink,
  IonActionSheet,
  IonFabButton,
  IonFab,
} from "@ionic/react";

import { ellipsisVertical, add } from "ionicons/icons";

import { tabWeeks } from "../utils/FormattingUtils";

// import RecipeCard from "../components/RecipeCard.js";

import { RecipeCard, Fab } from "../components";

const WebWidthCardContainer = styled.div`
  margin: auto;
  max-width: 750px;
`;

const CardWidthContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const IonFabStyled = styled(IonFab)`
  // @media (min-width: 875px) {
  //   margin-right: 25px;
  // }
`;

const MealPlan = () => {
  const {
    getMealPlan,
    mealPlanRecipes,
    mealPlanSchedule,
    user,
    isLoading,
    week,
    handleChange,
    mealPlanMacrosOrSummaryView,
    updateMealPlanMacrosOrSummaryView,
    removeMealPlanRecipe,
    setEditServingUpdateServing,
    clearRecipeValues,
    getRecipe,
  } = useAppContext();

  const history = useHistory();

  const [open, setOpen] = useState(false);

  const [ellipsisDropdownRecipe, setEllipsisDropdownRecipe] = useState(null);

  let { mealPlanMacroSelectedMember } = useAppContext();

  useEffect(() => {
    getMealPlan();
    // eslint-disable-next-line
  }, [week]);

  const updateWeek = (e) => {
    handleChange({ name: "week", value: e.target.value });
  };

  const updateMealPlanMacroSelectedMember = (e) => {
    handleChange({
      name: "mealPlanMacroSelectedMember",
      value: e.target.value,
    });
  };

  const openAndSetEllipsisDropdown = (recipe) => {
    setOpen(true);
    setEllipsisDropdownRecipe(recipe);
  };

  const setEditRecipeIdAndRedirect = (id) => {
    handleChange({
      name: "editRecipeId",
      value: id,
    });
    history.push(`/my/meal-plan/recipe/${id}`);
  };

  const handleSetEditServingUpdateServing = (id, personalizedYield, name) => {
    setEditServingUpdateServing(id, personalizedYield, name);
    history.push("/my/serving/edit");
  };

  // const setGetRecipe = (id) => {
  //   getRecipe(id);
  //   history.push("/my/edit-recipe-or-food");
  // };

  // set mealPlanMacroSelectedMember to the first member if it hasen't been selected
  mealPlanMacroSelectedMember =
    mealPlanMacroSelectedMember === ""
      ? user.members[0]._id
      : mealPlanMacroSelectedMember;

  // Get macroSelectedMemberMealPlanDays to map through it in JSX
  let macroSelectedMemberMealPlanDays = [];

  if (mealPlanMacrosOrSummaryView === "macros") {
    for (const m in mealPlanSchedule) {
      if (mealPlanSchedule[m].memberUserId === mealPlanMacroSelectedMember) {
        macroSelectedMemberMealPlanDays = mealPlanSchedule[m].days;
      }
    }
  }

  const weekDayLabels = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  // set the targets
  let caloriesTarget = "";
  let proteinTarget = "";

  for (const m in user.members) {
    if (user.members[m]._id == mealPlanMacroSelectedMember) {
      caloriesTarget = user.members[m].calories;
      proteinTarget = user.members[m].protein;
    }
  }

  // generateMacroseForSelectedMember
  const macrosForSelectedMember = [];

  for (const day of macroSelectedMemberMealPlanDays) {
    let dayMacros = {
      caloriesTarget: caloriesTarget,
      caloriesTotal: 0,
      caloriesDelta: 0,
      proteinTarget: proteinTarget,
      proteinTotal: 0,
      proteinDelta: 0,
    };

    if (day.length > 0) {
      for (const recipeAndMealPlanYieldToAdd of day) {
        const recipe = recipeAndMealPlanYieldToAdd.recipe;

        dayMacros.caloriesTotal = Math.trunc(
          Math.round(
            (dayMacros.caloriesTotal +
              recipe.calories * recipeAndMealPlanYieldToAdd.serving) *
              10
          ) / 10
        );

        dayMacros.proteinTotal = Math.trunc(
          Math.round(
            (dayMacros.proteinTotal +
              recipe.protein * recipeAndMealPlanYieldToAdd.serving) *
              10
          ) / 10
        );
      }

      // add commas to delta
      dayMacros.caloriesDelta = Number(
        Math.trunc(
          Math.round(
            (dayMacros.caloriesTotal - dayMacros.caloriesTarget) * 10
          ) / 10
        )
      ).toLocaleString();

      dayMacros.proteinDelta = Number(
        Math.trunc((dayMacros.proteinTotal - dayMacros.proteinTarget) * 10) / 10
      ).toLocaleString();

      // If the deltas are positive, add the "+" sign
      if (dayMacros.caloriesDelta > 0) {
        dayMacros.caloriesDelta = `+${dayMacros.caloriesDelta}`;
      }

      if (dayMacros.proteinDelta > 0) {
        dayMacros.proteinDelta = `+${dayMacros.proteinDelta}`;
      }

      // add commas target and total
      dayMacros.caloriesTarget = Number(
        dayMacros.caloriesTarget
      ).toLocaleString();

      dayMacros.proteinTarget = Number(
        dayMacros.proteinTarget
      ).toLocaleString();

      dayMacros.caloriesTotal = Number(
        dayMacros.caloriesTotal
      ).toLocaleString();

      dayMacros.proteinTotal = Number(dayMacros.proteinTotal).toLocaleString();

      // push to macrosForSelectedMember
      macrosForSelectedMember.push(dayMacros);

      // reset daily macros
      dayMacros = {
        caloriesTarget: caloriesTarget,
        caloriesTotal: 0,
        caloriesDelta: 0,
        proteinTarget: proteinTarget,
        proteinTotal: 0,
        proteinDelta: 0,
      };
    } else {
      macrosForSelectedMember.push(dayMacros);
    }
  }

  let unassignedRecipes = [];

  function checkAndGenerateUnassignedRecipes(
    mealPlanRecipes,
    mealPlanSchedule
    // recipeServing
  ) {
    let totalServingsAllocated = 0;
    // iterate through each unique recipe
    for (const r in mealPlanRecipes) {
      const uniqueRecipe = mealPlanRecipes[r];
      const uniqueRecipeId = mealPlanRecipes[r]._id;
      const uniqueRecipeServing = mealPlanRecipes[r].personalizedYield;

      /// iterate through the meal plan schedule
      for (const i in mealPlanSchedule) {
        const mealPlanScheduleDays = mealPlanSchedule[i].days;

        for (const d in mealPlanScheduleDays) {
          let day = mealPlanScheduleDays[d];

          // for each recipeAndMealPlanYieldToAdd in day
          for (const r in day) {
            const recipeAndMealPlanYieldToAdd = day[r];

            if (recipeAndMealPlanYieldToAdd.recipe._id == uniqueRecipeId) {
              totalServingsAllocated =
                totalServingsAllocated +
                Number(recipeAndMealPlanYieldToAdd.serving);
            }
          }
        }
      }

      if (totalServingsAllocated < uniqueRecipeServing) {
        // I'm multiplying * - 1 so the serving amount is not negative
        unassignedRecipes.push({
          recipe: uniqueRecipe,
          unassignedServing:
            (totalServingsAllocated - uniqueRecipeServing) * -1,
        });
      }

      totalServingsAllocated = 0;
    }
  }

  checkAndGenerateUnassignedRecipes(mealPlanRecipes, mealPlanSchedule);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonSegment value={week}>
            <IonSegmentButton
              value={tabWeeks()[0].week}
              onClick={updateWeek}
              disabled={isLoading ? true : false}
            >
              {tabWeeks()[0].date}
            </IonSegmentButton>
            <IonSegmentButton
              value={tabWeeks()[1].week}
              onClick={updateWeek}
              disabled={isLoading ? true : false}
            >
              {tabWeeks()[1].date}
            </IonSegmentButton>
            <IonSegmentButton
              value={tabWeeks()[2].week}
              onClick={updateWeek}
              disabled={isLoading ? true : false}
            >
              {tabWeeks()[2].date}
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <WebWidthCardContainer>
          <div
            className="ion-padding"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h1>
              {tabWeeks()[0].date} - {tabWeeks()[3].date}
            </h1>
            {mealPlanRecipes.length === 0 ? null : (
              <IonChip
                style={{ marginTop: "12px" }}
                className="width"
                color="primary"
                onClick={() => updateMealPlanMacrosOrSummaryView()}
                disabled={isLoading}
              >
                {mealPlanMacrosOrSummaryView === "summary"
                  ? "macros"
                  : "summary"}
              </IonChip>
            )}
          </div>
          {mealPlanRecipes.length === 0 ? (
            <div>
              <IonImg
                crossorigin="anonymous"
                style={{
                  height: "110px",
                  margin: "auto",
                  marginTop: "100px",
                }}
                src="https://my-recipa-app-images.s3.us-west-1.amazonaws.com/app-assets/recipe-book.svg"
                alt="recipe book"
              ></IonImg>
              <h4 style={{ textAlign: "center", color: "#808080" }}>
                No Recipes Added
              </h4>
              <h5
                style={{
                  textAlign: "center",
                  color: "#808080",
                  marginTop: "0",
                }}
              >
                Add a recipe to build a tasty meal plan!
              </h5>
            </div>
          ) : (
            ""
          )}
          {mealPlanMacrosOrSummaryView === "summary" ? (
            <>
              {/* Summary view cards */}
              <CardWidthContainer>
                {mealPlanRecipes.map((recipe) => {
                  return <RecipeCard key={recipe._id} {...recipe} />;
                })}
              </CardWidthContainer>
            </>
          ) : (
            <>
              {/* iterate through each unassigned recipe */}
              {unassignedRecipes.length === 0 ? (
                <></>
              ) : (
                <>
                  <IonItem detail={false} style={{ flex: "none" }}>
                    <h2
                      style={{
                        marginRight: "auto",
                      }}
                    >
                      Unassigned Servings
                    </h2>
                    <IonLabel
                      style={{
                        margin: "0",
                        height: "100%",
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "center",
                        flex: "none",
                        width: "45px",
                        paddingBottom: "6px",
                      }}
                    >
                      Cal
                    </IonLabel>
                    <IonLabel
                      style={{
                        margin: "0",
                        height: "100%",
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "center",
                        // textAlign: "center",
                        flex: "none",
                        width: "45px",
                        paddingBottom: "6px",
                      }}
                    >
                      P
                    </IonLabel>
                    {/* this IonButton has not functional need, but it keeps the UI exactly alinged since this button is in every other row*/}
                    <IonButton
                      fill="clear"
                      style={{
                        margin: "0px",
                        marginRight: "-15px",
                      }}
                    >
                      <IonIcon size="large"></IonIcon>
                    </IonButton>
                  </IonItem>
                </>
              )}
              <IonList lines="full">
                {unassignedRecipes.map((recipeAndServing, index) => {
                  const { recipe } = recipeAndServing;

                  return (
                    <IonItem
                      detail={false}
                      key={index}
                      style={{ flex: "none" }}
                    >
                      <IonImg
                        src={
                          recipe.featuredMedia === ""
                            ? "https://my-recipa-app-images.s3.us-west-1.amazonaws.com/app-assets/imageless-recipe-or-food.jpg"
                            : recipe.featuredMedia
                        }
                        style={{
                          width: "60px",
                          height: "33px",
                          objectFit: "cover",
                          paddingRight: "10px",
                        }}
                        onClick={() => setEditRecipeIdAndRedirect(recipe._id)}
                      ></IonImg>
                      <IonLabel
                        class="ion-text-wrap"
                        onClick={() => setEditRecipeIdAndRedirect(recipe._id)}
                        style={{
                          margin: "0",
                        }}
                      >
                        {recipeAndServing.unassignedServing === 1 ? (
                          <></>
                        ) : (
                          recipeAndServing.unassignedServing + " "
                        )}{" "}
                        {recipe.name}
                      </IonLabel>
                      <IonLabel
                        style={{
                          margin: "0",
                          textAlign: "center",
                          flex: "none",
                          width: "45px",
                        }}
                      >
                        {recipe.calories === null
                          ? null
                          : Math.trunc(
                              recipe.calories *
                                recipeAndServing.unassignedServing
                            ).toLocaleString()}
                      </IonLabel>

                      <IonLabel
                        style={{
                          margin: "0",

                          textAlign: "center",
                          flex: "none",
                          width: "45px",
                        }}
                      >
                        {recipe.protein === null
                          ? null
                          : Math.trunc(
                              recipe.protein *
                                recipeAndServing.unassignedServing
                            ).toLocaleString()}
                      </IonLabel>
                      <IonButton
                        fill="clear"
                        onClick={() =>
                          openAndSetEllipsisDropdown(recipeAndServing.recipe)
                        }
                        style={{
                          margin: "0px",
                          marginRight: "-15px",
                        }}
                      >
                        <IonIcon
                          color="medium"
                          icon={ellipsisVertical}
                          size="large"
                          style={{
                            height: "20px",
                          }}
                        ></IonIcon>
                      </IonButton>
                    </IonItem>
                  );
                })}
              </IonList>

              {/* start old ########################################################### */}
              {/* iterate through each unassigned recipe */}
              {/* <IonList lines="full">
                {unassignedRecipes.length === 0 ? (
                  <></>
                ) : (
                  <h4
                    className="ion-padding"
                    style={{ paddingTop: "0", paddingBottom: "0" }}
                  >
                    Unassigned Servings
                  </h4>
                )}

                {unassignedRecipes.map((recipeAndServing, i) => {
                  const { recipe } = recipeAndServing;

                  return (
                    <IonItem detail={false} key={i} style={{ flex: "none" }}>
                      <IonImg
                        src={recipe.featuredMedia}
                        style={{
                          width: "40px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                        onClick={() => setEditRecipeIdAndRedirect(recipe._id)}
                      ></IonImg>
                      <IonLabel
                        class="ion-text-wrap"
                        onClick={() => setEditRecipeIdAndRedirect(recipe._id)}
                        style={{
                          flex: "none",
                          width: "225px",

                          margin: "0",
                        }}
                      >
                        <IonText
                          style={{
                            display: "flex",
                            verticalAlign: "middle",
                            margin: "auto 0",
                          }}
                        >
                          {recipe.name}
                        </IonText>
                      </IonLabel>
                      <IonText
                        style={{
                          margin: "0",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                        className="smallColumn"
                      >
                        {recipeAndServing.unassignedServing}
                        {"-"}
                      </IonText>
                      <IonText
                        style={{
                          margin: "0",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                        className="smallColumn"
                      >
                        {recipe.calories}
                        {"-"}
                      </IonText>

                      <IonText
                        style={{
                          margin: "0",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                        className="smallColumn"
                      >
                        {recipe.protein}
                      </IonText>
                      <IonButton
                        fill="clear"
                        onClick={() => openAndSetEllipsisDropdown(recipe)}
                      >
                        <IonIcon
                          color="medium"
                          icon={ellipsisVertical}
                          size="large"
                          style={{
                            height: "20px",
                          }}
                        ></IonIcon>
                      </IonButton>
                    </IonItem>
                  );
                })}
              </IonList> */}
              {/* end old ########################################################### */}
              {/* segment tabs for each member */}
              {mealPlanRecipes.length === 0 ? null : (
                <div className="ion-padding">
                  <IonSegment value={mealPlanMacroSelectedMember}>
                    {mealPlanSchedule.map((member) => {
                      const members = user.members;
                      let memberName = "";

                      for (const m in members) {
                        if (members[m]._id === member.memberUserId) {
                          memberName = members[m].firstName;
                        }
                      }
                      return (
                        <IonSegmentButton
                          value={member.memberUserId}
                          onClick={updateMealPlanMacroSelectedMember}
                        >
                          {memberName}
                        </IonSegmentButton>
                      );
                    })}
                  </IonSegment>
                </div>
              )}

              <div>
                {/* iterate through each day of selected Member */}
                {macroSelectedMemberMealPlanDays.map((day, index) => {
                  // console.log("index:");
                  // console.log(index);

                  // console.log("day:");
                  // console.log(day);

                  // console.log("macrosForSelectedMember:");
                  // console.log(macrosForSelectedMember);

                  const m = macrosForSelectedMember[index];

                  // if I don't do this undefined check, the app breaks for new users. Not sure exacly why
                  // const caloriesTotalText =
                  //   typeof m === "undefined"
                  //     ? 0
                  //     : m.caloriesTotal.toLocaleString();

                  const caloriesTotalText = m.caloriesTotal;

                  // const caloriesTargetText =
                  //   typeof m === "undefined" ? 0 : m.caloriesTarget;

                  const caloriesTargetText = m.caloriesTarget;

                  // const caloriesDeltaText =
                  //   typeof m === "undefined" ? 0 : m.caloriesDelta;
                  const caloriesDeltaText = m.caloriesDelta;

                  // const proteinTotalText =
                  //   typeof m === "undefined" ? 0 : m.proteinTotal;

                  const proteinTotalText = m.proteinTotal;

                  // const proteinTargetText =
                  //   typeof m === "undefined" ? 0 : m.proteinTarget;
                  const proteinTargetText = m.proteinTarget;

                  // const proteinDeltaText =
                  //   typeof m === "undefined" ? 0 : m.proteinDelta;
                  const proteinDeltaText = m.proteinDelta;

                  return (
                    <div>
                      {day.length === 0 ? null : (
                        <>
                          <IonItem
                            detail={false}
                            key={index}
                            style={{ flex: "none" }}
                          >
                            <h2
                              style={{
                                marginRight: "auto",
                              }}
                            >
                              {weekDayLabels[index]}
                            </h2>
                            <IonLabel
                              style={{
                                margin: "0",
                                height: "100%",
                                display: "flex",
                                alignItems: "end",
                                justifyContent: "center",
                                flex: "none",
                                width: "45px",
                                paddingBottom: "6px",
                              }}
                            >
                              Cal
                            </IonLabel>
                            <IonLabel
                              style={{
                                margin: "0",
                                height: "100%",
                                display: "flex",
                                alignItems: "end",
                                justifyContent: "center",
                                // textAlign: "center",
                                flex: "none",
                                width: "45px",
                                paddingBottom: "6px",
                              }}
                            >
                              P
                            </IonLabel>
                            {/* this IonButton has not functional need, but it keeps the UI exactly alinged since this button is in every other row*/}
                            <IonButton
                              fill="clear"
                              style={{
                                margin: "0px",
                                marginRight: "-15px",
                              }}
                            >
                              <IonIcon size="large"></IonIcon>
                            </IonButton>
                          </IonItem>
                        </>
                      )}
                      <IonList lines="full">
                        {day.map((recipeAndMealPlanYieldToAdd, i) => {
                          const { recipe } = recipeAndMealPlanYieldToAdd;

                          return (
                            <IonItem
                              detail={false}
                              key={index}
                              style={{ flex: "none" }}
                            >
                              <IonImg
                                src={
                                  recipe.featuredMedia === ""
                                    ? "https://my-recipa-app-images.s3.us-west-1.amazonaws.com/app-assets/imageless-recipe-or-food.jpg"
                                    : recipe.featuredMedia
                                }
                                style={{
                                  width: "60px",
                                  height: "33px",
                                  objectFit: "cover",
                                  paddingRight: "10px",
                                }}
                                onClick={() =>
                                  setEditRecipeIdAndRedirect(recipe._id)
                                }
                              ></IonImg>
                              <IonLabel
                                class="ion-text-wrap"
                                onClick={() =>
                                  setEditRecipeIdAndRedirect(recipe._id)
                                }
                                style={{
                                  margin: "0",
                                }}
                              >
                                {recipeAndMealPlanYieldToAdd.serving === 1 ? (
                                  <></>
                                ) : (
                                  recipeAndMealPlanYieldToAdd.serving + " "
                                )}{" "}
                                {recipe.name}
                              </IonLabel>
                              <IonLabel
                                style={{
                                  margin: "0",
                                  textAlign: "center",
                                  flex: "none",
                                  width: "45px",
                                }}
                              >
                                {recipe.calories === null
                                  ? null
                                  : Math.trunc(
                                      recipe.calories *
                                        recipeAndMealPlanYieldToAdd.serving
                                    ).toLocaleString()}
                              </IonLabel>

                              <IonLabel
                                style={{
                                  margin: "0",

                                  textAlign: "center",
                                  flex: "none",
                                  width: "45px",
                                }}
                              >
                                {recipe.protein === null
                                  ? null
                                  : Math.trunc(
                                      recipe.protein *
                                        recipeAndMealPlanYieldToAdd.serving
                                    ).toLocaleString()}
                              </IonLabel>
                              <IonButton
                                fill="clear"
                                onClick={() =>
                                  openAndSetEllipsisDropdown(
                                    recipeAndMealPlanYieldToAdd.recipe
                                  )
                                }
                                style={{
                                  margin: "0px",
                                  marginRight: "-15px",
                                }}
                              >
                                <IonIcon
                                  color="medium"
                                  icon={ellipsisVertical}
                                  size="large"
                                  style={{
                                    height: "20px",
                                  }}
                                ></IonIcon>
                              </IonButton>
                            </IonItem>
                          );
                        })}
                      </IonList>
                      {day.length === 0 ? null : (
                        <IonItem lines="none" detail={false}>
                          <IonLabel
                            style={{
                              display: "flex",
                              verticalAlign: "middle",
                              margin: "auto 0",
                              fontSize: "0.9rem",
                            }}
                          >
                            Calories: {caloriesTotalText}/ {caloriesTargetText}{" "}
                            ({caloriesDeltaText} ) &nbsp;|&nbsp; Protein:{" "}
                            {proteinTotalText}/ {proteinTargetText} (
                            {proteinDeltaText} )
                          </IonLabel>
                        </IonItem>
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {/* Macros view action sheet for three dot menu */}
          <IonActionSheet
            isOpen={open}
            buttons={[
              {
                text: "Remove All Servings",
                handler: () =>
                  removeMealPlanRecipe(
                    ellipsisDropdownRecipe._id,
                    ellipsisDropdownRecipe.name
                  ),
                role: "destructive",
                data: {
                  action: "delete",
                },
              },
              {
                text: "Update Servings",
                handler: () =>
                  handleSetEditServingUpdateServing(
                    ellipsisDropdownRecipe._id,
                    ellipsisDropdownRecipe.personalizedYield,
                    ellipsisDropdownRecipe.name
                  ),
                data: {
                  action: "Update",
                },
              },
              // {
              //   text: "Edit",
              //   handler: () => setGetRecipe(ellipsisDropdownRecipe._id),
              //   data: {
              //     action: "Update",
              //   },
              // },
              {
                text: "Cancel",
                role: "cancel",
                data: {
                  action: "cancel",
                },
              },
            ]}
            onDidDismiss={() => setOpen(false)}
          ></IonActionSheet>{" "}
        </WebWidthCardContainer>
        <Fab />
      </IonContent>
    </IonPage>
  );
};

export default MealPlan;
