import { useState, useEffect } from "react";
import { IonContent, IonPage, useIonViewDidEnter } from "@ionic/react";
import styled from "styled-components";
import {
  IonList,
  IonButton,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonActionSheet,
  useIonToast,
} from "@ionic/react";
import { filter } from "ionicons/icons";
import {
  // RecipesContainer,
  // SearchContainer,
  Header,
  FormRowSelect,
  FormRow,
  RecipeCard,
  PageBtnContainer,
  Fab,
} from "../components";
import { useAppContext } from "../context/appContext";

const WebWidthCardContainer = styled.div`
  margin: auto;

  @media (min-width: 770px) {
    max-width: 740px;
  }

  @media (min-width: 1142px) {
    max-width: 1115px;
  }

  @media (min-width: 1512px) {
    max-width: 1485px;
  }

  @media (min-width: 1900px) {
    max-width: 1850px;
  }
`;

const CardWidthContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Collection = () => {
  const {
    isLoading,
    handleChange,
    recipeFeaturedCategories,
    recipeCourseTypes,
    recipeCuisines,
    recipeMealTypes,
    recipeMeatTypes,
    SortOptions,
    alertText,
    alertColor,
    showAlert,
    // CollectionSearch section
    collectionRecipeName,
    collectionRecipeSource,
    collectionRecipeSourceUrl,
    collectionRecipeTotalTime,
    collectionRecipeCookTime,
    collectionRecipeMarinateTime,
    collectionRecipeRestTime,
    collectionRecipeFeaturedCategory,
    collectionRecipeCourseType,
    collectionRecipeCuisine,
    collectionRecipeMealType,
    collectionRecipeMeatType,
    collectionRecipeYield,
    collectionRecipeCalories,
    collectionRecipeCarbohydrates,
    collectionRecipeFat,
    collectionRecipeProtein,
    collectionRecipeVideo,
    collectionRecipeSortOption,
    clearCollectionFilters,
    // Cards result section
    getCollectionPageRecipes,
    collectionRecipes,
    collectionTotalRecipes,
    collectionCurrentPage,
    collectionNumOfPages,
    // Pagination section
  } = useAppContext();

  const [present] = useIonToast();

  const presentToast = () => {
    present({
      message: alertText,
      duration: 2000,
      position: "top",
      color: alertColor,
    });
  };

  if (showAlert) {
    presentToast();
  }

  ////////////////////////////////////// COLLECTIONSEARCH SECTION //////////////////////////////////////

  const handleSearch = (e) => {
    // isLoading check ensure we don't make additional request we're during the middle of a request so we make less unnecessary requests
    // if (isLoading) return;
    handleChange({ name: e.target.name, value: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    clearCollectionFilters();
  };

  // filter modal
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  ////////////////////////////////////// CARDS RESULT SECTION //////////////////////////////////////
  // Bug: useIonViewDidEnter and useEffect run twice (make API call) on every load. useIonViewDidEnter works better than
  // useEffect because it runs when a user navigates away and back to the collectionsearch page
  // useIonViewDidEnter(() => {
  //   console.log("useIonViewDidEnter");

  //   getCollectionPageRecipes();
  // });

  useEffect(() => {
    handleChange({
      name: "discoverSelectedPage",
      value: "/my/discover/collection",
    });

    getCollectionPageRecipes();

    // eslint-disable-next-line
  }, [
    collectionCurrentPage,
    collectionRecipeName,
    collectionRecipeSource,
    collectionRecipeSourceUrl,
    collectionRecipeTotalTime,
    collectionRecipeCookTime,
    collectionRecipeMarinateTime,
    collectionRecipeRestTime,
    collectionRecipeFeaturedCategory,
    collectionRecipeCourseType,
    collectionRecipeCuisine,
    collectionRecipeMealType,
    collectionRecipeMeatType,
    collectionRecipeYield,
    collectionRecipeCalories,
    collectionRecipeCarbohydrates,
    collectionRecipeFat,
    collectionRecipeProtein,
    collectionRecipeVideo,
    collectionRecipeSortOption,
  ]);

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  if (collectionRecipes.length === 0) {
    <h2>No recipes to display...</h2>;
  }

  ////////////////////////////////////// PAGINATION SECTION //////////////////////////////////////

  const pages = Array.from({ length: collectionNumOfPages }, (_, index) => {
    return index + 1;
  });

  // console.log("pages");

  // I could do some logic here checking inf the collectionCurrentPage is in the first 5 slots, if it is return certain page number. Same logic if it's in the last 5 pages.
  // if (pages.length > 5) {
  //   console.log("it's greater than 5");
  // }

  const nextPage = () => {
    let newPage = collectionCurrentPage + 1;
    if (newPage > collectionNumOfPages) {
      newPage = 1;
    }

    handleChange({ name: "collectionCurrentPage", value: newPage });
  };

  const prevPage = () => {
    let newPage = collectionCurrentPage - 1;
    if (newPage < 1) {
      newPage = collectionNumOfPages;
    }
    handleChange({ name: "collectionCurrentPage", value: newPage });
  };

  return (
    <IonPage>
      <Header />
      <IonContent className="ion-padding">
        <WebWidthCardContainer>
          <h1>Your Collection</h1>
          {/* ////////////////////////////////////// COLLECTIONSEARCHSEARCH SECTION ////////////////////////////////////// */}
          <>
            <form>
              <div
                style={{
                  paddingBottom: "0",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <FormRow
                  // labelText="Name"
                  styleIonItem={{ width: "100%" }}
                  type="text"
                  placeholder="Search your collection"
                  name="collectionRecipeName"
                  value={collectionRecipeName}
                  onIonChange={handleSearch}
                />

                {/* None of the Collection Filters will work right now since users cannot edit those recipe fields */}
                {/*<div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "75px",
                  }}
                >
                  <IonButton fill="clear" onClick={() => setOpen(true)}>
                    <IonIcon
                      color="primary"
                      icon={filter}
                      size="large"
                      style={{ height: "26px" }}
                    ></IonIcon>
                  </IonButton>
                </div>
                */}
              </div>

              <IonList>{/* collectionsearch by name */}</IonList>
            </form>
            <IonModal
              isOpen={open}
              onDidDismiss={closeModal}
              breakpoints={[0, 0.3, 0.5, 1]}
              initialBreakpoint={0.5}
              backdropBreakpoint={0.2}
            >
              <IonToolbar>
                <IonTitle>Filters</IonTitle>
                <IonButtons slot="end">
                  <IonButton color="primary" onClick={() => closeModal()}>
                    Close
                  </IonButton>
                </IonButtons>
              </IonToolbar>
              <IonList className="ion-padding">
                {/* collectionsearch by source */}
                {/* <FormRow
          labelText="Source"
          type="text"
          name="collectionRecipeSource"
          value={collectionRecipeSource}
          onIonChange={handleSearch}
        /> */}
                {/* collectionsearch by sourceUrl */}
                {/* <FormRow
          labelText="Source Url"
          type="text"
          name="collectionRecipeSourceUrl"
          value={collectionRecipeSourceUrl}
          onIonChange={handleSearch}
        /> */}
                {/* collectionsearch by totalTime */}
                <FormRow
                  labelText="Total Time"
                  type="text"
                  placeholder="Less than minutes"
                  name="collectionRecipeTotalTime"
                  value={collectionRecipeTotalTime}
                  onIonChange={handleSearch}
                />
                {/* collectionsearch by cookTime */}
                {/* <FormRow
          labelText="Cook Time"
          type="text"
          name="collectionRecipeCookTime"
          value={collectionRecipeCookTime}
          onIonChange={handleSearch}
        /> */}
                {/* collectionsearch by marinateTime */}
                {/* <FormRow
          labelText="Marinate Time"
          type="text"
          name="collectionRecipeMarinateTime"
          value={collectionRecipeMarinateTime}
          onIonChange={handleSearch}
        /> */}
                {/* collectionsearch by restTime */}
                {/* <FormRow
          labelText="Rest Time"
          type="text"
          name="collectionRecipeRestTime"
          value={collectionRecipeRestTime}
          onIonChange={handleSearch}
        /> */}
                {/* the ... is an example of the spread operator  https://www.javascripttutorial.net/es6/javascript-spread/ */}
                {/* collectionsearch by featuredCategory */}
                {/* <FormRowSelect
          labelText="Featured Category"
          name="collectionRecipeFeaturedCategory"
          placeholder="Featured Category"
          value={collectionRecipeFeaturedCategory}
          onIonChange={handleSearch}
          list={["All", ...recipeFeaturedCategories]}
        /> */}
                {/* collectionsearch by recipeCourseTypes */}
                <FormRowSelect
                  labelText="Course"
                  name="collectionRecipeCourseType"
                  placeholder="Course Type"
                  value={collectionRecipeCourseType}
                  onIonChange={handleSearch}
                  list={["All", ...recipeCourseTypes]}
                />
                {/* collectionsearch by Cuisine */}
                <FormRowSelect
                  labelText="Cuisine"
                  name="collectionRecipeCuisine"
                  placeholder="Cuisine"
                  value={collectionRecipeCuisine}
                  onIonChange={handleSearch}
                  list={["All", ...recipeCuisines]}
                />
                {/* collectionsearch by MealType */}
                {/* <FormRowSelect
          labelText="Meal Type"
          name="collectionRecipeMealType"
          placeholder="Meal"
          value={collectionRecipeMealType}
          onIonChange={handleSearch}
          list={["All", ...recipeMealTypes]}
        /> */}
                {/* collectionsearch by meatType */}
                <FormRowSelect
                  labelText="Meat"
                  name="collectionRecipeMeatType"
                  placeholder="Meat Type"
                  value={collectionRecipeMeatType}
                  onIonChange={handleSearch}
                  list={["All", ...recipeMeatTypes]}
                />
                {/* collectionsearch by Recipe Yield */}
                {/* <FormRow
          labelText="Recipe Yield"
          type="text"
          name="collectionRecipeYield"
          value={collectionRecipeYield}
          onIonChange={handleSearch}
        /> */}
                {/* collectionsearch by calories */}
                {/* <FormRow
          labelText="Calories"
          type="text"
          name="collectionRecipeCalories"
          value={collectionRecipeCalories}
          onIonChange={handleSearch}
        /> */}
                {/* collectionsearch by carbohydrates */}
                {/* <FormRow
          labelText="Carbohydrates"
          type="text"
          name="collectionRecipeCarbohydrates"
          value={collectionRecipeCarbohydrates}
          onIonChange={handleSearch}
        /> */}
                {/* collectionsearch by fat */}
                {/* <FormRow
          labelText="Fat"
          type="fat"
          name="collectionRecipeFat"
          value={collectionRecipeFat}
          onIonChange={handleSearch}
        /> */}
                {/* collectionsearch by protein */}
                {/* <FormRow
          labelText="Protein"
          type="text"
          name="collectionRecipeProtein"
          value={collectionRecipeProtein}
          onIonChange={handleSearch}
        /> */}
                {/* collectionsearch by video */}
                {/* <FormRow
          labelText="Video"
          type="text"
          name="collectionRecipeVideo"
          value={collectionRecipeVideo}
          onIonChange={handleSearch}
        /> */}
                {/* <FormRowSelect
          labelText="Video"
          name="collectionRecipeVideo"
          placeholder="Video"
          value={collectionRecipeVideo}
          onIonChange={handleSearch}
          list={["Yes", "No"]}
        /> */}
                {/* sort order */}
                {/* <FormRowSelect
          labelText="Sort"
          name="collectionRecipeSortOption"
          placeholder="Sort"
          value={collectionRecipeSortOption}
          onIonChange={handleSearch}
          list={SortOptions}
        /> */}
              </IonList>
              <IonButton
                className="ion-padding"
                expand="block"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                Clear Filters
              </IonButton>
            </IonModal>
          </>
          {/* <SearchContainer /> */}
          {/* ////////////////////////////////////// CARDS RESULT SECTION ////////////////////////////////////// */}
          <h6
            className="ion-padding"
            style={{ paddingTop: "0px", paddingBottom: "0px" }}
          >
            {collectionTotalRecipes} recipe{collectionRecipes.length > 1 && "s"}{" "}
            found
          </h6>
          <CardWidthContainer>
            {/* { The ...{recipe} is another way to pass in all of the props */}
            {collectionRecipes.map((recipe) => {
              return <RecipeCard key={recipe._id} {...recipe} />;
            })}
          </CardWidthContainer>

          {/* <RecipesContainer /> */}
          {/* ////////////////////////////////////// PAGINATION SECTION ////////////////////////////////////// */}
          {collectionNumOfPages == 1 || collectionNumOfPages == 0 ? (
            <></>
          ) : (
            <>
              <IonButton color="light" onClick={prevPage} disabled={isLoading}>
                Back
              </IonButton>
              {pages.map((pageNumber) => {
                return (
                  <IonButton
                    color={
                      pageNumber === collectionCurrentPage ? "primary" : "light"
                    }
                    key={pageNumber}
                    onClick={() =>
                      handleChange({
                        name: "collectionCurrentPage",
                        value: pageNumber,
                      })
                    }
                  >
                    {pageNumber}
                  </IonButton>
                );
              })}
              <IonButton color="light" onClick={nextPage} disabled={isLoading}>
                Next
              </IonButton>
            </>
          )}
        </WebWidthCardContainer>
        {/* ////////////////////////////////////// FAB SECTION ////////////////////////////////////// */}
        <Fab />
      </IonContent>
    </IonPage>
  );
};

export default Collection;
