import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppContext } from "../context/appContext";
import { FormRow, FormTextarea } from "../components";
import styled from "styled-components";

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonBackButton,
  IonItem,
  IonInput,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  useIonToast,
  IonSelect,
  IonSelectOption,
  useIonViewWillEnter,
  IonBadge,
  IonActionSheet,
} from "@ionic/react";

import {
  calculatedQuantity,
  useHandleShoppingItemInput,
} from "../utils/Helpers.js";
import {
  aisleCategories,
  shoppingItemUnitCategories,
} from "../utils/Constants.js";

const WebWidthListContainer = styled.div`
  margin: auto;
  max-width: 750px;
`;

const SpaceSoiPhoneKeyboardDoesntCoverInputs = styled.div`
  .ios {
    height: 400px;
    background-color: white;
    display: block;
  }
`;

const ShoppingItemPage = () => {
  const {
    editRecipeId,
    handleChangeLevelTwo,
    recipeYield,
    personalizedYield,
    showAlert,
    alertColor,
    alertText,
    isLoading,
    shoppingItem,
    editRecipeShoppingItemViewRecipePage,
    addRecipeShoppingItemViewRecipePage,
    removeRecipeShoppingItemViewRecipePage,
    updatedShoppingItemRecipeQuantityViewRecipePage,
    updatedShoppingItemRecipeQuantity,
    createShoppingItem,
    clearShoppingItemModal,
    editRecipeShoppingItem,
    editShoppingListShoppingItem,
    removeRecipeShoppingItem,
    removeShoppingListShoppingItem,
    shoppingList,
    user,
  } = useAppContext();

  const history = useHistory();

  const { handleShoppingItemInput } = useHandleShoppingItemInput();

  const [present] = useIonToast();

  const location = useLocation();

  const isFromShoppingListPage = location.pathname.includes("shopping-list");

  const isFromMealPlanPage = location.pathname.includes("/meal-plan");

  const isFromCollectionPage = location.pathname.includes("/collection");

  const isAddShoppingItem = location.pathname.includes("add");

  const isEditShoppingItem = location.pathname.includes("edit");

  // user._id === "6382aed8c8c03dff526e8280" is the admin account
  const isNotEditable =
    isFromShoppingListPage === true ||
    isFromMealPlanPage === true ||
    isFromCollectionPage === true ||
    user._id === "6382aed8c8c03dff526e8280"
      ? false
      : true;

  const [open, setOpen] = useState(false);

  // // tech debt: temporary solution to handle when a user refreshes on the shopping item page.
  // useEffect(() => {
  //   console.log("use effect");
  //   if (shoppingList.length == 0 && editRecipeId == "") {
  //     console.log("redirect logic");
  //     setTimeout(function () {
  //       console.log("Start setTimeout");

  //       if (shoppingList.length == 0 && editRecipeId == "") {
  //         console.log("setTimeout history.push(/)");
  //         history.push("/");
  //       }
  //     }, 10000);
  //   }
  // }, [editRecipeId, shopping]);

  const presentToast = () => {
    present({
      message: alertText,
      duration: 1500,
      position: "top",
      color: alertColor,
    });
  };

  if (showAlert) {
    presentToast();
  }

  const removeShoppingItemAndCloseModal = () => {
    // if one of these exits, then it's the shoppingListPage calling this page
    if (isFromShoppingListPage) {
      if (shoppingItem.recipeId) {
        removeRecipeShoppingItem();
      } else if (shoppingItem.shoppingListId) {
        removeShoppingListShoppingItem();
      } else {
      }

      // else, this is the RecipeViewPage
    } else {
      removeRecipeShoppingItemViewRecipePage();
    }
    history.goBack();
  };

  const onSubmit = (e) => {
    e.preventDefault();

    // if one of these exits, then it's the shoppingListPage calling this page
    if (isFromShoppingListPage) {
      if (isAddShoppingItem) {
        createShoppingItem();
        history.goBack();
        return;
      } else if (isEditShoppingItem && shoppingItem.recipeId) {
        editRecipeShoppingItem();
        history.goBack();
        return;
      } else if (
        isEditShoppingItem &&
        typeof shoppingItem.recipeId === "undefined"
      ) {
        editShoppingListShoppingItem();
        history.goBack();
        return;
      }
      // else, this is the RecipeViewPage
    } else {
      if (typeof shoppingItem._id === "undefined") {
        addRecipeShoppingItemViewRecipePage();
        history.goBack();

        return;
      } else {
        editRecipeShoppingItemViewRecipePage();
        history.goBack();

        return;
      }
    }
  };

  const shoppingOrItemName = (recipeShoppingItem) => {
    if (
      typeof recipeShoppingItem.name === "undefined" ||
      recipeShoppingItem.name === ""
    ) {
      if (typeof recipeShoppingItem.item !== "undefined") {
        return recipeShoppingItem.item.name;
      }
    } else {
      return recipeShoppingItem.name;
    }
  };

  const shoppingOrItemAisle = (recipeShoppingItem) => {
    if (typeof recipeShoppingItem.aisle === "undefined") {
      if (typeof recipeShoppingItem.item !== "undefined") {
        return recipeShoppingItem.item.aisle;
      }
    } else {
      return recipeShoppingItem.aisle;
    }
  };

  let adaptedPersonalizedYield = personalizedYield;
  let adaptedRecipeYield = recipeYield;

  // if one of these exits, then it's the shoppingListPage calling this page
  if (
    typeof shoppingItem.recipeId !== "undefined" ||
    typeof shoppingItem.shoppingListId !== "undefined"
  ) {
    adaptedPersonalizedYield = shoppingItem.personalizedYield;
    adaptedRecipeYield = shoppingItem.recipeYield;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/my/discover/menu" />
          </IonButtons>
          <IonTitle>
            {typeof shoppingItem._id === "undefined" ? "Add" : "Edit"}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <WebWidthListContainer>
          <IonGrid style={{ width: "100%" }}>
            <IonRow style={{ justifyContent: "center" }}>
              <IonImg
                src={
                  typeof shoppingItem.item === "undefined"
                    ? "https://my-recipa-app-images.s3.us-west-1.amazonaws.com/app-assets/white-bag.jpg"
                    : shoppingItem.item.image
                }
                style={{
                  height: "250px",
                }}
              ></IonImg>
            </IonRow>
            <IonRow>
              {shoppingItem.recipeId ? (
                <IonCol size="12">
                  <FormRow
                    labelText="Recipe"
                    type="text"
                    name="recipe"
                    disabled={true}
                    value={`For ${shoppingItem.personalizedYield} servings of ${shoppingItem.recipeName}`}
                  />
                </IonCol>
              ) : (
                <></>
              )}
            </IonRow>
            <IonRow>
              <IonCol size="6">
                {/* if it's the shoppingListPage and shoppingItem.recipeId is undefined, it's a stand alone shopping item */}
                {isFromShoppingListPage &&
                typeof shoppingItem.recipeId === "undefined" ? (
                  <FormRow
                    labelText="Quantity"
                    type="number"
                    name="quantity"
                    value={shoppingItem.quantity}
                    disabled={isNotEditable ? true : false}
                    onIonChange={handleShoppingItemInput}
                  />
                ) : (
                  <IonItem>
                    <IonLabel position="stacked">Quantity</IonLabel>
                    <IonInput
                      type="text"
                      name="quantity"
                      value={
                        shoppingItem.quantity * adaptedPersonalizedYield === 0
                          ? ""
                          : calculatedQuantity(
                              adaptedPersonalizedYield,
                              adaptedRecipeYield,
                              shoppingItem.quantity
                            )
                      }
                      onBlur={
                        isFromShoppingListPage
                          ? updatedShoppingItemRecipeQuantity
                          : updatedShoppingItemRecipeQuantityViewRecipePage
                      }
                      disabled={isNotEditable ? true : false}
                    />
                  </IonItem>
                )}
              </IonCol>
              <IonCol size="6">
                <IonItem>
                  <IonLabel position="stacked">Unit</IonLabel>
                  <IonSelect
                    interface="action-sheet"
                    name="unit"
                    label="Stacked label"
                    labelPlacement="stacked"
                    value={shoppingItem.unit}
                    onIonChange={handleShoppingItemInput}
                    disabled={isNotEditable ? true : false}
                  >
                    {shoppingItemUnitCategories.map((itemValue, i) => {
                      if (itemValue === "heading") {
                        return null;
                      } else {
                        return (
                          <IonSelectOption
                            key={i}
                            value={itemValue}
                            disabled={false}
                          >
                            {itemValue}
                          </IonSelectOption>
                        );
                      }
                    })}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <FormRow
                  labelText="Name (Required*)"
                  type="text"
                  name="name"
                  disabled={
                    typeof shoppingItem.item === "undefined" ? false : true
                  }
                  value={shoppingOrItemName(shoppingItem)}
                  onIonChange={handleShoppingItemInput}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <IonItem>
                  <IonLabel position="stacked">Aisle</IonLabel>
                  <IonSelect
                    interface="action-sheet"
                    name="aisle"
                    label="Stacked label"
                    labelPlacement="stacked"
                    disabled={
                      typeof shoppingItem.item === "undefined" ? false : true
                    }
                    onIonChange={handleShoppingItemInput}
                    value={shoppingOrItemAisle(shoppingItem)}
                  >
                    {aisleCategories.map((itemValue, i) => {
                      return (
                        <IonSelectOption
                          key={i}
                          value={itemValue}
                          disabled={false}
                        >
                          {itemValue}
                        </IonSelectOption>
                      );
                    })}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <FormTextarea
                  labelText="Note"
                  type="text"
                  autoGrow={true}
                  name="note"
                  value={shoppingItem.note}
                  onIonChange={handleShoppingItemInput}
                  disabled={isNotEditable ? true : false}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              {isNotEditable ? (
                <></>
              ) : (
                <>
                  {typeof shoppingItem._id === "undefined" ? (
                    <></>
                  ) : (
                    <IonCol size="6">
                      <IonButton
                        color="light"
                        expand="block"
                        onClick={() => setOpen(true)}
                        disabled={isLoading}
                      >
                        DELETE
                      </IonButton>
                    </IonCol>
                  )}

                  <IonCol
                    size={typeof shoppingItem._id === "undefined" ? "12" : "6"}
                  >
                    <IonButton
                      expand="block"
                      onClick={onSubmit}
                      disabled={isLoading}
                    >
                      Save
                    </IonButton>
                  </IonCol>
                </>
              )}
            </IonRow>
          </IonGrid>
          {/* ////////////////////////////////////// ACION SHEEET DELETE CONFIRMATION ////////////////////////////////////// */}
          <IonActionSheet
            isOpen={open}
            buttons={[
              {
                text: "Delete",
                handler: () => removeShoppingItemAndCloseModal(),
                role: "destructive",
                data: {
                  action: "Confirm permanent delete",
                },
              },
              {
                text: "Cancel",
                role: "cancel",
                data: {
                  action: "cancel",
                },
              },
            ]}
            onDidDismiss={() => setOpen(false)}
          ></IonActionSheet>
          <SpaceSoiPhoneKeyboardDoesntCoverInputs>
            {/* Adding the IonBadge is a hack. I need to add a ionic component so I can target that element and change the height */}
            <IonBadge></IonBadge>
          </SpaceSoiPhoneKeyboardDoesntCoverInputs>
        </WebWidthListContainer>
      </IonContent>
    </IonPage>
  );
};

export default ShoppingItemPage;
