import { useHistory } from "react-router-dom";

import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonProgressBar,
  IonSegment,
  IonSegmentButton,
  handleChange,
} from "@ionic/react";

import { useAppContext } from "../context/appContext";

const Header = () => {
  const { isLoading, discoverSelectedPage, handleChange } = useAppContext();

  const history = useHistory();

  const updateDiscoverSelectedPage = (e) => {
    handleChange({
      name: "discoverSelectedPage",
      value: e.target.value,
    });

    history.push(e.target.value);
  };

  return (
    <IonHeader>
      <IonToolbar>
        <IonSegment value={discoverSelectedPage}>
          <IonSegmentButton
            value="/my/discover/menu"
            onClick={updateDiscoverSelectedPage}
            disabled={isLoading ? true : false}
          >
            Feed
          </IonSegmentButton>
          <IonSegmentButton
            value="/my/discover/search"
            onClick={updateDiscoverSelectedPage}
            disabled={isLoading ? true : false}
          >
            Search
          </IonSegmentButton>
          <IonSegmentButton
            value="/my/discover/collection"
            onClick={updateDiscoverSelectedPage}
            disabled={isLoading ? true : false}
          >
            Collection
          </IonSegmentButton>
          {/* <IonSegmentButton
    // value={tabWeeks()[2].week}
    onClick={updateDiscoverSelectedPage}
    // disabled={isLoading ? true : false}
    >
      Search
    </IonSegmentButton> */}
        </IonSegment>
      </IonToolbar>
      {/* {isLoading && <IonProgressBar type="indeterminate"></IonProgressBar>} */}
    </IonHeader>
  );
};

export default Header;
