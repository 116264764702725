import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonItem,
  IonLabel,
  IonList,
  IonRouterLink,
} from "@ionic/react";
import { useAppContext } from "../context/appContext";
import styled from "styled-components";

const WebWidthListContainer = styled.div`
  margin: auto;
  max-width: 750px;
`;

const Profile = () => {
  const { logoutUser, user } = useAppContext();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <WebWidthListContainer>
          {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Profile</IonTitle>
          </IonToolbar>
        </IonHeader> */}
          <div style={{ marginLeft: "16px", marginTop: "26px" }}>
            <h3>{user?.email}</h3>

            {user?.members.map((member, index) => {
              return (
                <>
                  {user?.members[index].calories === null &&
                  user?.members[index].protein === null &&
                  user?.members[index].carbohydrates === null &&
                  user?.members[index].fat === null ? (
                    <></>
                  ) : (
                    <h4 style={{ marginTop: "50px" }}>
                      {user?.members[index].firstName}'s Daily Nutrition
                    </h4>
                  )}
                  <h6>
                    {user?.members[index].calories === null ? (
                      <></>
                    ) : (
                      // `Calories: ${Number(
                      //   user?.members[index].calories
                      // ).toLocaleString()} kcal`
                      `Calories: ${Number(
                        user?.members[index].calories
                      ).toLocaleString()} kcal`
                    )}
                  </h6>
                  <h6>
                    {user?.members[index].protein === null ? (
                      <></>
                    ) : (
                      `Protein: ${user?.members[index].protein} g`
                    )}
                  </h6>
                  <h6>
                    {user?.members[index].carbohydrates === null ? (
                      <></>
                    ) : (
                      `Carbs: ${user?.members[index].carbohydrates} g`
                    )}
                  </h6>
                  <h6>
                    {user?.members[index].fat === null ? (
                      <></>
                    ) : (
                      `Fat: ${user?.members[index].fat} g`
                    )}
                  </h6>
                </>
              );
            })}
          </div>

          <IonList lines="full">
            <IonItem></IonItem>
            <IonItem>
              <IonLabel key="Profile">
                <IonRouterLink color="dark" routerLink="/my/personal-info">
                  Profile
                </IonRouterLink>
              </IonLabel>
            </IonItem>

            <IonItem onClick={logoutUser} key="Log out">
              <IonLabel>Log out</IonLabel>
            </IonItem>
          </IonList>
        </WebWidthListContainer>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
