const recipeYieldList = [
  1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
  18, 19, 20, 21, 22, 23, 24,
];

const shoppingItemUnitCategories = [
  "",
  "heading",
  "cup",
  "quart",
  "gal",
  "lb",
  "oz",
  "tbsp",
  "tsp",
  "g",
  "kg",
  "l",
  "mg",
  "ml",
  "bunch",
  "can",
  "clove",
  "dash",
  "leaf",
  "package",
  "piece",
  "pinch",
  "slice",
  "stick",
  "to taste",
];

const aisleCategories = [
  "",
  "Produce",
  "Meat & seafood",
  "Canned, condiments & jar goods",
  "Baking",
  "Frozen",
  "Dairy, cheese, eggs",
  "Personal Care & Cleaning Supplies",
  "Other",
];

const meatTypes = [
  "",
  "beef",
  "lamb",
  "pork",
  "goat",
  "chicken",
  "turkey",
  "duck",
  "goose",
  "fish",
  "prawns",
  "crab",
  "lobster",
  "mussels",
  "oysters",
  "scallops",
  "clams",
  "squid",
  "octopus",
  "salmon",
  "tofu",
  "vegetarian",
  "Other",
];

const recipeUtensilsList = [
  "",
  "Air Fryer",
  "Aluminum Foil",
  "Large Baking Dish",
  "Medium Baking Dish",
  "Small Baking Dish",
  "Large Baking Sheet",
  "Medium Baking Sheet",
  "Small Baking Sheet",
  "Blender",
  "Large Bowl",
  "Medium Bowl",
  "Small Bowl",
  "Can Opener",
  "Large Cast Iron Skillet",
  "Cast Iron Skillet",
  "Dutch Oven",
  "Colander",
  "Crock Pot",
  "Deep Fryer",
  "Greater",
  "Meat Mallet",
  "Microwave",
  "Large Non-Stick Pan",
  "Non-Stick Pan",
  "Oven",
  "Large Pan",
  "Medium Pan",
  "Small Pan",
  "Paper Towel",
  "Parchment Paper",
  "Peeler",
  "Plastic wrap",
  "Large Pot",
  "Medium Pot",
  "Small Pot",
  "Pot to Steam",
  "Potato Masher",
  "Pressure Cooker",
  "Rice Cooker",
  "Sous Vide",
  "Steamer Basket",
  "Whisk",
  "Wire Rack",
  "Wire-Mesh Strainer",
];

export {
  recipeYieldList,
  shoppingItemUnitCategories,
  aisleCategories,
  meatTypes,
  recipeUtensilsList,
};
