import { useEffect, useState, useRef } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { useAppContext } from "../context/appContext";
import { FormRow, FormRowSelect, ChipAlert, FormTextarea } from "../components";
import { formatQuantity } from "format-quantity";
import Markdown from "react-markdown";
import { InstagramEmbed } from "react-social-media-embed";

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonBackButton,
  IonList,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonCheckbox,
  IonImg,
  IonInput,
  useIonToast,
  IonSelect,
  IonSelectOption,
  IonText,
  IonModal,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonRouterLink,
} from "@ionic/react";

import {
  pencil,
  close,
  checkmark,
  cart,
  arrowBack,
  addCircleOutline,
} from "ionicons/icons";
import { formatTimeAbridge } from "../utils/FormattingUtils";

import {
  useEditRecipeAfterEveryClickRecipeInput,
  makePlural,
  makeUtensilsPlural,
  calculatedQuantity,
  handleInstructionVariables,
  useHandleShoppingItemInput,
} from "../utils/Helpers.js";
import {
  recipeYieldList,
  aisleCategories,
  shoppingItemUnitCategories,
} from "../utils/Constants.js";

const WebWidthListContainer = styled.div`
  margin: auto;
  max-width: 750px;
`;

const StackedDetailsStyledThreeColumn = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: "space-around";

  div {
    width: 33%;
    text-align: center;
  }

  .heading {
    font-weight: bold;
    margin-bottom: 0;
  }

  .value {
    margin-top: 5px;
  }
`;

const StackedDetailsStyledFourColumn = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: "space-around";

  div {
    width: 25%;
    text-align: center;
  }

  .heading {
    font-weight: bold;
    margin-bottom: 0;
  }

  .value {
    margin-top: 5px;
  }
`;

const TwoColumnIonList = styled(IonList)`
  .smallColumn {
    text-align: right;
    white-space: normal;
    flex: none;
    width: 85px;
  }
`;

const IonItemOnBasket = styled(IonItem)`
  --min-height: 0;

  ion-label {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 5px;
    --color: #757575 !important;
    font-style: italic;
  }
`;

const IonIconStyled = styled.div`
  ion-icon.md {
    margin-left: 15px;
  }
  ion-icon.ios {
    margin-left: 25px;
    margin-top: 14px;
  }
`;

const IonModalStyled = styled(IonModal)`
  --height: auto;
`;

const IonImgStyled = styled(IonImg)`
  width: 100%;
  object-fit: cover;
  height: 200px;

  @media (min-width: 350px) {
    height: 250px;
  }

  @media (min-width: 400px) {
    height: 300px;
  }

  @media (min-width: 450px) {
    height: 350px;
  }

  @media (min-width: 500px) {
    height: 400px;
  }

  @media (min-width: 550px) {
    height: 450px;
  }

  @media (min-width: 600px) {
    height: 500px;
  }
`;

const ShoppingItemsHeadingStyled = styled.div`
  display: flex;
  justify-content: "space-between";
  align-items: center;

  p {
    margin-left: auto;
  }
`;

const ViewRecipe = () => {
  const {
    recipeName,
    editRecipeId,
    recipeRating,
    recipeReviewCount,
    recipeSource,
    recipeSourceUrl,
    recipeFeaturedMedia,
    recipeInstagramUrl,
    recipeFeaturedCategory,
    recipeTotalTime,
    recipeCookTime,
    recipeActiveTime,
    recipePrepTime,
    recipeCoolTime,
    recipeFreezeTime,
    recipeMarinateTime,
    recipeRestTime,
    recipeSoakTime,
    recipeYield,
    personalizedYield,
    recipeShoppingItems,
    recipeCalories,
    recipeCarbohydrates,
    recipeFat,
    recipeProtein,
    recipeUtensils,
    recipeVideo,
    recipeNotes,
    recipeInstructions,
    recipeCreatedBy,
    showAlert,
    alertColor,
    alertText,
    isLoading,
    getRecipe,
    shoppingItem,
    createShoppingItem,
    setEditShoppingItemViewRecipePage,
    editShoppingListShoppingItem,
    editRecipeShoppingItemViewRecipePage,
    addRecipeShoppingItemViewRecipePage,
    clearShoppingItemModal,
    removeShoppingListShoppingItem,
    removeRecipeShoppingItemViewRecipePage,
    updatedShoppingItemRecipeQuantityViewRecipePage,
    checkBoxShoppingItemViewRecipePage,
    isEditing,
    // editRecipePersonalizedYield,
    setEditServingUpdateServing,
    setEditServingAddRecipe,
    mealPlanSchedule,
    handleChange,
    displayAlert,
    user,
    clearRecipeValues,
  } = useAppContext();

  const history = useHistory();

  const location = useLocation();

  const isMenuPage = location.pathname.includes("/menu");
  const isSearchPage = location.pathname.includes("/search");
  const isFromMealPlanPage = location.pathname.includes("/meal-plan");
  const isCollectionCreateRecipeOrRoodRecipeViewPage =
    location.pathname.includes(
      "/my/discover/collection/create-recipe-or-food/recipe/"
    );
  const isFromCollectionPage = location.pathname.includes("/collection");

  const { useEditRecipeAfterEveryClick } =
    useEditRecipeAfterEveryClickRecipeInput();
  const { handleShoppingItemInput } = useHandleShoppingItemInput();

  // const ShoppingItemsHeadingStyled = styled.div`
  //   display: flex;
  //   justify-content: "space-between";
  //   align-items: center;

  //   p {
  //     margin-left: auto;
  //   }
  // `;

  const params = useParams();

  const [present] = useIonToast();

  // useIonViewDidEnter(() => {
  //   console.log("View Recipe useIonViewDidEnter params.id:");
  //   console.log(params.id);
  //   console.log("View Recipe  useIonViewDidEnter editRecipeId:");
  //   console.log(editRecipeId);

  //   if (editRecipeId === "") {
  //     getRecipe(params.id);
  //     console.log("getRecipe(params.id)");
  //   } else {
  //     getRecipe(editRecipeId);
  //     console.log("getRecipe(editRecipeId)");
  //   }
  // });

  // useIonViewDidLeave(() => {
  //   clearRecipeValues();
  // });

  // // if the user enters the page directly, the back button will not show up. Therefore, this will put a back button to the homepage
  const [
    userDirectlyFromURLSoFirstPageEver,
    setUserDirectlyFromURLSoFirstPageEver,
  ] = useState(false);

  useIonViewDidEnter(() => {
    setUserDirectlyFromURLSoFirstPageEver(false);
  });

  if (history.action !== "POP") {
  } else {
    // without the timeout two back buttons appear when clicking back in the real back button
    setTimeout(() => {
      setUserDirectlyFromURLSoFirstPageEver(true);
    }, 1000);
  }

  useEffect(() => {
    console.log("useEffect(() =>");
    console.log("useEffect(() =>history.action");
    console.log(history.action);

    console.log("useEffect(() => editRecipeId");
    console.log(editRecipeId);

    if (history.action === "POP") {
      console.log("getRecipe(params.id)");
      getRecipe(params.id);
    } else if (editRecipeId === "") {
      // don't do anything
    } else {
      console.log("getRecipe(editRecipeId)");
      getRecipe(editRecipeId);
    }

    // if (editRecipeId === "") {
    //   console.log("getRecipe(params.id)");
    //   getRecipe(params.id);
    // } else {
    //   console.log("getRecipe(editRecipeId)");
    //   getRecipe(editRecipeId);
    // }
  }, [editRecipeId]);

  const presentToast = () => {
    present({
      message: alertText,
      duration: 1500,
      position: "top",
      color: alertColor,
    });
  };

  if (showAlert) {
    presentToast();
  }

  // // to do: find a way to display the serving multiplier somehow
  // let recipeYieldListWithOriginalLabel = [];

  // function addLabelOriginalYield(yieldOption, index) {
  //   if (yieldOption === recipeYield) {
  //     recipeYieldListWithOriginalLabel.push(`${yieldOption}⠀(1x)`);
  //   } else {
  //     recipeYieldListWithOriginalLabel.push(yieldOption);
  //   }
  // }
  // recipeYieldList.forEach(addLabelOriginalYield);

  // modal
  const [open, setOpen] = useState(false);

  const setEditShoppingItemAndSetOpen = (id) => {
    setEditShoppingItemViewRecipePage(id);
    if (isFromCollectionPage) {
      history.push("/my/discover/collection/recipe/shopping-item/edit");
    } else if (isFromMealPlanPage) {
      history.push("/my/meal-plan/recipe/shopping-item/edit");
    } else {
      history.push("/my/recipe/shopping-item/edit");
    }
    // setOpen(true);
  };

  const removeShoppingItemAndCloseModal = () => {
    removeRecipeShoppingItemViewRecipePage();
    setOpen(false);
  };

  const clearShoppingItemAndOpenModal = () => {
    clearShoppingItemModal();
    // history.push("/my/recipe/shopping-item/add");

    if (isFromCollectionPage) {
      history.push("/my/discover/collection/recipe/shopping-item/add");
    } else if (isFromMealPlanPage) {
      history.push("/my/meal-plan/recipe/shopping-item/add");
    } else {
      console.log("I don't think I need this route anymore");
      // history.push("/my/recipe/shopping-item/add");
    }
  };

  const [updatePersonalizeYieldWarning, setUpdatePersonalizeYieldWarning] =
    useState(false);

  const [query, setQuery] = useState(null);

  const inputRefPersonalizedYield = useRef(null);

  // const setQueryTheFirstTime = (e) => {
  //   console.log("setQueryTheFirstTime personalizedYield");
  //   console.log(personalizedYield);
  //   setQuery(personalizedYield);

  //   // setInitialQueryCounter(1);
  // };

  const handleChangePersonalizedYield = (e) => {
    // console.log("hellloooooooooooooooooooooooooooo World!");
    // console.log("handleChangePersonalizedYield setQuery(e.target.value)");
    // console.log("e.target.value");
    // console.log(e.target.value);

    // console.log("handleChangePersonalizedYield personalizedYield");
    // console.log(personalizedYield);

    // if (query === e.target.value) {
    //   console.log("it's the same so don't do anything");
    // } else {
    //   setQuery(e.target.value);

    //   if (isFromMealPlanPage) {
    //     setUpdatePersonalizeYieldWarning(true);
    //   }
    // }

    // // original code start
    setQuery(e.target.value);

    // if (isFromMealPlanPage) {
    //   setUpdatePersonalizeYieldWarning(true);
    // }
    // // original code end
  };

  const ShowUpdatePersonalizeYieldWarning = () => {
    if (isFromMealPlanPage) {
      setUpdatePersonalizeYieldWarning(true);
    }
  };

  // useEffect(() => {
  //   console.log("useEffect setQuery(personalizedYield)");
  //   console.log("personalizedYield");
  //   console.log(personalizedYield);
  //   console.log("typeof personalizedYield");
  //   console.log(typeof personalizedYield);

  //   setQuery(personalizedYield);
  // }, []);

  useEffect(() => {
    // inputRefPersonalizedYield.current.setFocus();

    const timeOutId = setTimeout(() => {
      // it's null if it hasen't been set
      if (query === null) {
      } else if (query == "" || query > 0) {
        handleChange({
          name: "personalizedYield",
          value: query,
        });
      } else if (query <= 0) {
        displayAlert("Serving has to be greater than zero");
      }
    }, 2000);

    return () => clearTimeout(timeOutId);
  }, [query]);

  const handleSetEditServingUpdateServing = (
    editRecipeId,
    personalizedYield,
    recipeName
  ) => {
    if (mealPlanSchedule.length == 0) {
      // if it's empty it mean the user entered this route directly and the App doesn't know the existing meal plan schedule. In the future I can do a get meal plan schedule by meal plan ID but for now I'll just redirect back to the meal plan page

      history.push("/my/meal-plan");
    } else {
      setEditServingUpdateServing(editRecipeId, personalizedYield, recipeName);

      history.push("/my/serving/edit");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (typeof shoppingItem._id === "undefined") {
      addRecipeShoppingItemViewRecipePage();
      setOpen(false);
      return;
    } else {
      editRecipeShoppingItemViewRecipePage();
      setOpen(false);
      return;
    }
  };

  const shoppingOrItemName = (recipeShoppingItem) => {
    if (
      typeof recipeShoppingItem.name === "undefined" ||
      recipeShoppingItem.name === ""
    ) {
      if (typeof recipeShoppingItem.item !== "undefined") {
        // it's null if the backend returns null since the item id doesn't exist
        if (recipeShoppingItem.item == null) {
          return "not found";
        } else {
          return recipeShoppingItem.item.name;
        }
      }
    } else {
      return recipeShoppingItem.name;
    }
  };

  const shoppingOrItemAisle = (recipeShoppingItem) => {
    if (typeof recipeShoppingItem.aisle === "undefined") {
      if (typeof recipeShoppingItem.item !== "undefined") {
        return recipeShoppingItem.item.aisle;
      }
    } else {
      return recipeShoppingItem.aisle;
    }
  };

  if (isLoading === true) {
    <div>Loading...</div>;
  } else {
  }

  const recipeUtensilsText = recipeUtensils.map((utensil, index) => {
    const recipeUtensilsListLength = recipeUtensils.length - 1;

    const updateUtensil = makeUtensilsPlural(utensil.utensil, utensil.quantity);

    // don't include a dot on the last item or if there is only one item
    if (recipeUtensilsListLength === index) {
      return `${utensil.quantity} ${updateUtensil}`;
    }

    return `${utensil.quantity} ${updateUtensil} •  `;
  });

  const goBack = () => {
    if (isCollectionCreateRecipeOrRoodRecipeViewPage === true) {
      // I need to go back three times here. From /my/discover/collection/create-recipe-or-food/recipe/:id back to /my/discover/collection/create-recipe-or-food back to /my/discover/collection. This is the best approach since when I used push for this, I would get a bug. To replicate the history bug do 1) create a recipe in collection page. 2) go back to collection page, 3) create another recipe. Result: The second recipe would not show in the Collection View Recipe page. The first one does which is wrong.
      // history.goBack();
      // history.goBack();
      // history.goBack();

      history.push("/my/discover/collection");

      clearRecipeValues();
      // console.log("if (isCollectionCreateRecipeOrRoodRecipeViewPage === true)");
    } else {
      clearRecipeValues();
      console.log("history.goBack()");

      history.goBack();
    }
  };

  const goBackWhenNoHistory = () => {
    // this goBackWhenNoHistory is not true always. sometimes this triggers.
    if (isMenuPage) {
      // console.log("************* push /my/discover/menu");
      history.push("/my/discover/menu");
      clearRecipeValues();
    } else if (isSearchPage) {
      // console.log("************* push /my/discover/search");
      history.push("/my/discover/search");
      clearRecipeValues();
    } else if (isFromMealPlanPage) {
      // console.log("************* push /my/meal-plan");
      history.push("/my/meal-plan");
      clearRecipeValues();
    } else if (isFromCollectionPage) {
      // console.log("************* push /my/discover/collection");

      history.push("/my/discover/collection");
      clearRecipeValues();
    } else {
      // console.log("************* push /my/discover/menu");
      history.push("/my/discover/menu");
      // history.push("/my/discover/search");

      clearRecipeValues();
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {userDirectlyFromURLSoFirstPageEver === true ? (
              <IonBackButton
                onClick={goBackWhenNoHistory}
                // having defaultHref set to # is a hack to for the back button to always appear when there is not history, but instead of routing to a default URL, i'm routing to a different location pased on path/ page and doing clearRecipeValues
                defaultHref={"#"}
              />
            ) : (
              <IonBackButton onClick={goBack} />
            )}
          </IonButtons>
          <IonTitle></IonTitle>

          {(isFromMealPlanPage === true || isFromCollectionPage === true) &&
          recipeCreatedBy === user._id ? (
            <IonButtons slot="primary">
              <IonButton
                color="primary"
                fill="solid"
                routerLink={
                  isFromMealPlanPage
                    ? "/my/meal-plan/edit-recipe-or-food"
                    : "/my/discover/collection/edit-recipe-or-food"
                }
                // onClick={() => getRecipe(editRecipeId)}
              >
                <IonIcon icon={pencil}></IonIcon>
              </IonButton>
            </IonButtons>
          ) : (
            <></>
          )}

          {isFromMealPlanPage === true ? (
            <></>
          ) : (
            <IonButtons slot="primary">
              <IonButton
                color="primary"
                fill="solid"
                routerLink="/my/serving/add"
                onClick={() =>
                  setEditServingAddRecipe(
                    editRecipeId,
                    personalizedYield,
                    recipeName
                  )
                }
              >
                Add
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <WebWidthListContainer>
          <div className="ion-padding" style={{ paddingBottom: "0" }}>
            <h1 style={{ fontWeight: "700" }}>{recipeName}</h1>

            <p style={{ marginTop: "0px" }}>
              {/* rating section */}
              {recipeRating === null || recipeRating === "" ? (
                <></>
              ) : (
                <span>
                  {recipeRating} ★ ({recipeReviewCount})
                  <span style={{ color: "#D3D3D3" }}>
                    &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
              )}
              {/* recipe source section */}
              {recipeSource === "" ? (
                <></>
              ) : (
                <>
                  By&nbsp;
                  <a
                    href={recipeSourceUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#000" }}
                  >
                    {recipeSource}
                  </a>
                </>
              )}

              {/* recipe categories section */}
              {recipeFeaturedCategory === "" ? (
                <></>
              ) : (
                <>
                  <span style={{ color: "#D3D3D3" }}>
                    &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                  </span>
                  {recipeFeaturedCategory}
                </>
              )}
            </p>
          </div>

          {/* display featured media. Don't display if blank. Don't display if intagram link exists. */}
          {recipeFeaturedMedia === "" ? (
            <></>
          ) : recipeInstagramUrl === "" ||
            typeof recipeInstagramUrl === "undefined" ? (
            <IonImgStyled src={recipeFeaturedMedia}></IonImgStyled>
          ) : (
            <></>
          )}

          {/* display instagram */}
          {recipeInstagramUrl === "" ||
          typeof recipeInstagramUrl === "undefined" ? (
            <></>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <InstagramEmbed url={recipeInstagramUrl} width={"100%"} />
            </div>
          )}
          <StackedDetailsStyledThreeColumn>
            {recipeTotalTime === null ? (
              <></>
            ) : (
              <div>
                <p className="heading">Total Time</p>
                <p className="value">{formatTimeAbridge(recipeTotalTime)}</p>
              </div>
            )}
            {recipePrepTime === null ? (
              <></>
            ) : (
              <div>
                <p className="heading">Prep Time</p>
                <p className="value">{formatTimeAbridge(recipePrepTime)}</p>
              </div>
            )}
            {recipeCookTime === null ? (
              <></>
            ) : (
              <div>
                <p className="heading">Cook Time</p>
                <p className="value">{formatTimeAbridge(recipeCookTime)}</p>
              </div>
            )}
            {recipeActiveTime === null ? (
              <></>
            ) : (
              <div>
                <p className="heading">Active Time</p>
                <p className="value">{formatTimeAbridge(recipeActiveTime)}</p>
              </div>
            )}
            {recipeCoolTime === null ? (
              <></>
            ) : (
              <div>
                <p className="heading">Cool Time</p>
                <p className="value">{formatTimeAbridge(recipeCoolTime)}</p>
              </div>
            )}
            {recipeFreezeTime === null ? (
              <></>
            ) : (
              <div>
                <p className="heading">Freeze Time</p>
                <p className="value">{formatTimeAbridge(recipeFreezeTime)}</p>
              </div>
            )}
            {recipeMarinateTime === null ? (
              <></>
            ) : (
              <div>
                <p className="heading">Marinate Time</p>
                <p className="value">{formatTimeAbridge(recipeMarinateTime)}</p>
              </div>
            )}
            {recipeRestTime === null ? (
              <></>
            ) : (
              <div>
                <p className="heading">Rest Time</p>
                <p className="value">{formatTimeAbridge(recipeRestTime)}</p>
              </div>
            )}
            {recipeSoakTime === null ? (
              <></>
            ) : (
              <div>
                <p className="heading">Soak Time</p>
                <p className="value">{formatTimeAbridge(recipeSoakTime)}</p>
              </div>
            )}
          </StackedDetailsStyledThreeColumn>
          <ShoppingItemsHeadingStyled
            key="viewRecipe"
            className="ion-padding"
            style={{ paddingTop: "0", paddingBottom: "0" }}
          >
            {/* checking if recipeName is blank is my workaround to have a blank page while the page load . recipeName is requried so I know it should always load eventually */}
            {recipeName === "" ? (
              <></>
            ) : (
              <>
                <h2 style={{ fontWeight: "700" }}>Ingredients</h2>

                <p style={{ marginBottom: "5px" }}>Servings</p>
                <IonItem>
                  <IonInput
                    style={{
                      // minWidth: "50px",
                      width: "50px",
                      paddingBottom: "0px",
                      padding: "0px",
                    }}
                    type="number"
                    name="personalizedYield"
                    ref={inputRefPersonalizedYield}
                    // value={query}
                    value={query === null ? personalizedYield : query}
                    // onClick={setQueryTheFirstTime}
                    onClick={ShowUpdatePersonalizeYieldWarning}
                    onIonChange={handleChangePersonalizedYield}
                    // onIonChange={
                    //   query === null || personalizedYield === ""
                    //     ? () => null
                    //     : handleChangePersonalizedYield
                    // }

                    // onIonChange={
                    //   query === null || personalizedYield === ""
                    //     ? () => null
                    //     : handleChangePersonalizedYield
                    // }
                  />
                </IonItem>

                <p
                  style={{
                    width:
                      personalizedYield / recipeYield === 1 ? "0px" : "35px",
                    marginBottom: "5px",
                    marginLeft: "10px",
                    marginRight: "-5px",
                  }}
                >
                  {personalizedYield / recipeYield === 1 ? (
                    <></>
                  ) : (
                    `${
                      Math.round((personalizedYield / recipeYield) * 10) / 10
                    }x`
                  )}
                </p>
              </>
            )}
          </ShoppingItemsHeadingStyled>
          {/* this warning is only if the recipe is in the meal plan page */}
          {updatePersonalizeYieldWarning === true ? (
            <div
              key="updatePersonalizeYieldWarningKey"
              className="ion-padding"
              style={{
                height: "30px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <p style={{ marginLeft: "auto" }}>
                <a
                  onClick={() =>
                    handleSetEditServingUpdateServing(
                      editRecipeId,
                      personalizedYield,
                      recipeName
                    )
                  }
                >
                  Save{" "}
                </a>
                or
                <a onClick={() => setUpdatePersonalizeYieldWarning(false)}>
                  {" "}
                  Dismiss
                </a>
              </p>
            </div>
          ) : (
            <></>
          )}
          <TwoColumnIonList lines="full">
            {recipeShoppingItems.length == 0 ? (
              <></>
            ) : (
              <IonItemOnBasket lines="none">
                <IonIcon icon={cart}></IonIcon>
                <IonLabel>Check ingredients you have</IonLabel>
              </IonItemOnBasket>
            )}

            {recipeShoppingItems.length == 0 ? (
              <></>
            ) : (
              <>
                {recipeShoppingItems.map((recipeShoppingItem, index) => {
                  // let shoppingOrItemName = "";
                  //
                  // if (
                  //   typeof recipeShoppingItem.name === "undefined" ||
                  //   recipeShoppingItem.name === ""
                  // ) {
                  //   if (typeof recipeShoppingItem.item !== "undefined") {
                  //     shoppingOrItemName = recipeShoppingItem.item.name;
                  //   }
                  //   // shoppingOrItemName = recipeShoppingItem.item.name;
                  // } else {
                  //   shoppingOrItemName = recipeShoppingItem.name;
                  // }

                  const hidden = recipeShoppingItem.hidden;

                  const editable =
                    typeof recipeShoppingItem.item === "undefined"
                      ? true
                      : false;

                  const shopppingItemCalculatedQuantity = calculatedQuantity(
                    personalizedYield,
                    recipeYield,
                    recipeShoppingItem.quantity
                  );

                  // TEMP DON"T MOVE TO VIEW RECIPE
                  // personalizedYield doesn't apply to shoppingItems not in a recipe so need to set to 1 if it doesn't exist
                  // const adaptedPersonalizedYield =
                  //   typeof recipeShoppingItem.personalizedYield === "undefined"
                  //     ? 1
                  //     : recipeShoppingItem.personalizedYield;

                  if (recipeShoppingItem.unit === "heading") {
                    return (
                      <IonItem key={index} detail={false}>
                        <IonLabel>
                          <p
                            style={{
                              fontWeight: "600",
                              fontSize: "16px",
                              color: "#000",
                            }}
                          >
                            {shoppingOrItemName(recipeShoppingItem)}
                            {/* {editable
                        ? recipeShoppingItem.name
                        : recipeShoppingItem.item.name} */}
                          </p>
                        </IonLabel>
                      </IonItem>
                    );
                  } else if (recipeShoppingItem.hidden === true) {
                    return <></>;
                  } else {
                    return (
                      <>
                        <IonItem button detail={false} key={`checkbox${index}`}>
                          <IonCheckbox
                            style={{
                              marginLeft: "0px",
                              marginRight: "10px",
                            }}
                            slot="start"
                            name="procureShoppingItem"
                            index={index}
                            _id={recipeShoppingItem._id}
                            // disabled={
                            //   isLoading === true || isFromMealPlanPage === false
                            //     ? true
                            //     : false
                            // }
                            // checked={
                            //   hidden
                            //     ? false
                            //     : recipeShoppingItem.procureShoppingItem
                            // }
                            // onClick={checkBoxShoppingItemViewRecipePage}
                          ></IonCheckbox>
                          <IonImg
                            index={index}
                            src={
                              typeof recipeShoppingItem.item === "undefined" ||
                              recipeShoppingItem.item == null
                                ? "https://my-recipa-app-images.s3.us-west-1.amazonaws.com/app-assets/white-bag.jpg"
                                : recipeShoppingItem.item.image
                            }
                            style={{
                              height: "100%",
                              width: "40px",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}
                            onClick={() =>
                              setEditShoppingItemAndSetOpen(
                                recipeShoppingItem._id
                              )
                            }
                          ></IonImg>
                          {/* Refactor: Without this IonLabel display none, whenever the ShoppingItem has a long name, it will makes it so it's not possible to click the first few letters of the name. Use the inspector toolt to notice it. */}
                          <IonLabel style={{ display: "none" }}></IonLabel>
                          <IonLabel
                            class="ion-text-wrap"
                            onClick={() =>
                              setEditShoppingItemAndSetOpen(
                                recipeShoppingItem._id
                              )
                            }
                            style={{
                              display: "flex",
                              height: "100%",
                              margin: "0",
                              alignItems: "center",
                            }}
                            index={index}
                          >
                            <IonText>
                              {shoppingOrItemName(recipeShoppingItem)}
                              {recipeShoppingItem.note === "" ||
                              typeof recipeShoppingItem.note === "undefined" ? (
                                <></>
                              ) : (
                                <IonText
                                  color="medium"
                                  style={{
                                    marginLeft: "5px",
                                  }}
                                >
                                  {`(${recipeShoppingItem.note})`}
                                </IonText>
                              )}
                            </IonText>
                          </IonLabel>
                          <IonLabel
                            onClick={() =>
                              setEditShoppingItemAndSetOpen(
                                recipeShoppingItem._id
                              )
                            }
                            style={
                              hidden
                                ? {
                                    textDecoration: "line-through",
                                    margin: "0",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                  }
                                : {
                                    margin: "0",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "end",
                                  }
                            }
                            className="smallColumn"
                          >
                            {shopppingItemCalculatedQuantity}{" "}
                            {makePlural(
                              recipeShoppingItem.unit,
                              shopppingItemCalculatedQuantity
                            )}
                          </IonLabel>
                        </IonItem>
                      </>
                    );
                  }
                })}
              </>
            )}
          </TwoColumnIonList>

          {/* checking if recipeName is blank is my workaround to have a blank page while the page load . recipeName is requried so I know it should always load eventually */}
          {recipeName === "" ? (
            <></>
          ) : (
            <>
              {isFromMealPlanPage === true || isFromCollectionPage ? (
                <IonIconStyled>
                  {/* <IonRouterLink routerLink={`/my/shopping-item`}> */}
                  <IonIcon
                    icon={addCircleOutline}
                    onClick={() => clearShoppingItemAndOpenModal()}

                    // onClick={() => clearShoppingItemAndOpenModal()}
                  ></IonIcon>
                  {/* </IonRouterLink> */}
                </IonIconStyled>
              ) : (
                <></>
              )}
            </>
          )}

          {recipeCalories === null &&
          recipeFat === null &&
          recipeCarbohydrates === null &&
          recipeProtein === null ? (
            <></>
          ) : (
            <h2
              className="ion-padding"
              style={{ paddingTop: "0", paddingBottom: "0", fontWeight: "700" }}
            >
              Nutrition
            </h2>
          )}

          <StackedDetailsStyledFourColumn>
            {recipeCalories === null ? (
              <></>
            ) : (
              <div>
                <p className="heading">Calories</p>
                <p className="value">{recipeCalories.toLocaleString()}</p>
              </div>
            )}
            {recipeFat === null ? (
              <></>
            ) : (
              <div>
                <p className="heading">Fat</p>
                <p className="value">{recipeFat} g</p>
              </div>
            )}
            {recipeCarbohydrates === null ? (
              <></>
            ) : (
              <div>
                <p className="heading">Carbs</p>
                <p className="value">{recipeCarbohydrates} g</p>
              </div>
            )}
            {recipeProtein === null ? (
              <></>
            ) : (
              <div>
                <p className="heading">Protein</p>
                <p className="value">{recipeProtein} g</p>
              </div>
            )}
          </StackedDetailsStyledFourColumn>
          {recipeUtensils.length === 0 ? (
            <></>
          ) : (
            <>
              <h2
                className="ion-padding"
                style={{
                  paddingTop: "0",
                  paddingBottom: "0",
                  fontWeight: "700",
                }}
              >
                Utensils
              </h2>
              <div className="ion-padding">
                <p
                  style={{
                    lineHeight: "1.5",
                    marginTop: "0",
                    marginBottom: "0",
                  }}
                >
                  {recipeUtensilsText}
                </p>
              </div>
            </>
          )}
          {recipeNotes == "" ||
          recipeNotes == " " ||
          typeof recipeNotes === "undefined" ? (
            <></>
          ) : (
            <div
              className="ion-padding"
              style={{
                paddingTop: "0",
                paddingBottom: "0",
              }}
            >
              <h2
                style={{
                  fontWeight: "700",
                }}
              >
                My Notes
              </h2>

              <Markdown>{recipeNotes}</Markdown>
            </div>
          )}

          {recipeInstructions.length == 0 ? (
            <></>
          ) : (
            <h2
              className="ion-padding"
              style={{ paddingTop: "0", paddingBottom: "0", fontWeight: "700" }}
            >
              Instructions
            </h2>
          )}

          {recipeVideo === "" || typeof recipeVideo === "undefined" ? (
            <></>
          ) : (
            <div
              style={{
                position: "relative",
                paddingBottom: "56.25%",
                overflow: "hidden",
                marginBottom: "16px",
              }}
            >
              <iframe
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
                src={recipeVideo}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          )}
          {/* <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/C5Hq4mjMJQM?si=pSAkPY-TVN6wn-i6"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe> */}
          <IonList>
            {recipeInstructions.length === 0 ? (
              <>
                {recipeSourceUrl === "" ? (
                  <></>
                ) : (
                  <div className="ion-padding" style={{ paddingTop: "0px" }}>
                    <IonButton
                      style={{ marginTop: "0px" }}
                      href={recipeSourceUrl}
                      expand="block"
                      class="ion-margin-top"
                      target="_blank"
                      // onClick={onSubmit}
                      disabled={isLoading}
                    >
                      {recipeSource === ""
                        ? "View recipe or food"
                        : `View on ${recipeSource}`}
                    </IonButton>
                  </div>
                )}
              </>
            ) : (
              recipeInstructions.map((recipeInstruction, index) => {
                return (
                  <div key={index}>
                    {recipeInstruction.media === "" ? (
                      <div></div>
                    ) : (
                      <IonItem lines="none" key={`media${index}`}>
                        <IonImg
                          src={recipeInstruction.media}
                          style={{ marginTop: "20px" }}
                        ></IonImg>
                      </IonItem>
                    )}
                    <IonItem
                      // UI Bug: When I try to align the number to the top of the row it gets fixed with marginTop 40px for Android but not iOS
                      // style={{ display: "flex", alignItems: "start" }}
                      key={`instruction${index}`}
                    >
                      <p
                        style={{
                          fontWeight: "700",
                          marginRight: "10px",
                          // marginTop: "40px",
                        }}
                      >
                        {index + 1}.
                      </p>
                      <p style={{ whiteSpace: "normal" }}>
                        <Markdown>
                          {handleInstructionVariables(
                            recipeInstruction.description,
                            recipeShoppingItems,
                            personalizedYield
                          )}
                        </Markdown>
                      </p>
                    </IonItem>
                  </div>
                );
              })
            )}
          </IonList>
          <IonModalStyled
            isOpen={open}
            onDidDismiss={() => setOpen(false)}
            breakpoints={(0, 1)}
            initialBreakpoint={1}
            backdropBreakpoint={0}
          >
            <IonHeader>
              <IonToolbar>
                {/* <IonTitle>Add</IonTitle> */}
                <IonTitle>
                  {typeof shoppingItem._id === "undefined" ? "Add" : "Edit"}
                </IonTitle>

                <IonButtons slot="end">
                  {/* <IonButton onClick={() => closeModal()}>Close</IonButton> */}
                  <IonIcon
                    onClick={() => setOpen(false)}
                    icon={close}
                    size="large"
                    style={{ height: "26px" }}
                  ></IonIcon>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            {/* <IonGrid>
            <IonRow>
              <IonCol size="3">
                <FormRow
                  labelText="Amount"
                  placeholder="1"
                  type="text"
                  name="test1"
                />
              </IonCol>
              <IonCol size="9">
                <FormRow
                  labelText="Unit"
                  placeholder="tbsp (optional)"
                  type="text"
                  name="test1"
                />
              </IonCol>
            </IonRow>
          </IonGrid> */}

            {/* <IonList> */}
            <IonGrid style={{ width: "100%" }}>
              <IonRow>
                <IonCol size="12">
                  {shoppingItem.recipeId ? (
                    <FormRow
                      labelText="Recipe"
                      type="text"
                      name="recipe"
                      // placeholder="For 4 Honeycrisp Apple Salad"
                      disabled={true}
                      value={`For ${shoppingItem.personalizedYield} servings of ${shoppingItem.recipeName}`}
                    />
                  ) : (
                    <></>
                  )}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="6">
                  <FormRow
                    labelText="Quantity (Required*)"
                    type="number"
                    name="quantity"
                    value={
                      shoppingItem.quantity * personalizedYield === 0
                        ? ""
                        : calculatedQuantity(
                            personalizedYield,
                            recipeYield,
                            shoppingItem.quantity
                          )
                    }
                    onIonChange={
                      updatedShoppingItemRecipeQuantityViewRecipePage
                    }
                  />
                </IonCol>

                <IonCol size="6">
                  <IonItem>
                    <IonLabel position="stacked">Unit</IonLabel>

                    <IonSelect
                      // style={{ width: "100%" }}
                      interface="action-sheet"
                      name="unit"
                      label="Stacked label"
                      labelPlacement="stacked"
                      // placeholder="cup (optional)"
                      value={shoppingItem.unit}
                      onIonChange={handleShoppingItemInput}
                    >
                      {shoppingItemUnitCategories.map((itemValue, i) => {
                        if (itemValue === "heading") {
                          return null;
                          // return (
                          //   <IonSelectOption
                          //     e={"yo"}
                          //     key={i}
                          //     value={itemValue}
                          //     disabled={false}
                          //   >
                          //     {itemValue}
                          //   </IonSelectOption>
                          // );
                        } else {
                          return (
                            <IonSelectOption
                              e={"yo"}
                              key={i}
                              value={itemValue}
                              disabled={false}
                            >
                              {itemValue}
                            </IonSelectOption>
                          );
                        }
                      })}
                    </IonSelect>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12">
                  <FormRow
                    labelText="Name (Required*)"
                    type="text"
                    name="name"
                    // placeholder="apple"
                    disabled={
                      typeof shoppingItem.item === "undefined" ? false : true
                    }
                    value={shoppingOrItemName(shoppingItem)}
                    onIonChange={handleShoppingItemInput}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12">
                  <IonItem>
                    <IonLabel position="stacked">Aisle</IonLabel>
                    <IonSelect
                      interface="action-sheet"
                      name="aisle"
                      label="Stacked label"
                      labelPlacement="stacked"
                      disabled={
                        typeof shoppingItem.item === "undefined" ? false : true
                      }
                      // placeholder="produce (optional)"
                      // onIonChange={handleShoppingItemInput}
                      value={shoppingOrItemAisle(shoppingItem)}
                    >
                      {aisleCategories.map((itemValue, i) => {
                        return (
                          <IonSelectOption
                            e={"yo"}
                            key={i}
                            value={itemValue}
                            disabled={false}
                          >
                            {itemValue}
                          </IonSelectOption>
                        );
                      })}
                    </IonSelect>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12">
                  <FormRow
                    labelText="Note"
                    type="text"
                    name="note"
                    // placeholder="sliced (optional)"
                    value={shoppingItem.note}
                    onIonChange={handleShoppingItemInput}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12">
                  {isEditing && (
                    <IonButton
                      color="light"
                      expand="block"
                      onClick={() => removeShoppingItemAndCloseModal()}
                      disabled={isLoading}
                    >
                      Remove
                    </IonButton>
                  )}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12">
                  {/* {showAlert && <ChipAlert />} */}
                  <IonButton
                    expand="block"
                    onClick={onSubmit}
                    disabled={isLoading}
                  >
                    Save
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
            {/* </IonList> */}
          </IonModalStyled>
          {/* {showAlert && presentToast()} */}
        </WebWidthListContainer>
      </IonContent>
    </IonPage>
  );
};

export default ViewRecipe;
