import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useAppContext } from "../context/appContext";
import { FormRow, FormRowSelect, ChipAlert } from "../components";

import {
  recipeYieldList,
  shoppingItemUnitCategories,
  aisleCategories,
} from "../utils/Constants.js";

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonBackButton,
  IonList,
  IonItem,
  IonLabel,
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
  IonCheckbox,
  IonImg,
  IonInput,
  useIonToast,
  IonSelect,
  IonSelectOption,
  useIonViewDidEnter,
  IonFab,
  IonFabButton,
  IonModal,
  IonText,
  IonSegment,
  IonSegmentButton,
  IonRadioGroup,
  IonRadio,
  IonToggle,
} from "@ionic/react";

import {
  pencil,
  close,
  checkmark,
  cart,
  arrowBack,
  filter,
  add,
  ellipsisVertical,
  copy,
} from "ionicons/icons";

import "./action-sheet.css";

import { formatTimeAbridge } from "../utils/FormattingUtils";

import { tabWeeks } from "../utils/FormattingUtils";

import {
  useEditRecipeAfterEveryClickRecipeInput,
  makePlural,
  calculatedQuantity,
  handleInstructionVariables,
  useHandleShoppingItemInput,
} from "../utils/Helpers.js";

const WebWidthListContainer = styled.div`
  margin: auto;
  max-width: 750px;
`;

const IonFabStyled = styled(IonFab)`
  @media (min-width: 875px) {
    margin-right: 25px;
  }
`;

const TwoColumnIonList = styled(IonList)`
  .smallColumn {
    text-align: right;
    white-space: normal;
    flex: none;
    width: 85px;
  }
`;

const IonSelectStyled = styled(IonSelect)`
::ng-deep .action-sheet-group {
  --height: 400px;
  background: #e5e5e5;
  color: blue;
  --border-color: red;

  .sc-ion-action-sheet-md-h .sc-ion-action-sheet-md-s .select-action-sheet {
    --height: 400px !important;
    --color: red:
    --border-color: red;
  }

  .sc-ion-action-sheet-md-h {
    --height: 400px !important;
    --color: red:
    --border-color: red;
  }

  .sc-ion-action-sheet-md-s {
    --height: 400px !important;
    --color: red:
    --border-color: red;
  }

  .select-action-sheet {
    --height: 400px !important;
    --color: red:
    --border-color: red;
  }
  .action-sheet-wrapper .ion-overlay-wrapper .sc-ion-action-sheet-md {
    --height: 400px !important;
    --color: red:
    --border-color: red;
  }

  .action-sheet-wrapper {
    --height: 400px !important;
    --color: red:
    --border-color: red;
  }

  .ion-overlay-wrapper {
    --height: 400px !important;
    --color: red:
    --border-color: red;
  }
  .sc-ion-action-sheet-md {
    --height: 400px !important;
    --color: red:
    --border-color: red;
  }
  .action-sheet-container {
    --height: 400px !important;
    --color: red:
    --border-color: red;
  }

  .sc-ion-action-sheet-md {
    --height: 400px !important;
    --color: red:
    --border-color: red;
  }

  .action-sheet-group {
    --height: 400px !important;
    --color: red:
    --border-color: red;
  }

  .sc-ion-action-sheet-md {
    --height: 400px !important;
    --color: red:
    --border-color: red;
  }
}
  
`;

// const IonItemStyled = styled(IonItem)`
//   IonCheckbox::part(container) {
//     --border-color: blue !important;
//   }

//   IonCheckbox::part(label) {
//     display: none;
//     width: 0x !important;
//     left: 0px !important;
//     color: blue !important;
//   }
// `;

const ShoppingList = () => {
  const {
    showAlert,
    alertColor,
    alertText,
    isLoading,
    recipeYield,
    getShopping,
    shoppingList,
    shoppingListGroupCategory,
    shoppingListHideCheckedShoppingItem,
    updateShoppingListGroupCategory,
    updateShoppingListHideCheckedShoppingItem,
    displayAlert,
    shoppingItem,
    createShoppingItem,
    setEditShoppingItem,
    clearShoppingItemModal,
    isEditing,
    editShoppingListShoppingItem,
    editRecipeShoppingItem,
    removeShoppingListShoppingItem,
    removeRecipeShoppingItem,
    updatedShoppingListItemRecipeQuantity,
    checkBoxShoppingItem,
    week,
    mealPlanRecipes,
    handleChange,
  } = useAppContext();

  const history = useHistory();

  const [open, setOpen] = useState(false);

  const { useEditRecipeAfterEveryClick } =
    useEditRecipeAfterEveryClickRecipeInput();
  const { handleShoppingItemInput } = useHandleShoppingItemInput();

  const ShoppingItemsHeadingStyled = styled.div`
    display: flex;
    justify-content: "space-between";
    align-items: center;

    p {
      margin-left: auto;
    }
  `;

  // Future to do. Make modal have auto height. Currently, for some reason this breaks makes the modal open many times back to back.
  // const IonModalStyled = styled(IonModal)`
  //   height: auto;
  // `;

  // useIonViewDidEnter(() => {
  //   console.log("useIonViewDidEnter");
  //   getShopping();
  // });

  const updateWeek = (e) => {
    handleChange({ name: "week", value: e.target.value });
  };

  useEffect(() => {
    console.log("ShoppingList Page useEffect");

    getShopping();
    // eslint-disable-next-line
  }, [week, mealPlanRecipes]);

  const [present] = useIonToast();

  const presentToast = () => {
    present({
      message: alertText,
      duration: 1500,
      position: "top",
      color: alertColor,
    });
  };

  if (showAlert) {
    presentToast();
  }

  const setEditShoppingItemAndSetOpen = (id) => {
    // setOpen(true);
    setEditShoppingItem(id);
    history.push("/my/shopping-list/shopping-item/edit");
  };

  const clearShoppingItemAndOpenModal = () => {
    clearShoppingItemModal();
    history.push("/my/shopping-list/shopping-item/add");
    // setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const removeShoppingItemAndCloseModal = () => {
    if (shoppingItem.recipeId) {
      removeRecipeShoppingItem();
    } else if (shoppingItem.shoppingListId) {
      removeShoppingListShoppingItem();
    }

    setOpen(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (typeof shoppingItem._id === "undefined") {
      createShoppingItem();
      clearShoppingItemModal();
      closeModal();
      return;
    } else if (shoppingItem.recipeId) {
      editRecipeShoppingItem();
      closeModal();
      return;
    } else if (typeof shoppingItem.recipeId === "undefined") {
      editShoppingListShoppingItem();
      closeModal();
      return;
    }
  };

  // Filter checked shoppingItems depending on Filter Settings
  let updatedShoppingList = [];

  if (shoppingListHideCheckedShoppingItem === true) {
    // filter out all of the unchecked shoppingItems

    ///////////////////////////////////////////////////////////////////
    // TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST
    ///////////////////////////////////////////////////////////////////
    console.log("shoppingList test:");
    console.log(shoppingList);

    console.log("typeof shoppingList");
    console.log(typeof shoppingList);

    console.log("shoppingList.length");
    console.log(shoppingList.length);

    ///////////////////////////////////////////////////////////////////
    // TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST
    ///////////////////////////////////////////////////////////////////
    updatedShoppingList = shoppingList.filter(
      (shoppingItem) => shoppingItem.procureShoppingItem === false
    );
    console.log("filtered");
  } else {
    // Display all checked and unchcked shoppingItems
    updatedShoppingList = shoppingList;
  }

  // Group shoppingItems depending on Filter Settings
  let groupedShopping = [];
  let uniqueGroupCategories = [];

  updatedShoppingList.forEach((shoppingItem) => {
    // if it's a manual shoppingItem, automatically assign it the aisle to "No aisle"
    const shoppingItemAisle =
      typeof shoppingItem.item === "undefined"
        ? "No aisle"
        : shoppingItem.item.aisle;

    // if it's a manual shoppingItem, automatically assign it the recipe to "No Recipe"
    const shoppingItemRecipeName =
      typeof shoppingItem.recipeName === "undefined"
        ? "No Recipe"
        : shoppingItem.recipeName;

    // set the groupCategoryName
    const groupCategoryName =
      shoppingListGroupCategory === "aisle"
        ? shoppingItemAisle
        : shoppingItemRecipeName;

    // create the group if it doesn't exist
    if (!uniqueGroupCategories.includes(groupCategoryName)) {
      uniqueGroupCategories.push(groupCategoryName);

      const key = groupCategoryName;
      const groupObj = {};

      groupObj[key] = [];
      groupedShopping.push(groupObj);
    }

    // Add the shoppingItem to the right group
    groupedShopping.forEach((group, index) => {
      const groupObjKey = Object.keys(group)[0];
      if (groupCategoryName === groupObjKey) {
        groupedShopping[index][groupCategoryName].push(shoppingItem);
      }
    });
  });

  const shoppingOrItemName = (recipeShoppingItem) => {
    if (
      typeof recipeShoppingItem.name === "undefined" ||
      recipeShoppingItem.name === ""
    ) {
      if (typeof recipeShoppingItem.item !== "undefined") {
        return recipeShoppingItem.item.name;
      }
      // shoppingOrItemName = recipeShoppingItem.item.name;
    } else {
      return recipeShoppingItem.name;
    }
  };

  const shoppingOrItemAisle = (recipeShoppingItem) => {
    if (typeof recipeShoppingItem.aisle === "undefined") {
      if (typeof recipeShoppingItem.item !== "undefined") {
        return recipeShoppingItem.item.aisle;
      }
      // shoppingOrItemName = recipeShoppingItem.item.name;
    } else {
      return recipeShoppingItem.aisle;
    }
  };

  const copyToClipboard = (textToCopy) => {
    // Copy the text inside the text field
    navigator.clipboard.writeText(textToCopy);
  };

  // filter modal
  const [open2, setOpen2] = useState(false);

  const closeModal2 = () => {
    setOpen2(false);
  };
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonSegment value={week}>
            <IonSegmentButton
              value={tabWeeks()[0].week}
              onClick={updateWeek}
              disabled={isLoading ? true : false}
            >
              {tabWeeks()[0].date}
            </IonSegmentButton>
            <IonSegmentButton
              value={tabWeeks()[1].week}
              onClick={updateWeek}
              disabled={isLoading ? true : false}
            >
              {tabWeeks()[1].date}
            </IonSegmentButton>
            <IonSegmentButton
              value={tabWeeks()[2].week}
              onClick={updateWeek}
              disabled={isLoading ? true : false}
            >
              {tabWeeks()[2].date}
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <WebWidthListContainer>
          <div
            className="ion-padding"
            style={{
              paddingBottom: "0",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h1>Shopping List</h1>

            <div style={{ display: "flex", alignItems: "center" }}>
              <IonButton fill="clear" onClick={() => setOpen2(true)}>
                <IonIcon
                  color="primary"
                  icon={filter}
                  size="large"
                  style={{ height: "26px" }}
                ></IonIcon>
              </IonButton>
            </div>
          </div>
          {/* This code is to now show the loading screen while the page is making the initial fetch */}
          {updatedShoppingList.length === 0 && isLoading === false ? (
            <div>
              <IonImg
                style={{ height: "110px", margin: "auto", marginTop: "100px" }}
                src="https://my-recipa-app-images.s3.us-west-1.amazonaws.com/app-assets/white-bag.jpg"
                alt="shopping bag"
              ></IonImg>
              <h3 style={{ textAlign: "center", color: "#808080" }}>
                Empty Shopping List
              </h3>
              <div
                style={{
                  display: "block",
                  textAlign: "center",
                  color: "#808080",
                  marginTop: "0",
                  // width: "100%",
                  width: "80%",
                  margin: "auto",
                }}
              >
                <h5>
                  1) Add a recipe on the Meals page to automatically add the
                  recipe ingredients to your shopping list.
                </h5>
                <h5>2) Use the "+" button to add to your shopping list.</h5>
              </div>
            </div>
          ) : (
            <></>
          )}

          {groupedShopping.map((group, groupIndex, groupedShopping) => {
            const groupName = Object.keys(group)[0];
            const groupShoppingItems = group[groupName];

            return (
              <>
                <ShoppingItemsHeadingStyled
                  key="viewRecipe"
                  className="ion-padding"
                  style={{ paddingTop: "0", paddingBottom: "0" }}
                >
                  <h2 style={{ fontWeight: "700" }}>{groupName}</h2>
                </ShoppingItemsHeadingStyled>
                <TwoColumnIonList lines="full">
                  {groupShoppingItems.map((recipeShoppingItem, index) => {
                    // let shoppingOrItemName = "";

                    // if (
                    //   typeof recipeShoppingItem.name === "undefined" ||
                    //   recipeShoppingItem.name === ""
                    // ) {
                    //   if (typeof recipeShoppingItem.item !== "undefined") {
                    //     shoppingOrItemName = recipeShoppingItem.item.name;
                    //   }
                    //   // shoppingOrItemName = recipeShoppingItem.item.name;
                    // } else {
                    //   shoppingOrItemName = recipeShoppingItem.name;
                    // }

                    const hidden = recipeShoppingItem.hidden;

                    const editable =
                      typeof recipeShoppingItem.item === "undefined"
                        ? true
                        : false;

                    // TEMP DON"T MOVE TO VIEW RECIPE
                    // personalizedYield doesn't apply to shoppingItems not in a recipe so need to set to 1 if it doesn't exist
                    const adaptedPersonalizedYield =
                      typeof recipeShoppingItem.personalizedYield ===
                      "undefined"
                        ? 1
                        : recipeShoppingItem.personalizedYield;

                    if (recipeShoppingItem.unit === "heading") {
                      return null;
                    } else {
                      return (
                        <>
                          <IonItem
                            button
                            detail={false}
                            key={`checkbox${index}`}
                          >
                            <IonCheckbox
                              style={{
                                marginLeft: "0px",
                                marginRight: "10px",
                              }}
                              slot="start"
                              name="procureShoppingItem"
                              index={index}
                              _id={recipeShoppingItem._id}
                              disabled={isLoading ? true : false}
                              // disabled={hidden || isLoading ? true : false} // final code
                              checked={
                                hidden
                                  ? false
                                  : recipeShoppingItem.procureShoppingItem
                              }
                              onClick={checkBoxShoppingItem}
                            ></IonCheckbox>
                            <IonImg
                              src={
                                typeof recipeShoppingItem.item === "undefined"
                                  ? "https://my-recipa-app-images.s3.us-west-1.amazonaws.com/app-assets/white-bag.jpg"
                                  : recipeShoppingItem.item.image
                              }
                              style={{
                                height: "100%",
                                width: "40px",
                                paddingLeft: "10px",
                                paddingRight: "10px",
                              }}
                              onClick={() =>
                                setEditShoppingItemAndSetOpen(
                                  recipeShoppingItem._id
                                )
                              }
                            ></IonImg>
                            {/* Refactor: Without this IonLabel display none, whenever the ShoppingItem has a long name, it will makes it so it's not possible to click the first few letters of the name. Use the inspector toolt to notice it. */}
                            <IonLabel style={{ display: "none" }}></IonLabel>
                            <IonLabel
                              class="ion-text-wrap"
                              onClick={() =>
                                setEditShoppingItemAndSetOpen(
                                  recipeShoppingItem._id
                                )
                              }
                              style={{
                                display: "flex",
                                height: "100%",
                                margin: "0",
                                alignItems: "center",
                              }}
                            >
                              <IonText>
                                {shoppingOrItemName(recipeShoppingItem)}
                                {recipeShoppingItem.note === "" ||
                                typeof recipeShoppingItem.note ===
                                  "undefined" ? (
                                  <></>
                                ) : (
                                  <IonText
                                    color="medium"
                                    style={{
                                      marginLeft: "5px",
                                    }}
                                  >
                                    {`(${recipeShoppingItem.note})`}
                                  </IonText>
                                )}
                              </IonText>
                            </IonLabel>
                            <IonLabel
                              onClick={() =>
                                setEditShoppingItemAndSetOpen(
                                  recipeShoppingItem._id
                                )
                              }
                              style={
                                hidden
                                  ? {
                                      textDecoration: "line-through",
                                      margin: "0",
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                    }
                                  : {
                                      margin: "0",
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "end",
                                    }
                              }
                              className="smallColumn"
                            >
                              {typeof recipeShoppingItem.recipeId ===
                              "undefined"
                                ? recipeShoppingItem.quantity
                                : calculatedQuantity(
                                    adaptedPersonalizedYield,
                                    recipeShoppingItem.recipeYield,
                                    recipeShoppingItem.quantity
                                  )}{" "}
                              {makePlural(
                                recipeShoppingItem.unit,
                                recipeShoppingItem.quantity,
                                adaptedPersonalizedYield
                              )}
                            </IonLabel>
                            <IonButton
                              fill="clear"
                              onClick={() =>
                                copyToClipboard(
                                  shoppingOrItemName(recipeShoppingItem)
                                )
                              }
                            >
                              <IonIcon
                                color="medium"
                                icon={copy}
                                size="large"
                                style={{
                                  height: "20px",
                                }}
                              ></IonIcon>
                            </IonButton>
                          </IonItem>
                        </>
                      );
                    }
                  })}
                </TwoColumnIonList>
              </>
            );
          })}
          {/* start This code is If ever want to bring back the sheet modal  */}
          {/* <IonButton
            id="open-modal"
            expand="block"
            onClick={() => setOpen(true)}
          >
            Open Sheet Modal
          </IonButton> */}
          {/* End This code is If ever want to bring back the sheet modal  */}
          {/* **************************** Start - This code is If ever want to bring back the sheet modal **************************** */}
          <IonModal
            isOpen={open}
            // style={{ height: "auto" }}
            onDidDismiss={() => setOpen(false)}
            breakpoints={[0, 1]}
            initialBreakpoint={1}
          >
            <IonHeader>
              <IonToolbar>
                {/* <IonTitle>Add</IonTitle> */}
                <IonTitle>
                  {typeof shoppingItem._id === "undefined" ? "Add" : "Edit"}
                </IonTitle>

                <IonButtons slot="end">
                  {/* <IonButton onClick={() => closeModal()}>Close</IonButton> */}
                  <IonIcon
                    onClick={() => closeModal()}
                    icon={close}
                    size="large"
                    style={{ height: "26px" }}
                  ></IonIcon>
                </IonButtons>
              </IonToolbar>
            </IonHeader>

            {/* <IonList> */}
            <IonGrid style={{ width: "100%" }}>
              <IonRow>
                <IonCol size="12">
                  {shoppingItem.recipeId ? (
                    <FormRow
                      labelText="Recipe"
                      type="text"
                      name="recipe"
                      placeholder="For 4 Honeycrisp Apple Salad"
                      disabled={true}
                      value={`For ${shoppingItem.personalizedYield} servings of ${shoppingItem.recipeName}`}
                    />
                  ) : (
                    <></>
                  )}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="6">
                  {typeof shoppingItem.recipeId === "undefined" ? (
                    // if it's undefined, it means it a ShoppingItem not assigned to a recipe
                    <FormRow
                      labelText="Quantity (Required*)"
                      type="number"
                      name="quantity"
                      value={shoppingItem.quantity}
                      onIonChange={handleShoppingItemInput}
                    />
                  ) : (
                    // if it's not undefined, it means it a Recipe ShoppingItem
                    <>
                      <FormRow
                        labelText="Quantity (Required*)"
                        type="number"
                        name="quantity"
                        value={
                          shoppingItem.quantity *
                            shoppingItem.personalizedYield ===
                          0
                            ? ""
                            : calculatedQuantity(
                                shoppingItem.personalizedYield,
                                shoppingItem.recipeYield,
                                shoppingItem.quantity
                              )
                        }
                        onIonChange={updatedShoppingListItemRecipeQuantity}
                      />
                    </>
                  )}
                </IonCol>

                <IonCol size="6">
                  <IonItem>
                    <IonLabel position="stacked">Unit</IonLabel>

                    <IonSelectStyled
                      interface="action-sheet"
                      name="unit"
                      label="Stacked label"
                      labelPlacement="stacked"
                      value={shoppingItem.unit}
                      onIonChange={handleShoppingItemInput}
                    >
                      {shoppingItemUnitCategories.map((itemValue, i) => {
                        // if (itemValue === "heading") {
                        //   return null;
                        // } else {
                        return (
                          <IonSelectOption
                            e={"yo"}
                            key={"i"}
                            value={itemValue}
                            disabled={false}
                          >
                            {itemValue}
                          </IonSelectOption>
                        );
                        // }
                      })}
                    </IonSelectStyled>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12">
                  <FormRow
                    labelText="Name (Required*)"
                    type="text"
                    name="name"
                    // placeholder="apple"
                    disabled={
                      typeof shoppingItem.item === "undefined" ? false : true
                    }
                    value={shoppingOrItemName(shoppingItem)}
                    onIonChange={handleShoppingItemInput}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12">
                  <IonItem>
                    <IonLabel position="stacked">Aisle</IonLabel>
                    <IonSelect
                      interface="action-sheet"
                      name="aisle"
                      label="Stacked label"
                      labelPlacement="stacked"
                      // placeholder="produce (optional)"
                      disabled={
                        typeof shoppingItem.item === "undefined" ? false : true
                      }
                      onIonChange={handleShoppingItemInput}
                      value={shoppingOrItemAisle(shoppingItem)}
                    >
                      {aisleCategories.map((itemValue, i) => {
                        return (
                          <IonSelectOption
                            e={"yo"}
                            key={"i"}
                            value={itemValue}
                            disabled={false}
                          >
                            {itemValue}
                          </IonSelectOption>
                        );
                      })}
                    </IonSelect>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12">
                  <FormRow
                    labelText="Note"
                    type="text"
                    name="note"
                    // placeholder="sliced (optional)"
                    value={shoppingItem.note}
                    onIonChange={handleShoppingItemInput}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12">
                  {isEditing && (
                    <IonButton
                      color="light"
                      expand="block"
                      onClick={() => removeShoppingItemAndCloseModal()}
                      disabled={isLoading}
                    >
                      Remove
                    </IonButton>
                  )}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12">
                  {/* {showAlert && <ChipAlert />} */}
                  <IonButton
                    expand="block"
                    onClick={onSubmit}
                    disabled={isLoading}
                  >
                    Save
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonModal>
          {/* **************************** End - This code is I ever want to bring back the sheet modal **************************** */}

          {/* </IonList> */}
          <IonModal
            isOpen={open2}
            onDidDismiss={closeModal2}
            breakpoints={[0, 0.2, 0.6, 1]}
            initialBreakpoint={0.6}
            backdropBreakpoint={0.2}
          >
            {/* <IonContent dismiss={closeModal2}></IonContent> */}

            <IonToolbar>
              <IonTitle>Filters</IonTitle>
              <IonButtons slot="end">
                <IonButton color="primary" onClick={() => closeModal2()}>
                  Close
                </IonButton>
              </IonButtons>
            </IonToolbar>
            {/* <div className="ion-padding"> */}
            <h5 className="ion-padding">Group Shopping Items</h5>
            {/* </div> */}
            <IonRadioGroup
              value={shoppingListGroupCategory}
              onIonChange={(e) => updateShoppingListGroupCategory(e)}
            >
              <IonItem>
                <IonLabel>Recipe</IonLabel>
                <IonRadio value="recipe"></IonRadio>
              </IonItem>
              <IonItem>
                <IonLabel>Aisle</IonLabel>
                <IonRadio value="aisle"></IonRadio>
              </IonItem>
            </IonRadioGroup>

            {/* <div className="ion-padding"> */}
            <h5 className="ion-padding">Hide</h5>
            {/* </div> */}
            <IonItem>
              <IonLabel>Hide checked shopping items</IonLabel>
              <IonToggle
                justify="end"
                checked={shoppingListHideCheckedShoppingItem}
                onClick={() => updateShoppingListHideCheckedShoppingItem()}
              ></IonToggle>
            </IonItem>
          </IonModal>
        </WebWidthListContainer>
        <div style={{ marginBottom: "70px" }}></div>

        <IonFabStyled slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton onClick={() => clearShoppingItemAndOpenModal()}>
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
        </IonFabStyled>
      </IonContent>
    </IonPage>
  );
};

export default ShoppingList;
