import { useAppContext } from "../context/appContext";
import { IonChip } from "@ionic/react";

const ChipAlert = () => {
  const { alertColor, alertText } = useAppContext();

  return (
    <IonChip className="width" color={alertColor}>
      {alertText}
    </IonChip>
  );
};
export default ChipAlert;
