import {
  DISPLAY_ALERT,
  CLEAR_ALERT,
  BEGIN_REDIRECT,
  END_REDIRECT,
  GENERIC_ERROR,
  REGISTER_USER_BEGIN,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGIN_USER_BEGIN,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  HANDLE_CHANGE_MEMBER_INFO,
  HANDLE_CHANGE,
  HANDLE_CHANGE_LEVEL_TWO,
  CLEAR_VALUES,
  CREATE_RECIPE_BEGIN,
  CREATE_RECIPE_SUCCESS,
  CREATE_RECIPE_ERROR,
  CLEAR_RECIPE_VALUES,
  REMOVE_SHOPPING_ITEM_BEGIN_RECIPE_PAGE,
  HANDLE_CHANGE_SHOPPING_ITEM_RECIPE_PAGE,
  ADD_INSTRUCTION_STEP,
  REMOVE_INSTRUCTION_STEP,
  HANDLE_CHANGE_INSTRUCTION_CHANGE_ADD_RECIPE,
  ADD_UTENSIL,
  REMOVE_UTENSIL,
  HANDLE_CHANGE_UTENSIL_CHANGE_ADD_RECIPE,
  SET_EDIT_RECIPE,
  DELETE_RECIPE_BEGIN,
  DELETE_RECIPE_SUCCESS,
  EDIT_RECIPE_BEGIN,
  EDIT_RECIPE_SUCCESS,
  EDIT_RECIPE_ERROR,
  // MENU
  GET_MENU_BEGIN,
  GET_MENU_SUCCESS,
  GET_RECIPE_BEGIN,
  GET_RECIPE_SUCCESS,
  // Search Page
  GET_SEARCH_PAGE_RECIPES_BEGIN,
  GET_SEARCH_PAGE_RECIPES_SUCCESS,
  SEARCH_CLEAR_FILTERS,
  // Collection Page
  COLLECTION_CLEAR_FILTERS,
  GET_COLLECTION_PAGE_RECIPES_BEGIN,
  GET_COLLECTION_PAGE_RECIPES_SUCCESS,
  CREATE_RECIPE_IN_COLLECTION_PAGE_BEGIN,
  CREATE_RECIPE_IN_COLLECTION_PAGE_SUCCESS,
  CREATE_RECIPE_IN_COLLECTION_PAGE_ERROR,
  EDIT_RECIPE_COLLECTION_PAGE_BEGIN,
  EDIT_RECIPE_COLLECTION_PAGE_SUCCESS,
  EDIT_RECIPE_COLLECTION_PAGE_ERROR,
  // Edit Serving Page
  SET_EDIT_SERVING_ADD_RECIPE,
  SET_EDIT_SERVING_UPDATE_RECIPE,
  HANDLE_CHANGE_EDIT_SERVINGS_PAGE_MEAL,
  HANDLE_CHANGE_EDIT_SERVINGS_PAGE_SERVING_ALLOCATION,
  // Meal Plan
  GET_MEAL_PLAN_BEGIN,
  GET_MEAL_PLAN_SUCCESS,
  CREATE_MEAL_PLAN_RECIPE_BEGIN,
  CREATE_MEAL_PLAN_RECIPE_SUCCESS,
  CREATE_MEAL_PLAN_RECIPE_ERROR,
  REMOVE_MEAL_PLAN_RECIPE_BEGIN,
  REMOVE_MEAL_PLAN_RECIPE_SUCCESS,
  REMOVE_MEAL_PLAN_RECIPE_ERROR,
  UPDATE_MEAL_PLAN_RECIPE_BEGIN,
  UPDATE_MEAL_PLAN_RECIPE_SUCCESS,
  UPDATE_MEAL_PLAN_RECIPE_ERROR,
  UPDATE_MACROS_OR_SUMMARY_VIEW,
  CREATE_RECIPE_IN_MEAL_PLAN_AND_SET_SERVING_EDIT_PAGE_BEGIN,
  CREATE_RECIPE_IN_MEAL_PLAN_AND_SET_SERVING_EDIT_PAGE_SUCCESS,
  CREATE_RECIPE_IN_MEAL_PLAN_AND_SET_SERVING_EDIT_PAGE_ERROR,
  EDIT_RECIPE_MEAL_PLAN_PAGE_BEGIN,
  EDIT_RECIPE_MEAL_PLAN_PAGE_SUCCESS,
  EDIT_RECIPE_MEAL_PLAN_PAGE_ERROR,
  // Shopping List
  GET_SHOPPING_BEGIN,
  GET_SHOPPING_SUCCESS,
  UPDATE_SHOPPING_LIST_GROUP_CATEGORY,
  UPDATE_SHOPPING_LIST_HIDE_CHECKED,
  CREATE_SHOPPING_ITEM_BEGIN,
  CREATE_SHOPPING_ITEM_SUCCESS,
  CREATE_SHOPPING_ITEM_ERROR,
  CLEAR_SHOPPING_ITEM,
  SET_EDIT_SHOPPING_ITEM,
  CLEAR_SHOPPING_ITEM_VALUES,
  EDIT_SHOPPING_LIST_SHOPPING_ITEM_BEGIN,
  EDIT_SHOPPING_LIST_SHOPPING_ITEM_SUCCESS,
  EDIT_SHOPPING_LIST_SHOPPING_ITEM_ERROR,
  EDIT_RECIPE_SHOPPING_ITEM_BEGIN,
  EDIT_RECIPE_SHOPPING_ITEM_SUCCESS,
  EDIT_RECIPE_SHOPPING_ITEM_ERROR,
  REMOVE_SHOPPING_LIST_SHOPPING_ITEM_BEGIN,
  REMOVE_SHOPPING_LIST_SHOPPING_ITEM_SUCCESS,
  REMOVE_SHOPPING_LIST_SHOPPING_ITEM_ERROR,
  REMOVE_RECIPE_SHOPPING_ITEM_BEGIN,
  REMOVE_RECIPE_SHOPPING_ITEM_SUCCESS,
  REMOVE_RECIPE_SHOPPING_ITEM_ERROR,
  CHECK_BOX_SHOPPING_ITEM_BEGIN,
  CHECK_BOX_SHOPPING_ITEM_SUCCESS,
  CHECK_BOX_SHOPPING_ITEM_ERROR,
  SET_EDIT_SHOPPING_ITEM_VIEW_RECIPE_PAGE,
  EDIT_RECIPE_SHOPPING_ITEM_BEGIN_VIEW_RECIPE_PAGE,
  EDIT_RECIPE_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE,
  EDIT_RECIPE_SHOPPING_ITEM_ERROR_VIEW_RECIPE_PAGE,
  REMOVE_RECIPE_SHOPPING_ITEM_BEGIN_VIEW_RECIPE_PAGE,
  REMOVE_RECIPE_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE,
  REMOVE_RECIPE_SHOPPING_ITEM_ERROR_VIEW_RECIPE_PAGE,
  UPDATE_SHOPPING_ITEM_RECIPE_QUANTITY_SUCCESS,
  UPDATE_SHOPPING_ITEM_RECIPE_QUANTITY_ERROR,
  CHECK_BOX_SHOPPING_ITEM_BEGIN_VIEW_RECIPE_PAGE,
  CHECK_BOX_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE,
  CHECK_BOX_SHOPPING_ITEM_ERROR_VIEW_RECIPE_PAGE,
  ADD_RECIPE_SHOPPING_ITEM_BEGIN_VIEW_RECIPE_PAGE,
  ADD_RECIPE_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE,
  ADD_RECIPE_SHOPPING_ITEM_ERROR_VIEW_RECIPE_PAGE,
} from "./actions";

import { initialState } from "./appContext";

const reducer = (state, action) => {
  // alertText: "Please fill out every field.",

  if (action.type === DISPLAY_ALERT) {
    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: !action.payload.msg
        ? "Please fill out every field."
        : action.payload.msg,
    };
  }

  if (action.type === CLEAR_ALERT) {
    return {
      ...state,
      showAlert: false,
      alertColor: "",
      alertText: "",
    };
  }

  if (action.type === BEGIN_REDIRECT) {
    return {
      ...state,
      redirectFrom: action.payload.redirectFrom,
      redirectTo: action.payload.redirectTo,
    };
  }

  if (action.type === END_REDIRECT) {
    return {
      ...state,
      redirectFrom: "",
      redirectTo: "",
    };
  }

  if (action.type === REGISTER_USER_BEGIN) {
    return {
      ...state,
      showAlert: false,
      alertColor: "",
      alertText: "",
      isLoading: true,
    };
  }

  if (action.type === REGISTER_USER_SUCCESS) {
    return {
      ...state,
      showAlert: true,
      alertColor: "success",
      alertText: "Successfully created account",
      isLoading: false,
      token: action.payload.token,
      user: action.payload.user,
      userAddress: action.payload.address,
      jobAddress: action.payload.address,
    };
  }

  if (action.type === REGISTER_USER_ERROR) {
    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: action.payload.msg,
      isLoading: false,
    };
  }

  if (action.type === LOGIN_USER_BEGIN) {
    return {
      ...state,
      showAlert: false,
      alertColor: "",
      alertText: "",
      isLoading: true,
    };
  }

  if (action.type === LOGIN_USER_SUCCESS) {
    return {
      ...state,
      showAlert: true,
      alertColor: "success",
      alertText: "Successfully logged in",
      isLoading: false,
      token: action.payload.token,
      user: action.payload.user,
      userAddress: action.payload.address,
      jobAddress: action.payload.address,
    };
  }

  if (action.type === LOGIN_USER_ERROR) {
    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: action.payload.msg,
      isLoading: false,
    };
  }

  if (action.type === LOGOUT_USER) {
    return {
      ...initialState,
      user: null,
      token: null,
      userAddress: "",
      jobAddress: "",
    };
  }
  if (action.type === UPDATE_USER_BEGIN) {
    return {
      ...state,
      showAlert: false,
      alertColor: "",
      alertText: "",
      isLoading: true,
    };
  }

  if (action.type === UPDATE_USER_SUCCESS) {
    return {
      ...state,
      showAlert: true,
      alertColor: "success",
      alertText: "Successfully updated account",
      isLoading: false,
      token: action.payload.token,
      user: action.payload.user,
      userAddress: action.payload.address,
      jobAddress: action.payload.address,
    };
  }

  if (action.type === UPDATE_USER_ERROR) {
    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: action.payload.msg,
      isLoading: false,
    };
  }

  // watch for syntax https://www.youtube.com/watch?v=_qxCYtWm0tw&t=348
  if (action.type === HANDLE_CHANGE) {
    return {
      ...state,
      [action.payload.name]: action.payload.value,
    };
  }

  if (action.type === HANDLE_CHANGE_LEVEL_TWO) {
    // console.log("action.payload.levelOne:");
    // console.log(action.payload.levelOne);
    // console.log("action.payload.name:");
    // console.log(action.payload.value);
    // console.log("action.payload.value:");
    // console.log(action.payload.value);

    // const levelOneKey = "mealPlanServingSelection";

    // // const levelOneValue = action.payload.levelOne;
    // const levelOneValue = state.levelOneKey;

    // // const levelOne = action.payload.mealPlanServingSelection;

    // console.log("levelOneValue:");
    // console.log(levelOneValue);
    // console.log("[mealPlanServingSelection]:");
    // console.log([mealPlanServingSelection]);

    // console.log('typeof("state")');
    // console.log(typeof state);
    // console.log('typeof("action.payload.levelOne")');
    // console.log(typeof action.payload.levelOne);

    // const mealPlanServingSelection = state.mealPlanServingSelection;

    const levelOneKey = action.payload.levelOneKey;
    // console.log("levelOneKey:");
    // console.log(levelOneKey);

    // const levelOneValue = action.payload.levelOneValue; //////////// This already works

    // const levelOneValue = state.[levelOneKey];
    // console.log("levelOneValue:");
    // console.log(levelOneValue);

    let levelOneValue = null;

    // console.log(state);

    for (const [key, value] of Object.entries(state)) {
      // console.log(state[i]);
      // console.log(`${key}: ${value}`);
      if (key == levelOneKey) {
        // console.log(`${key}: ${value}`);

        levelOneValue = value;
      }
    }

    // levelOneValue = action.payload.levelOneValue; //////////// This already works
    return {
      ...state,
      [levelOneKey]: {
        ...levelOneValue,
        [action.payload.name]: action.payload.value,
      },
    };
  }

  if (action.type === CLEAR_VALUES) {
    const initialState = {
      isEditing: false,
      // editRecipeId: "",
      // "recipe: null" is my test to format the initial state like "user: user"
      recipe: null,
      recipeName: "",
      recipeRating: "",
      recipeReviewCount: "",
      recipeSource: "",
      recipeSourceUrl: "",
      recipeFeaturedMedia: "",
      recipeInstagramUrl: "",
      recipeFeaturedCategory: "",
      recipeTotalTime: null,
      recipeCookTime: null,
      recipeActiveTime: null,
      recipePrepTime: null,
      recipeCoolTime: null,
      recipeFreezeTime: null,
      recipeMarinateTime: null,
      recipeRestTime: null,
      recipeSoakTime: null,
      recipeDescription: null,
      recipeCuisine: null,
      recipeMealType: null,
      recipeCourseType: null,
      recipeMeatType: "",
      personalizedYield: "",
      recipeYield: "",
      // recipeShoppingItems: [
      //   {
      //     quantity: "",
      //     unit: "",
      //     name: "",
      //   },
      //   {
      //     quantity: "",
      //     unit: "",
      //     name: "",
      //   },
      // ],
      recipeShoppingItems: [],

      recipeCalories: null,
      recipeCarbohydrates: null,
      recipeFat: null,
      recipeProtein: null,
      recipeSodium: null,
      recipeUtensils: [],
      recipeVideo: "",
      recipeNotes: "",
      recipeInstructions: [
        {
          media: "",
          description: "",
        },
        {
          media: "",
          description: "",
        },
      ],
      recipeCreatedBy: "",
      week: "",
      recipeEditable: false,
      recipeChosenInWeeklyMenu: false,
      shoppingList: [],
      shoppingListGroupCategory: "recipe",
      shoppingListHideCheckedShoppingItem: true,
      shoppingItem: {
        quantity: "",
        unit: "",
        name: "",
        aisle: "",
        note: "",
      },
    };

    return {
      ...state,
      ...initialState,
    };
  }

  if (action.type === CREATE_RECIPE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === CREATE_RECIPE_SUCCESS) {
    return {
      ...state,
      showAlert: true,
      alertColor: "success",
      alertText: "New recipe created",
      isLoading: false,
    };
  }

  if (action.type === CREATE_RECIPE_ERROR) {
    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: action.payload.msg,
      isLoading: false,
    };
  }

  if (action.type === CLEAR_RECIPE_VALUES) {
    console.log("CLEAR_RECIPE_VALUES");
    return {
      ...state,
      isEditing: false,
      // I commented editRecipeId out since I'm using useEffect to update the revipeViewPage, it causes me to make an API call when I clear this value
      editRecipeId: "",
      recipe: null,
      recipeName: "",
      recipeRating: "",
      recipeReviewCount: "",
      recipeSource: "",
      recipeSourceUrl: "",
      recipeFeaturedMedia: "",
      recipeInstagramUrl: "",
      recipeFeaturedCategory: "",
      recipeTotalTime: null,
      recipeCookTime: null,
      recipeActiveTime: null,
      recipePrepTime: null,
      recipeCoolTime: null,
      recipeFreezeTime: null,
      recipeMarinateTime: null,
      recipeRestTime: null,
      recipeSoakTime: null,
      recipeDescription: "",
      recipeCuisine: "",
      recipeMealType: "",
      recipeCourseType: "",
      recipeMeatType: "",
      personalizedYield: "",
      recipeYield: "",
      // recipeShoppingItems: [
      //   {
      //     quantity: "",
      //     unit: "",
      //     name: "",
      //   },
      //   {
      //     quantity: "",
      //     unit: "",
      //     name: "",
      //   },
      // ],
      recipeShoppingItems: [],

      recipeCalories: null,
      recipeCarbohydrates: null,
      recipeFat: null,
      recipeProtein: null,
      recipeSodium: null,
      recipeUtensils: [],
      recipeVideo: "",
      recipeNotes: "",
      recipeInstructions: [],
      recipeCreatedBy: "",
      recipeChosenInWeeklyMenu: false,
    };
  }

  if (action.type === REMOVE_SHOPPING_ITEM_BEGIN_RECIPE_PAGE) {
    const testRecipeShoppingItems = [
      {
        quantity: "1",
        unit: "tsp",
        name: "1",
      },
      {
        quantity: "2",
        unit: "lb",
        name: "2",
      },
    ];

    return {
      ...state,
      // recipeShoppingItems: testRecipeShoppingItems,

      recipeShoppingItems: action.payload.updatedRecipeShoppingItems,
    };
  }

  if (action.type === HANDLE_CHANGE_SHOPPING_ITEM_RECIPE_PAGE) {
    return {
      ...state,
      recipeShoppingItems: action.payload.updatedRecipeShoppingItems,
    };
  }

  if (action.type === ADD_INSTRUCTION_STEP) {
    return {
      ...state,
      recipeInstructions: action.payload.updatedRecipeInstructions,
    };
  }

  if (action.type === REMOVE_INSTRUCTION_STEP) {
    return {
      ...state,
      recipeInstructions: action.payload.updatedRecipeInstructions,
    };
  }

  if (action.type === HANDLE_CHANGE_INSTRUCTION_CHANGE_ADD_RECIPE) {
    return {
      ...state,
      recipeInstructions: action.payload.updatedRecipeInstructions,
    };
  }

  if (action.type === ADD_UTENSIL) {
    return {
      ...state,
      recipeUtensils: action.payload.updatedRecipeUtensils,
    };
  }

  if (action.type === REMOVE_UTENSIL) {
    return {
      ...state,
      recipeUtensils: action.payload.updatedRecipeUtensils,
    };
  }

  if (action.type === HANDLE_CHANGE_UTENSIL_CHANGE_ADD_RECIPE) {
    return {
      ...state,
      recipeUtensils: action.payload.updatedRecipeUtensils,
    };
  }

  if (action.type === HANDLE_CHANGE_MEMBER_INFO) {
    const user = state.user;

    return {
      ...state,
      user: {
        ...user,
        members: action.payload.updatedMembers,
      },
    };
  }

  if (action.type === GET_RECIPE_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }

  if (action.type === GET_RECIPE_SUCCESS) {
    const recipe = action.payload.recipe;
    const {
      _id,
      name,
      rating,
      reviewCount,
      source,
      sourceUrl,
      featuredMedia,
      instagramUrl,
      featuredCategory,
      totalTime,
      cookTime,
      activeTime,
      prepTime,
      coolTime,
      freezeTime,
      marinateTime,
      restTime,
      soakTime,
      description,
      cuisine,
      mealType,
      courseType,
      meatType,
      personalizedYield,
      recipeYield,
      shoppingItems,
      calories,
      carbohydrates,
      fat,
      protein,
      sodium,
      utensils,
      video,
      notes,
      instructions,
      createdBy,
      recipeEditable,
      recipeChosenInWeeklyMenu,
    } = recipe;

    console.log("action.type === GET_RECIPE_SUCCESS name");
    console.log(name);
    console.log("action.type === GET_RECIPE_SUCCESS _id");
    console.log(_id);

    return {
      ...state,
      isEditing: true,
      editRecipeId: _id,
      recipeName: name,
      recipeRating: rating,
      recipeReviewCount: reviewCount,
      recipeSource: source,
      recipeSourceUrl: sourceUrl,
      recipeFeaturedMedia: featuredMedia,
      recipeInstagramUrl: instagramUrl,
      recipeFeaturedCategory: featuredCategory,
      recipeTotalTime: totalTime,
      recipeCookTime: cookTime,
      recipeActiveTime: activeTime,
      recipePrepTime: prepTime,
      recipeCoolTime: coolTime,
      recipeFreezeTime: freezeTime,
      recipeMarinateTime: marinateTime,
      recipeRestTime: restTime,
      recipeSoakTime: soakTime,
      recipeDescription: description,
      recipeCuisine: cuisine,
      recipeMealType: mealType,
      recipeCourseType: courseType,
      recipeMeatType: meatType,
      personalizedYield,
      recipeYield,
      recipeShoppingItems: shoppingItems,
      recipeCalories: calories,
      recipeCarbohydrates: carbohydrates,
      recipeFat: fat,
      recipeProtein: protein,
      recipeSodium: sodium,
      recipeVideo: video,
      recipeNotes: notes,
      recipeUtensils: utensils,
      recipeCreatedBy: createdBy,
      recipeInstructions: instructions,
      recipeEditable: true,
      recipeChosenInWeeklyMenu,
      isLoading: false,
    };

    // return {
    //   ...state,
    //   isLoading: false,
    //   recipes: action.payload.recipes,
    //   searchTotalRecipes: action.payload.searchTotalRecipes,
    //   searchNumOfPages: action.payload.searchNumOfPages,
    //   showAlert: false,
    // };
  }

  // Not used anymore, but keep it here for reference since I really like the logic
  // if (action.type === SET_EDIT_RECIPE) {
  //   const recipe = state.recipes.find(
  //     (recipe) => recipe._id === action.payload.id
  //   );
  //   const {
  //     _id,
  //     name,
  //     rating,
  //     reviewCount,
  //     source,
  //     sourceUrl,
  //     featuredMedia,
  //     instagramUrl,
  //     featuredCategory,
  //     totalTime,
  //     cookTime,
  //     activeTime,
  //     prepTime,
  //     coolTime,
  //     freezeTime,
  //     marinateTime,
  //     restTime,
  //     soakTime,
  //     description,
  //     cuisine,
  //     mealType,
  //     courseType,
  //     meatType,
  //     personalizedYield,
  //     recipeYield,
  //     shoppingItems,
  //     calories,
  //     carbohydrates,
  //     fat,
  //     protein,
  //     sodium,
  //     utensils,
  //     video,
  //     notes,
  //     instructions,
  //     recipeEditable,
  //     recipeChosenInWeeklyMenu,
  //   } = recipe;

  //   return {
  //     ...state,
  //     isEditing: true,
  //     editRecipeId: _id,
  //     recipeName: name,
  //     recipeRating: rating,
  //     recipeReviewCount: reviewCount,
  //     recipeSource: source,
  //     recipeSourceUrl: sourceUrl,
  //     recipeFeaturedMedia: featuredMedia,
  //     recipeInstagramUrl: instagramUrl,
  //     recipeFeaturedCategory: featuredCategory,
  //     recipeTotalTime: totalTime,
  //     recipeCookTime: cookTime,
  //     recipeActiveTime: activeTime,
  //     recipePrepTime: prepTime,
  //     recipeCoolTime: coolTime,
  //     recipeFreezeTime: freezeTime,
  //     recipeMarinateTime: marinateTime,
  //     recipeRestTime: restTime,
  //     recipeSoakTime: soakTime,
  //     recipeDescription: description,
  //     recipeCuisine: cuisine,
  //     recipeMealType: mealType,
  //     recipeCourseType: courseType,
  //     recipeMeatType: meatType,
  //     personalizedYield,
  //     recipeYield,
  //     recipeShoppingItems: shoppingItems,
  //     recipeCalories: calories,
  //     recipeCarbohydrates: carbohydrates,
  //     recipeFat: fat,
  //     recipeProtein: protein,
  //     recipeSodium: sodium,
  //     recipeUtensils: utensils,
  //     recipeVideo: video,
  //     recipeNotes: notes,
  //     recipeInstructions: instructions,
  //     recipeEditable: true,
  //     recipeChosenInWeeklyMenu,
  //   };
  // }

  if (action.type === DELETE_RECIPE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === DELETE_RECIPE_SUCCESS) {
    console.log("DELETE_RECIPE_SUCCESS");

    console.log("action.payload.name");
    console.log(action.payload.name);

    return {
      ...state,
      showAlert: true,
      alertColor: "success",
      alertText: "New recipe created",

      // showAlert: true,
      // alertColor: "success",
      // alertText: `Deleted recipe ${action.payload.name}`,
      isLoading: false,
    };
  }

  if (action.type === EDIT_RECIPE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === EDIT_RECIPE_SUCCESS) {
    const recipe = action.payload.recipe;

    let {
      _id,
      name,
      rating,
      reviewCount,
      source,
      sourceUrl,
      featuredMedia,
      instagramUrl,
      featuredCategory,
      totalTime,
      cookTime,
      prepTime,
      coolTime,
      freezeTime,
      marinateTime,
      restTime,
      soakTime,
      activeTime,
      description,
      cuisine,
      mealType,
      courseType,
      meatType,
      personalizedYield,
      recipeYield,
      shoppingItems,
      calories,
      carbohydrates,
      fat,
      protein,
      sodium,
      video,
      notes,
      instructions,
      recipeEditable,
      recipeChosenInWeeklyMenu,
    } = recipe;

    // let { personalizedYield } = state;

    // // checks I send a new personalizedYield in editRecipeAfterEveryClick
    // if (typeof action.payload !== "undefined") {
    //   personalizedYield = action.payload.recipe.personalizedYield;
    // }

    return {
      ...state,
      isEditing: true,
      editRecipeId: _id,
      recipeName: name,
      recipeRating: rating,
      recipeReviewCount: reviewCount,
      recipeSource: source,
      recipeSourceUrl: sourceUrl,
      recipeFeaturedMedia: featuredMedia,
      recipeInstagramUrl: instagramUrl,
      recipeFeaturedCategory: featuredCategory,
      recipeTotalTime: totalTime,
      recipeCookTime: cookTime,
      recipeActiveTime: activeTime,
      recipePrepTime: prepTime,
      recipeCoolTime: coolTime,
      recipeFreezeTime: freezeTime,
      recipeMarinateTime: marinateTime,
      recipeRestTime: restTime,
      recipeSoakTime: soakTime,
      recipeDescription: description,
      recipeCuisine: cuisine,
      recipeMealType: mealType,
      recipeCourseType: courseType,
      personalizedYield,
      recipeMeatType: meatType,
      recipeYield: recipeYield,
      recipeShoppingItems: shoppingItems,
      recipeCalories: calories,
      recipeCarbohydrates: carbohydrates,
      recipeFat: fat,
      recipeProtein: protein,
      recipeSodium: sodium,
      recipeVideo: video,
      recipeNotes: notes,
      recipeInstructions: instructions,
      recipeEditable: true,
      recipeChosenInWeeklyMenu,
      showAlert: true,
      alertColor: "dark",
      alertText: "Successfully edited recipe",
      isLoading: false,
    };

    // /// Before Code
    // let { personalizedYield } = state;

    // // checks I send a new personalizedYield in editRecipeAfterEveryClick
    // if (typeof action.payload !== "undefined") {
    //   personalizedYield = action.payload.recipe.personalizedYield;
    // }

    // return {
    //   ...state,
    //   showAlert: true,
    //   alertColor: "success",
    //   alertText: "Successfully edited recipe",
    //   isLoading: false,
    //   personalizedYield: personalizedYield,
    // };
  }

  if (action.type === EDIT_RECIPE_ERROR) {
    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: action.payload.msg,
      isLoading: false,
    };
  }

  ////////////////////////////////////// SEARCH PAGE //////////////////////////////////////
  if (action.type === GET_SEARCH_PAGE_RECIPES_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }

  if (action.type === GET_SEARCH_PAGE_RECIPES_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      searchRecipes: action.payload.recipes,
      searchTotalRecipes: action.payload.totalRecipes,
      searchNumOfPages: action.payload.numOfPages,
      showAlert: false,
    };
  }

  if (action.type === SEARCH_CLEAR_FILTERS) {
    return {
      ...state,
      searchRecipeName: "",
      searchRecipeSource: "",
      searchRecipeSourceUrl: "",
      searchRecipeTotalTime: "",
      searchRecipeCookTime: "",
      searchRecipeMarinateTime: "",
      searchRecipeRestTime: "",
      searchRecipeFeaturedCategory: "",
      searchRecipeCourseType: "",
      searchRecipeCuisine: "",
      searchRecipeMealType: "",
      searchRecipeMeatType: "",
      searchRecipeYield: "",
      searchRecipeCalories: "",
      searchRecipeCarbohydrates: "",
      searchRecipeFat: "",
      searchRecipeProtein: "",
      searchRecipeVideo: "",
      searchRecipeSortOption: "Latest",
    };
  }

  ////////////////////////////////////// COLLECTION PAGE //////////////////////////////////////
  if (action.type === GET_COLLECTION_PAGE_RECIPES_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }

  if (action.type === GET_COLLECTION_PAGE_RECIPES_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      collectionRecipes: action.payload.recipes,
      collectionTotalRecipes: action.payload.totalRecipes,
      collectionNumOfPages: action.payload.numOfPages,
      showAlert: false,
    };
  }

  if (action.type === COLLECTION_CLEAR_FILTERS) {
    return {
      ...state,
      collectionRecipeName: "",
      collectionRecipeSource: "",
      collectionRecipeSourceUrl: "",
      collectionRecipeTotalTime: "",
      collectionRecipeCookTime: "",
      collectionRecipeMarinateTime: "",
      collectionRecipeRestTime: "",
      collectionRecipeFeaturedCategory: "",
      collectionRecipeCourseType: "",
      collectionRecipeCuisine: "",
      collectionRecipeMealType: "",
      collectionRecipeMeatType: "",
      collectionRecipeYield: "",
      collectionRecipeCalories: "",
      collectionRecipeCarbohydrates: "",
      collectionRecipeFat: "",
      collectionRecipeProtein: "",
      collectionRecipeVideo: "",
      collectionRecipeSortOption: "Latest",
    };
  }

  if (action.type === CREATE_RECIPE_IN_COLLECTION_PAGE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === CREATE_RECIPE_IN_COLLECTION_PAGE_SUCCESS) {
    let levelOneValue = {};

    console.log("action.payload.newRecipe._id");
    console.log(action.payload.newRecipe._id);

    return {
      ...state,
      showAlert: true,
      alertColor: "success",
      alertText: "New recipe created",
      isLoading: false,
      redirect: true,
      editRecipeId: action.payload.newRecipe._id,
      redirectFrom: "/my/discover/collection/create-recipe-or-food",
      recipeName: "",
      recipeSourceUrl: "",
      recipeFeaturedMedia: "",
      recipeYield: "",
      recipeCalories: "",
      recipeProtein: "",
      recipeNotes: "",
      mealPlanServingSelection: {
        ...levelOneValue,
        week: state.week,
        recipeId: action.payload.newRecipe._id,
        name: action.payload.newRecipe.name,
        meal: "Dinner",
        recipeServing: action.payload.newRecipe.personalizedYield,
        assignment: action.payload.schedule,
        checkboxOrNumberServingInput: "checkbox",
      },
    };
  }

  if (action.type === CREATE_RECIPE_IN_COLLECTION_PAGE_ERROR) {
    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: action.payload.msg,
      isLoading: false,
    };
  }

  if (action.type === EDIT_RECIPE_COLLECTION_PAGE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === EDIT_RECIPE_COLLECTION_PAGE_SUCCESS) {
    // let levelOneValue = {};
    console.log(
      "dispatch EDIT_RECIPE_COLLECTION_PAGE_SUCCESS action.payload.redirectFrom"
    );
    console.log(action.payload.redirectFrom);

    const recipe = action.payload.updatedRecipe;

    let {
      _id,
      name,
      rating,
      reviewCount,
      source,
      sourceUrl,
      featuredMedia,
      instagramUrl,
      featuredCategory,
      totalTime,
      cookTime,
      prepTime,
      coolTime,
      freezeTime,
      marinateTime,
      restTime,
      soakTime,
      activeTime,
      description,
      cuisine,
      mealType,
      courseType,
      meatType,
      personalizedYield,
      recipeYield,
      shoppingItems,
      calories,
      carbohydrates,
      fat,
      protein,
      sodium,
      video,
      notes,
      instructions,
      recipeEditable,
      recipeChosenInWeeklyMenu,
    } = recipe;

    return {
      ...state,
      isEditing: true,
      editRecipeId: _id,
      recipeName: name,
      recipeRating: rating,
      recipeReviewCount: reviewCount,
      recipeSource: source,
      recipeSourceUrl: sourceUrl,
      recipeFeaturedMedia: featuredMedia,
      recipeInstagramUrl: instagramUrl,
      recipeFeaturedCategory: featuredCategory,
      recipeTotalTime: totalTime,
      recipeCookTime: cookTime,
      recipeActiveTime: activeTime,
      recipePrepTime: prepTime,
      recipeCoolTime: coolTime,
      recipeFreezeTime: freezeTime,
      recipeMarinateTime: marinateTime,
      recipeRestTime: restTime,
      recipeSoakTime: soakTime,
      recipeDescription: description,
      recipeCuisine: cuisine,
      recipeMealType: mealType,
      recipeCourseType: courseType,
      personalizedYield,
      recipeMeatType: meatType,
      recipeYield: recipeYield,
      recipeShoppingItems: shoppingItems,
      recipeCalories: calories,
      recipeCarbohydrates: carbohydrates,
      recipeFat: fat,
      recipeProtein: protein,
      recipeSodium: sodium,
      recipeVideo: video,
      recipeNotes: notes,
      recipeInstructions: instructions,
      recipeEditable: true,
      recipeChosenInWeeklyMenu,
      redirect: true,
      redirectFrom: action.payload.redirectFrom,
      showAlert: true,
      alertColor: "success",
      alertText: "Updated recipe",
      isLoading: false,
    };

    // return {
    //   ...state,
    //   showAlert: true,
    //   alertColor: "success",
    //   alertText: "Updated recipe",
    //   isLoading: false,
    //   redirect: true,
    //   redirectFrom: "/my/meal-plan/create-recipe-or-food",
    //   recipeName: "",
    //   recipeSourceUrl: "",
    //   recipeFeaturedMedia: "",
    //   recipeYield: "",
    //   recipeCalories: "",
    //   recipeProtein: "",
    //   recipeNotes: "",
    //   // mealPlanServingSelection: {
    //   //   ...levelOneValue,
    //   //   week: state.week,
    //   //   recipeId: action.payload.updatedRecipe._id,
    //   //   name: action.payload.updatedRecipe.name,
    //   //   meal: "Dinner",
    //   //   recipeServing: action.payload.updatedRecipe.personalizedYield,
    //   //   assignment: action.payload.schedule,
    //   // },
    // };
  }

  if (action.type === EDIT_RECIPE_COLLECTION_PAGE_ERROR) {
    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: action.payload.msg,
      isLoading: false,
    };
  }

  ////////////////////////////////////// ????? PAGE //////////////////////////////////////

  if (action.type === GET_MENU_BEGIN) {
    return {
      ...state,
      isLoading: true,
      // showAlert: false,
    };
  }

  if (action.type === GET_MENU_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      menuRecipes: action.payload.recipes,
      week: action.payload.week,
      menuId: action.payload.menuId,
      menuNutrition: action.payload.menuNutrition,
      // showAlert: false,
    };
  }

  // Meal Plan
  if (action.type === GET_MEAL_PLAN_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }

  if (action.type === GET_MEAL_PLAN_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      mealPlanRecipes: action.payload.mealPlanRecipes,
      mealPlanSchedule: action.payload.mealPlanSchedule,
      week: action.payload.week,
      showAlert: false,
    };
  }

  if (action.type === SET_EDIT_SERVING_ADD_RECIPE) {
    let levelOneValue = {};

    // const mealPlanServingSelection = "mealPlanServingSelection";

    // // good logic for reference but it's not needed
    // let levelOneValue = null;
    // for (const [key, value] of Object.entries(state)) {
    //   if (key == mealPlanServingSelection) {
    //     levelOneValue = value;
    //   }
    // }

    // const recipe = state.recipes.find(
    //   (recipe) => recipe._id === action.payload.id
    // );

    // const { _id, name, recipeYield } = recipe;

    // let { personalizedYield } = recipe;

    // // action.payload.personalizedYield will contain the yield on the ViewRecipePage since users have the yield input field so they can do on-the-fly serving adjustments. On the Menu and Meal Plan List view page, this will be undefined so therefore use the personalizedYield from the recipe
    // if (typeof action.payload.personalizedYield === "undefined") {
    // } else {
    //   personalizedYield = action.payload.personalizedYield;
    // }

    return {
      ...state,
      mealPlanServingSelection: {
        ...levelOneValue,
        week: state.week,
        recipeId: action.payload.id,
        name: action.payload.name,
        meal: "Dinner",
        recipeServing: action.payload.personalizedYield,
        assignment: action.payload.schedule,
        checkboxOrNumberServingInput: "checkbox",
      },
    };
  }

  if (action.type === SET_EDIT_SERVING_UPDATE_RECIPE) {
    let levelOneValue = {};

    // // good logic for reference but it's not needed

    // let levelOneValue = null;
    // const mealPlanServingSelection = "mealPlanServingSelection";

    // for (const [key, value] of Object.entries(state)) {
    //   if (key == mealPlanServingSelection) {
    //     levelOneValue = value;
    //   }
    // }

    // const recipe = state.mealPlanRecipes.find(
    //   (recipe) => recipe._id === action.payload.id
    // );

    // const { _id, name } = recipe;

    // let { personalizedYield } = recipe;

    // // action.payload.personalizedYield will contain the yield on the ViewRecipePage since users have the yield input field so they can do on-the-fly serving adjustments. On the Menu and Meal Plan List view page, this will be undefined so therefore use the personalizedYield from the recipe
    // if (typeof action.payload.personalizedYield === "undefined") {
    // } else {
    //   personalizedYield = action.payload.personalizedYield;
    // }

    // return {
    //   ...state,
    //   mealPlanServingSelection: {
    //     ...levelOneValue,
    //     week: state.week,
    //     recipeId: _id,
    //     name: name,
    //     meal: "Dinner",
    //     recipeServing: personalizedYield,
    //     assignment: action.payload.generatedRecipeSchedule,
    //   },
    // };

    return {
      ...state,
      mealPlanServingSelection: {
        ...levelOneValue,
        week: state.week,
        recipeId: action.payload.id,
        name: action.payload.name,
        meal: "Dinner",
        recipeServing: action.payload.personalizedYield,
        assignment: action.payload.generatedRecipeSchedule,
        checkboxOrNumberServingInput:
          action.payload.checkboxOrNumberServingInput,
      },
    };
  }

  if (action.type === HANDLE_CHANGE_EDIT_SERVINGS_PAGE_MEAL) {
    const levelOneKey = action.payload.levelOneKey;

    let levelOneValue = null;

    // find value
    for (const [key, value] of Object.entries(state)) {
      if (key == levelOneKey) {
        levelOneValue = value;
      }
    }
    return {
      ...state,
      [levelOneKey]: {
        ...levelOneValue,
        meal: action.payload.value,
        assignment: action.payload.updatedRecipeSchedule,
      },
    };
  }

  if (action.type === HANDLE_CHANGE_EDIT_SERVINGS_PAGE_SERVING_ALLOCATION) {
    const levelOneKey = action.payload.levelOneKey;

    let levelOneValue = null;

    for (const [key, value] of Object.entries(state)) {
      if (key == levelOneKey) {
        levelOneValue = value;
      }
    }

    return {
      ...state,
      [levelOneKey]: {
        ...levelOneValue,
        assignment: action.payload.updatedRecipeSchedule,
      },
    };
  }

  if (action.type === CREATE_MEAL_PLAN_RECIPE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === CREATE_MEAL_PLAN_RECIPE_SUCCESS) {
    return {
      ...state,
      mealPlanRecipes: action.payload.mealPlanRecipes,
      mealPlanSchedule: action.payload.mealPlanSchedule,
      showAlert: true,
      alertColor: "success",
      alertText: `Added ${action.payload.name}`,
      isLoading: false,
      redirectFrom: "/serving/",
      // redirectTo: action.payload.redirectTo,
    };
  }

  if (action.type === CREATE_MEAL_PLAN_RECIPE_ERROR) {
    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: action.payload.msg,
      isLoading: false,
    };
  }

  if (action.type === UPDATE_MEAL_PLAN_RECIPE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === UPDATE_MEAL_PLAN_RECIPE_SUCCESS) {
    return {
      ...state,
      mealPlanSchedule: action.payload.updatedMealPlanSchedule,
      showAlert: true,
      alertColor: "success",
      alertText: `Updated ${action.payload.name}`,
      isLoading: false,
      redirectFrom: "/serving/",
      // redirectTo: action.payload.redirectTo,
    };
  }

  if (action.type === UPDATE_MEAL_PLAN_RECIPE_ERROR) {
    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: action.payload.msg,
      isLoading: false,
    };
  }

  if (action.type === REMOVE_MEAL_PLAN_RECIPE_BEGIN) {
    return {
      ...state,
      showAlert: false,
      alertColor: "",
      alertText: "",
      isLoading: true,
    };
  }

  if (action.type === REMOVE_MEAL_PLAN_RECIPE_SUCCESS) {
    return {
      ...state,
      mealPlanRecipes: action.payload.updatedMealPlanRecipes,
      mealPlanSchedule: action.payload.mealPlanSchedule,
      showAlert: true,
      alertColor: "success",
      alertText: `Successfully removed ${action.payload.name}`,
      isLoading: false,
    };
  }

  if (action.type === REMOVE_MEAL_PLAN_RECIPE_ERROR) {
    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: action.payload.msg,
      isLoading: false,
    };
  }

  if (action.type === UPDATE_MACROS_OR_SUMMARY_VIEW) {
    return {
      ...state,
      mealPlanMacrosOrSummaryView:
        state.mealPlanMacrosOrSummaryView === "summary" ? "macros" : "summary",
    };
  }

  if (
    action.type === CREATE_RECIPE_IN_MEAL_PLAN_AND_SET_SERVING_EDIT_PAGE_BEGIN
  ) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (
    action.type === CREATE_RECIPE_IN_MEAL_PLAN_AND_SET_SERVING_EDIT_PAGE_SUCCESS
  ) {
    let levelOneValue = {};

    return {
      ...state,
      showAlert: true,
      alertColor: "success",
      alertText: "New recipe created",
      isLoading: false,
      redirect: true,
      redirectFrom: "/my/meal-plan/create-recipe-or-food",
      recipeName: "",
      recipeSourceUrl: "",
      recipeFeaturedMedia: "",
      recipeYield: "",
      recipeCalories: "",
      recipeProtein: "",
      recipeNotes: "",
      mealPlanServingSelection: {
        ...levelOneValue,
        week: state.week,
        recipeId: action.payload.newRecipe._id,
        name: action.payload.newRecipe.name,
        meal: "Dinner",
        recipeServing: action.payload.newRecipe.personalizedYield,
        assignment: action.payload.schedule,
        checkboxOrNumberServingInput: "checkbox",
      },
    };
  }

  if (
    action.type === CREATE_RECIPE_IN_MEAL_PLAN_AND_SET_SERVING_EDIT_PAGE_ERROR
  ) {
    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: action.payload.msg,
      isLoading: false,
    };
  }

  if (action.type === EDIT_RECIPE_MEAL_PLAN_PAGE_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === EDIT_RECIPE_MEAL_PLAN_PAGE_SUCCESS) {
    // let levelOneValue = {};
    console.log("dispatch action.payload.redirectFrom");
    console.log(action.payload.redirectFrom);

    const recipe = action.payload.updatedRecipe;

    let {
      _id,
      name,
      rating,
      reviewCount,
      source,
      sourceUrl,
      featuredMedia,
      instagramUrl,
      featuredCategory,
      totalTime,
      cookTime,
      prepTime,
      coolTime,
      freezeTime,
      marinateTime,
      restTime,
      soakTime,
      activeTime,
      description,
      cuisine,
      mealType,
      courseType,
      meatType,
      personalizedYield,
      recipeYield,
      shoppingItems,
      calories,
      carbohydrates,
      fat,
      protein,
      sodium,
      video,
      notes,
      instructions,
      recipeEditable,
      recipeChosenInWeeklyMenu,
    } = recipe;

    return {
      ...state,
      isEditing: true,
      editRecipeId: _id,
      recipeName: name,
      recipeRating: rating,
      recipeReviewCount: reviewCount,
      recipeSource: source,
      recipeSourceUrl: sourceUrl,
      recipeFeaturedMedia: featuredMedia,
      recipeInstagramUrl: instagramUrl,
      recipeFeaturedCategory: featuredCategory,
      recipeTotalTime: totalTime,
      recipeCookTime: cookTime,
      recipeActiveTime: activeTime,
      recipePrepTime: prepTime,
      recipeCoolTime: coolTime,
      recipeFreezeTime: freezeTime,
      recipeMarinateTime: marinateTime,
      recipeRestTime: restTime,
      recipeSoakTime: soakTime,
      recipeDescription: description,
      recipeCuisine: cuisine,
      recipeMealType: mealType,
      recipeCourseType: courseType,
      personalizedYield,
      recipeMeatType: meatType,
      recipeYield: recipeYield,
      recipeShoppingItems: shoppingItems,
      recipeCalories: calories,
      recipeCarbohydrates: carbohydrates,
      recipeFat: fat,
      recipeProtein: protein,
      recipeSodium: sodium,
      recipeVideo: video,
      recipeNotes: notes,
      recipeInstructions: instructions,
      recipeEditable: true,
      recipeChosenInWeeklyMenu,
      redirect: true,
      redirectFrom: action.payload.redirectFrom,
      showAlert: true,
      alertColor: "success",
      alertText: "Updated recipe",
      isLoading: false,
    };

    // return {
    //   ...state,
    //   showAlert: true,
    //   alertColor: "success",
    //   alertText: "Updated recipe",
    //   isLoading: false,
    //   redirect: true,
    //   redirectFrom: "/my/meal-plan/create-recipe-or-food",
    //   recipeName: "",
    //   recipeSourceUrl: "",
    //   recipeFeaturedMedia: "",
    //   recipeYield: "",
    //   recipeCalories: "",
    //   recipeProtein: "",
    //   recipeNotes: "",
    //   // mealPlanServingSelection: {
    //   //   ...levelOneValue,
    //   //   week: state.week,
    //   //   recipeId: action.payload.updatedRecipe._id,
    //   //   name: action.payload.updatedRecipe.name,
    //   //   meal: "Dinner",
    //   //   recipeServing: action.payload.updatedRecipe.personalizedYield,
    //   //   assignment: action.payload.schedule,
    //   // },
    // };
  }

  if (action.type === EDIT_RECIPE_MEAL_PLAN_PAGE_ERROR) {
    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: action.payload.msg,
      isLoading: false,
    };
  }

  ////////////////////////////////////// SHOPPING LIST PAGE //////////////////////////////////////

  if (action.type === GET_SHOPPING_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }

  if (action.type === GET_SHOPPING_SUCCESS) {
    const user = state.user;

    return {
      ...state,
      shoppingList: action.payload.shoppingList,
      week: action.payload.week,
      isLoading: false,
      showAlert: false,
      user: {
        ...user,
        shoppingList: [action.payload.shoppingListId],
      },
    };
  }

  if (action.type === UPDATE_SHOPPING_LIST_GROUP_CATEGORY) {
    return {
      ...state,
      shoppingListGroupCategory:
        action.payload.updatedShoppingListGroupCategory,
    };
  }

  if (action.type === UPDATE_SHOPPING_LIST_HIDE_CHECKED) {
    return {
      ...state,
      shoppingListHideCheckedShoppingItem:
        state.shoppingListHideCheckedShoppingItem === true ? false : true,
    };
  }

  if (action.type === CREATE_SHOPPING_ITEM_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === CREATE_SHOPPING_ITEM_SUCCESS) {
    return {
      ...state,
      shoppingList: action.payload.newShoppingList,
      user: action.payload.newUser,
      showAlert: true,
      alertColor: "success",
      alertText: `${action.payload.addedShoppingItem.name} successfully created`,
      isLoading: false,
    };
  }

  if (action.type === CREATE_SHOPPING_ITEM_ERROR) {
    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: action.payload.msg,
      isLoading: false,
    };
  }

  if (action.type === CLEAR_SHOPPING_ITEM) {
    return {
      ...state,
      shoppingItem: {
        quantity: "",
        unit: "",
        name: "",
        aisle: "",
        note: "",
      },
    };
  }

  if (action.type === SET_EDIT_SHOPPING_ITEM) {
    const shoppingItem = state.shoppingList.find(
      (shoppingItem) => shoppingItem._id === action.payload.id
    );

    return {
      ...state,
      isEditing: true,
      shoppingItem: shoppingItem,
    };
  }

  // Bug: This was making the unit disappear after I edited or dismissed the shopping item modal on the recipe view and shoppingList page
  if (action.type === CLEAR_SHOPPING_ITEM_VALUES) {
    return {
      ...state,
      isEditing: true,
      shoppingItem: {
        quantity: "",
        unit: "",
        name: "",
        aisle: "",
        note: "",
      },
    };
  }

  if (action.type === EDIT_SHOPPING_LIST_SHOPPING_ITEM_BEGIN) {
    return {
      ...state,
      showAlert: false,
      alertColor: "",
      alertText: "",
      isLoading: true,
    };
  }

  if (action.type === EDIT_SHOPPING_LIST_SHOPPING_ITEM_SUCCESS) {
    console.log("EDIT_SHOPPING_LIST_SHOPPING_ITEM_SUCCESS");

    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertColor: "success",
      alertText: "Successfully updated",
      // shoppingList: action.payload.updatedShoppingList,
    };
  }

  if (action.type === EDIT_SHOPPING_LIST_SHOPPING_ITEM_ERROR) {
    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: action.payload.msg,
      isLoading: false,
    };
  }

  if (action.type === EDIT_RECIPE_SHOPPING_ITEM_BEGIN) {
    return {
      ...state,
      showAlert: false,
      alertColor: "",
      alertText: "",
      isLoading: true,
    };
  }

  if (action.type === EDIT_RECIPE_SHOPPING_ITEM_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertColor: "success",
      alertText: "Successfully updated",
      shoppingList: action.payload.updatedShopping,
    };
  }

  if (action.type === EDIT_RECIPE_SHOPPING_ITEM_ERROR) {
    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: action.payload.msg,
      isLoading: false,
    };
  }

  if (action.type === REMOVE_SHOPPING_LIST_SHOPPING_ITEM_BEGIN) {
    return {
      ...state,
      showAlert: false,
      alertColor: "",
      alertText: "",
      isLoading: true,
    };
  }

  if (action.type === REMOVE_SHOPPING_LIST_SHOPPING_ITEM_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertColor: "success",
      alertText: "Successfully removed",
      shoppingList: action.payload.updatedShopping,
    };
  }

  if (action.type === REMOVE_SHOPPING_LIST_SHOPPING_ITEM_ERROR) {
    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: action.payload.msg,
      isLoading: false,
    };
  }

  if (action.type === REMOVE_RECIPE_SHOPPING_ITEM_BEGIN) {
    return {
      ...state,
      showAlert: false,
      alertColor: "",
      alertText: "",
      isLoading: true,
    };
  }

  if (action.type === REMOVE_RECIPE_SHOPPING_ITEM_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertColor: "success",
      alertText: "Successfully removed",
      shoppingList: action.payload.updatedShopping,
    };
  }

  if (action.type === REMOVE_RECIPE_SHOPPING_ITEM_ERROR) {
    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: action.payload.msg,
      isLoading: false,
    };
  }

  if (action.type === CHECK_BOX_SHOPPING_ITEM_BEGIN) {
    return {
      ...state,
      showAlert: false,
      alertColor: "",
      alertText: "",
      isLoading: true,
    };
  }

  if (action.type === CHECK_BOX_SHOPPING_ITEM_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertColor: "success",
      alertText: "Successfully updated",
      // shoppingList: action.payload.updatedShoppingList,
      thisShoppingListDoesntDoAnything: action.payload.updatedShoppingList,
    };
  }

  if (action.type === CHECK_BOX_SHOPPING_ITEM_ERROR) {
    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: action.payload.msg,
      isLoading: false,
    };
  }

  //// start of view recipe

  if (action.type === SET_EDIT_SHOPPING_ITEM_VIEW_RECIPE_PAGE) {
    const shoppingItem = state.recipeShoppingItems.find(
      (shoppingItem) => shoppingItem._id === action.payload.id
    );

    return {
      ...state,
      isEditing: true,
      shoppingItem: shoppingItem,
    };
  }

  if (action.type === EDIT_RECIPE_SHOPPING_ITEM_BEGIN_VIEW_RECIPE_PAGE) {
    return {
      ...state,
      showAlert: false,
      alertColor: "",
      alertText: "",
      isLoading: true,
    };
  }

  if (action.type === EDIT_RECIPE_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE) {
    const recipe = action.payload.recipe;
    const {
      _id,
      name,
      rating,
      reviewCount,
      source,
      sourceUrl,
      featuredMedia,
      instagramUrl,
      featuredCategory,
      totalTime,
      cookTime,
      prepTime,
      coolTime,
      freezeTime,
      marinateTime,
      restTime,
      soakTime,
      activeTime,
      description,
      cuisine,
      mealType,
      courseType,
      meatType,
      personalizedYield,
      recipeYield,
      shoppingItems,
      calories,
      carbohydrates,
      fat,
      protein,
      sodium,
      video,
      notes,
      instructions,
      recipeEditable,
      recipeChosenInWeeklyMenu,
    } = recipe;
    // // let { personalizedYield } = state;
    // checks I send a new personalizedYield in editRecipeAfterEveryClick
    // if (typeof action.payload !== "undefined") {
    //   personalizedYield = action.payload.recipe.personalizedYield;
    // }
    return {
      ...state,
      isEditing: true,
      editRecipeId: _id,
      recipeName: name,
      recipeRating: rating,
      recipeReviewCount: reviewCount,
      recipeSource: source,
      recipeSourceUrl: sourceUrl,
      recipeFeaturedMedia: featuredMedia,
      recipeInstagramUrl: instagramUrl,
      recipeFeaturedCategory: featuredCategory,
      recipeTotalTime: totalTime,
      recipeCookTime: cookTime,
      recipeActiveTime: activeTime,
      recipePrepTime: prepTime,
      recipeCoolTime: coolTime,
      recipeFreezeTime: freezeTime,
      recipeMarinateTime: marinateTime,
      recipeRestTime: restTime,
      recipeSoakTime: soakTime,
      recipeDescription: description,
      recipeCuisine: cuisine,
      recipeMealType: mealType,
      recipeCourseType: courseType,
      personalizedYield,
      recipeMeatType: meatType,
      recipeYield: recipeYield,
      recipeShoppingItems: shoppingItems,
      recipeCalories: calories,
      recipeCarbohydrates: carbohydrates,
      recipeFat: fat,
      recipeProtein: protein,
      recipeSodium: sodium,
      recipeVideo: video,
      recipeNotes: notes,
      recipeInstructions: instructions,
      recipeEditable: true,
      recipeChosenInWeeklyMenu,
      showAlert: true,
      alertColor: "success",
      alertText: "Successfully updated",
      isLoading: false,
    };
  }

  if (action.type === EDIT_RECIPE_SHOPPING_ITEM_ERROR_VIEW_RECIPE_PAGE) {
    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: action.payload.msg,
      isLoading: false,
    };
  }

  if (action.type === ADD_RECIPE_SHOPPING_ITEM_BEGIN_VIEW_RECIPE_PAGE) {
    return {
      ...state,
      showAlert: false,
      alertColor: "",
      alertText: "",
      isLoading: true,
    };
  }

  if (action.type === ADD_RECIPE_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE) {
    const recipe = action.payload.recipe;
    const {
      _id,
      name,
      rating,
      reviewCount,
      source,
      sourceUrl,
      featuredMedia,
      instagramUrl,
      featuredCategory,
      totalTime,
      cookTime,
      prepTime,
      coolTime,
      freezeTime,
      marinateTime,
      restTime,
      soakTime,
      activeTime,
      description,
      cuisine,
      mealType,
      courseType,
      meatType,
      personalizedYield,
      recipeYield,
      shoppingItems,
      calories,
      carbohydrates,
      fat,
      protein,
      sodium,
      video,
      notes,
      instructions,
      recipeEditable,
      recipeChosenInWeeklyMenu,
    } = recipe;
    // // let { personalizedYield } = state;
    // checks I send a new personalizedYield in editRecipeAfterEveryClick
    // if (typeof action.payload !== "undefined") {
    //   personalizedYield = action.payload.recipe.personalizedYield;
    // }
    return {
      ...state,
      isEditing: true,
      editRecipeId: _id,
      recipeName: name,
      recipeRating: rating,
      recipeReviewCount: reviewCount,
      recipeSource: source,
      recipeSourceUrl: sourceUrl,
      recipeFeaturedMedia: featuredMedia,
      recipeInstagramUrl: instagramUrl,
      recipeFeaturedCategory: featuredCategory,
      recipeTotalTime: totalTime,
      recipeCookTime: cookTime,
      recipeActiveTime: activeTime,
      recipePrepTime: prepTime,
      recipeCoolTime: coolTime,
      recipeFreezeTime: freezeTime,
      recipeMarinateTime: marinateTime,
      recipeRestTime: restTime,
      recipeSoakTime: soakTime,
      recipeDescription: description,
      recipeCuisine: cuisine,
      recipeMealType: mealType,
      recipeCourseType: courseType,
      personalizedYield,
      recipeMeatType: meatType,
      recipeYield: recipeYield,
      recipeShoppingItems: shoppingItems,
      recipeCalories: calories,
      recipeCarbohydrates: carbohydrates,
      recipeFat: fat,
      recipeProtein: protein,
      recipeSodium: sodium,
      recipeVideo: video,
      recipeNotes: notes,
      recipeInstructions: instructions,
      recipeEditable: true,
      recipeChosenInWeeklyMenu,
      showAlert: true,
      alertColor: "success",
      alertText: "Successfully updated",
      isLoading: false,
    };
  }

  if (action.type === ADD_RECIPE_SHOPPING_ITEM_ERROR_VIEW_RECIPE_PAGE) {
    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: action.payload.msg,
      isLoading: false,
    };
  }

  if (action.type === REMOVE_RECIPE_SHOPPING_ITEM_BEGIN_VIEW_RECIPE_PAGE) {
    return {
      ...state,
      showAlert: false,
      alertColor: "",
      alertText: "",
      isLoading: true,
    };
  }

  if (action.type === REMOVE_RECIPE_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE) {
    const recipe = action.payload.recipe;

    let {
      _id,
      name,
      rating,
      reviewCount,
      source,
      sourceUrl,
      featuredMedia,
      instagramUrl,
      featuredCategory,
      totalTime,
      cookTime,
      prepTime,
      coolTime,
      freezeTime,
      marinateTime,
      restTime,
      soakTime,
      activeTime,
      description,
      cuisine,
      mealType,
      courseType,
      meatType,
      personalizedYield,
      recipeYield,
      shoppingItems,
      calories,
      carbohydrates,
      fat,
      protein,
      sodium,
      video,
      notes,
      instructions,
      recipeEditable,
      recipeChosenInWeeklyMenu,
    } = recipe;

    // // let { personalizedYield } = state;

    // // checks I send a new personalizedYield in editRecipeAfterEveryClick
    // if (typeof action.payload !== "undefined") {
    //   personalizedYield = action.payload.recipe.personalizedYield;
    // }

    return {
      ...state,
      isEditing: true,
      editRecipeId: _id,
      recipeName: name,
      recipeRating: rating,
      recipeReviewCount: reviewCount,
      recipeSource: source,
      recipeSourceUrl: sourceUrl,
      recipeFeaturedMedia: featuredMedia,
      recipeInstagramUrl: instagramUrl,
      recipeFeaturedCategory: featuredCategory,
      recipeTotalTime: totalTime,
      recipeCookTime: cookTime,
      recipeActiveTime: activeTime,
      recipePrepTime: prepTime,
      recipeCoolTime: coolTime,
      recipeFreezeTime: freezeTime,
      recipeMarinateTime: marinateTime,
      recipeRestTime: restTime,
      recipeSoakTime: soakTime,
      recipeDescription: description,
      recipeCuisine: cuisine,
      recipeMealType: mealType,
      recipeCourseType: courseType,
      personalizedYield,
      recipeMeatType: meatType,
      recipeYield: recipeYield,
      recipeShoppingItems: shoppingItems,
      recipeCalories: calories,
      recipeCarbohydrates: carbohydrates,
      recipeFat: fat,
      recipeProtein: protein,
      recipeSodium: sodium,
      recipeVideo: video,
      recipeNotes: notes,
      recipeInstructions: instructions,
      recipeEditable: true,
      recipeChosenInWeeklyMenu,
      showAlert: true,
      alertColor: "success",
      alertText: "Successfully removed",
      isLoading: false,
    };
  }

  if (action.type === REMOVE_RECIPE_SHOPPING_ITEM_ERROR_VIEW_RECIPE_PAGE) {
    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: action.payload.msg,
      isLoading: false,
    };
  }

  if (action.type === UPDATE_SHOPPING_ITEM_RECIPE_QUANTITY_ERROR) {
    const shoppingItem = state.shoppingItem;

    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: "Enter a valid number, decimal number, or fraction.",
      shoppingItem: {
        ...shoppingItem,
        quantity: action.payload.updatedShoppingItemQuantity,
      },
    };
  }

  if (action.type === UPDATE_SHOPPING_ITEM_RECIPE_QUANTITY_SUCCESS) {
    const shoppingItem = state.shoppingItem;

    return {
      ...state,
      showAlert: false,
      shoppingItem: {
        ...shoppingItem,
        quantity: action.payload.updatedShoppingItemQuantity,
      },
    };
  }

  if (action.type === CHECK_BOX_SHOPPING_ITEM_BEGIN_VIEW_RECIPE_PAGE) {
    return {
      ...state,
      showAlert: false,
      alertColor: "",
      alertText: "",
      isLoading: true,
    };
  }

  if (action.type === CHECK_BOX_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE) {
    const recipe = action.payload.recipe;

    let {
      _id,
      name,
      rating,
      reviewCount,
      source,
      sourceUrl,
      featuredMedia,
      instagramUrl,
      featuredCategory,
      totalTime,
      cookTime,
      prepTime,
      coolTime,
      freezeTime,
      marinateTime,
      restTime,
      soakTime,
      activeTime,
      description,
      cuisine,
      mealType,
      courseType,
      meatType,
      personalizedYield,
      recipeYield,
      shoppingItems,
      calories,
      carbohydrates,
      fat,
      protein,
      sodium,
      video,
      notes,
      instructions,
      recipeEditable,
      recipeChosenInWeeklyMenu,
    } = recipe;

    return {
      ...state,
      isEditing: true,
      editRecipeId: _id,
      recipeName: name,
      recipeRating: rating,
      recipeReviewCount: reviewCount,
      recipeSource: source,
      recipeSourceUrl: sourceUrl,
      recipeFeaturedMedia: featuredMedia,
      recipeInstagramUrl: instagramUrl,
      recipeFeaturedCategory: featuredCategory,
      recipeTotalTime: totalTime,
      recipeCookTime: cookTime,
      recipeActiveTime: activeTime,
      recipePrepTime: prepTime,
      recipeCoolTime: coolTime,
      recipeFreezeTime: freezeTime,
      recipeMarinateTime: marinateTime,
      recipeRestTime: restTime,
      recipeSoakTime: soakTime,
      recipeDescription: description,
      recipeCuisine: cuisine,
      recipeMealType: mealType,
      recipeCourseType: courseType,
      personalizedYield,
      recipeMeatType: meatType,
      recipeYield: recipeYield,
      recipeShoppingItems: shoppingItems,
      recipeCalories: calories,
      recipeCarbohydrates: carbohydrates,
      recipeFat: fat,
      recipeProtein: protein,
      recipeSodium: sodium,
      recipeVideo: video,
      recipeNotes: notes,
      recipeInstructions: instructions,
      recipeEditable: true,
      recipeChosenInWeeklyMenu,
      showAlert: true,
      alertColor: "success",
      alertText: "Successfully updated",
      isLoading: false,
    };
  }

  if (action.type === CHECK_BOX_SHOPPING_ITEM_ERROR_VIEW_RECIPE_PAGE) {
    return {
      ...state,
      showAlert: true,
      alertColor: "danger",
      alertText: action.payload.msg,
      isLoading: false,
    };
  }

  throw new Error(`no such action : ${action.type}`);
};

export default reducer;
