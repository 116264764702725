import moment from "moment";

// Time format
const formatTime = (num) => {
  let hours = Math.floor(num / 60);
  let minutes = num % 60;

  let minutesDisplay = "";
  let hoursDisplay = "";

  if (minutes === 0) {
    minutesDisplay = "";
  } else if (minutes === 1) {
    minutesDisplay = `${minutes} minute`;
  } else if (minutes < 60) {
    minutesDisplay = `${minutes} minutes`;
  }

  if (hours === 0) {
    hoursDisplay = "";
  } else if (hours === 1) {
    hoursDisplay = `${hours} hour`;
  } else if (hours > 1) {
    hoursDisplay = `${hours} hours`;
  }

  if (num === 0 || num === "") {
    return "0 minutes";
  } else if (num < 60) {
    return minutesDisplay;
  } else if (num > 59 && minutes === 0) {
    return hoursDisplay;
  } else {
    return `${hoursDisplay} & ${minutesDisplay}`;
  }
};

// Time format
const formatTimeAbridge = (num) => {
  let hours = Math.floor(num / 60);
  let minutes = num % 60;

  let minutesDisplay = "";
  let hoursDisplay = "";

  if (minutes === 0) {
    minutesDisplay = "";
  } else if (minutes === 1) {
    minutesDisplay = `${minutes} min`;
  } else if (minutes < 60) {
    minutesDisplay = `${minutes} min`;
  }

  if (hours === 0) {
    hoursDisplay = "";
  } else if (hours === 1) {
    hoursDisplay = `${hours} hr`;
  } else if (hours > 1) {
    hoursDisplay = `${hours} hr`;
  }

  if (num === 0 || num === "") {
    return "0 min";
  } else if (num < 60) {
    return minutesDisplay;
  } else if (num > 59 && minutes === 0) {
    return hoursDisplay;
  } else {
    return `${hoursDisplay} & ${minutesDisplay}`;
  }
};

const currentWeek = (date) => {
  return moment().week();
};

// create conditional statement to know if it is Today

const currentWeekDays = (mealPlanServingSelectionWeek) => {
  let currentWeekDaysList = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

  // I can't use current week, I to use mealPlanServingSelectionWeek so it works in other weeks
  const currentWeek = moment().week();

  if (currentWeek == mealPlanServingSelectionWeek) {
    // console.log("it's the current week");

    // const today = moment().weekday();
    // console.log("today");
    // console.log(today);

    const TodayIsDayOfTheWeek = moment().weekday() - 1;

    currentWeekDaysList[TodayIsDayOfTheWeek] = "TODAY";

    // console.log(currentWeekDaysList);
  }

  return currentWeekDaysList;
};

//
//
//
//
//
//

const tabWeeks = (date) => {
  const currentWeek = moment().week();

  const list = [
    // week 1 , Monday
    {
      week: currentWeek,
      date: moment()
        .week(currentWeek)
        .weekday(1)
        .startOf("day")
        .format("ddd MMM D"),
    },
    // week 2 , Monday
    {
      week: currentWeek + 1,
      date: moment()
        .week(currentWeek + 1)
        .weekday(1)
        .startOf("day")
        .format("ddd MMM D"),
    },
    // week 3 , Monday
    {
      week: currentWeek + 2,
      date: moment()
        .week(currentWeek + 2)
        .weekday(1)
        .startOf("day")
        .format("ddd MMM D"),
    },
    // week 1 , Sunday
    {
      week: currentWeek,
      date: moment()
        .week(currentWeek)
        .weekday(7)
        .startOf("day")
        .format("ddd MMM D"),
    },
  ];

  return list;
};

export {
  formatTime,
  formatTimeAbridge,
  currentWeek,
  tabWeeks,
  currentWeekDays,
};
