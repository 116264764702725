import { IonItem, IonLabel, IonTextarea } from "@ionic/react";

const FormTextarea = ({
  name,
  value,
  onIonChange,
  labelText,
  index,
  autoGrow,
  style,
  disabled,
  labelStyle,
}) => {
  return (
    <IonItem>
      <IonLabel style={labelStyle} position="stacked">
        {labelText}
      </IonLabel>
      <IonTextarea
        // style={{ marginTop: "0" }}
        style={style}
        index={index}
        value={value}
        autoGrow={autoGrow}
        rows={1}
        name={name}
        disabled={disabled}
        onIonChange={onIonChange}
      />
    </IonItem>
  );
};

export default FormTextarea;
