// import mongoose from "mongoose";
import { useAppContext } from "../context/appContext";
import { InstagramEmbed } from "react-social-media-embed";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonList,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonImg,
  IonItem,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { FormRow, FormRowSelect, FormTextarea, ChipAlert } from "../components";

import { close } from "ionicons/icons";

import { formatTimeAbridge } from "../utils/FormattingUtils";
import {
  useHandleRecipeInput,
  useAddShoppingItemFiled,
  useAddShoppingItemFiledByItemId,
} from "../utils/Helpers.js";
import {
  recipeYieldList,
  shoppingItemUnitCategories,
  meatTypes,
  recipeUtensilsList,
} from "../utils/Constants.js";

const AddRecipe = () => {
  const {
    isLoading,
    showAlert,
    displayAlert,
    isEditing,
    handleChange,
    createRecipe,
    removeShoppingItemAddRecipePage,
    handleChangeShoppingItemRecipePage,
    addInscructionStep,
    removeInstructionStep,
    handleChangeInstructions,
    addUtensil,
    removeUtensil,
    handleChangeUtensil,
    editRecipe,
    recipeName,
    recipeRating,
    recipeReviewCount,
    recipeSource,
    recipeSourceUrl,
    recipeFeaturedMedia,
    recipeInstagramUrl,
    recipeFeaturedCategory,
    recipeFeaturedCategories,
    recipeTotalTime,
    recipeCookTime,
    recipeActiveTime,
    recipePrepTime,
    recipeCoolTime,
    recipeFreezeTime,
    recipeMarinateTime,
    recipeRestTime,
    recipeSoakTime,
    recipeDescription,
    recipeCuisine,
    recipeCuisines,
    recipeMealType,
    recipeMeatTypes,
    recipeUtensils,
    recipeVideo,
    recipeNotes,
    recipeCourseType,
    recipeCourseTypes,
    recipeMeatType,
    personalizedYield,
    recipeYield,
    recipeShoppingItems,
    recipeCalories,
    recipeCarbohydrates,
    recipeFat,
    recipeProtein,
    recipeSodium,
    recipeInstructions,
  } = useAppContext();

  const { useHandleChange } = useHandleRecipeInput();
  const { addShoppingItemFiled } = useAddShoppingItemFiled();
  const { addShoppingItemFiledByItemId } = useAddShoppingItemFiledByItemId();

  const onSubmit = (e) => {
    e.preventDefault();
    if (!recipeName) {
      displayAlert();
      return;
    }
    if (isEditing) {
      editRecipe();
      return;
    }
    createRecipe();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>AddRecipe</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form className="ion-padding">
          <h3>{isEditing ? "edit recipe" : "add recipe"}</h3>
          <IonList>
            {/* recipeFeaturedMedia input */}
            {recipeFeaturedMedia === "" ? (
              <div></div>
            ) : (
              <IonImg src={recipeFeaturedMedia}></IonImg>
            )}
            {/* recipeInstagramUrl input */}
            {recipeInstagramUrl === "" ||
            typeof recipeInstagramUrl === "undefined" ? (
              <div></div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <InstagramEmbed url={recipeInstagramUrl} width={"100%"} />
              </div>
              // <div></div>
            )}
            {/* recipeName input */}
            <FormRow
              labelText="Name"
              type="text"
              name="recipeName"
              value={recipeName}
              onIonChange={useHandleChange}
            />
            {/* description input */}
            <FormRow
              labelText="Description"
              type="text"
              name="recipeDescription"
              value={recipeDescription}
              onIonChange={useHandleChange}
            />
            {/* recipeRating input */}
            <FormRow
              labelText="Rating"
              type="number"
              name="recipeRating"
              value={recipeRating}
              onIonChange={useHandleChange}
            />
            {/* recipeReviewCount input */}
            <FormRow
              labelText="Review Count"
              type="number"
              name="recipeReviewCount"
              value={recipeReviewCount}
              onIonChange={useHandleChange}
            />
            {/* recipeSource input */}
            <FormRow
              labelText="Recipe Source"
              type="text"
              name="recipeSource"
              value={recipeSource}
              onIonChange={useHandleChange}
            />
            {/* recipeSourceUrl input */}
            <FormRow
              labelText="Recipe Source URL"
              type="text"
              name="recipeSourceUrl"
              value={recipeSourceUrl}
              onIonChange={useHandleChange}
            />
            {/* recipeFeaturedMedia input */}
            <FormRow
              labelText="Featured Media"
              type="text"
              name="recipeFeaturedMedia"
              value={recipeFeaturedMedia}
              onIonChange={useHandleChange}
            />
            {/* recipeInstagramUrl input */}
            <FormRow
              labelText="Instagram URL Media"
              type="text"
              name="recipeInstagramUrl"
              value={recipeInstagramUrl}
              onIonChange={useHandleChange}
            />
            {/* recipeFeaturedCategory input */}
            <FormRowSelect
              labelText="Featured Category"
              name="recipeFeaturedCategory"
              value={recipeFeaturedCategory}
              onIonChange={useHandleChange}
              list={recipeFeaturedCategories}
            />
            {/* recipeTotalTime input */}
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <div style={{ width: "125px" }}>
                <FormRow
                  labelText="Total Time"
                  type="text"
                  name="recipeTotalTime"
                  value={recipeTotalTime}
                  onIonChange={useHandleChange}
                />
              </div>
              <div style={{ paddingTop: "30px", paddingLeft: "30px" }}>
                {formatTimeAbridge(recipeTotalTime)}
              </div>
            </div>
            {/* recipeCookTime input */}
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <div style={{ width: "125px" }}>
                <FormRow
                  labelText="Cook Time"
                  type="text"
                  name="recipeCookTime"
                  value={recipeCookTime}
                  onIonChange={useHandleChange}
                />
              </div>
              <div style={{ paddingTop: "30px", paddingLeft: "30px" }}>
                {formatTimeAbridge(recipeCookTime)}
              </div>
            </div>
            {/* recipeActiveTime input */}
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <div style={{ width: "125px" }}>
                <FormRow
                  labelText="Active Time"
                  type="text"
                  name="recipeActiveTime"
                  value={recipeActiveTime}
                  onIonChange={useHandleChange}
                />
              </div>
              <div style={{ paddingTop: "30px", paddingLeft: "30px" }}>
                {formatTimeAbridge(recipeActiveTime)}
              </div>
            </div>
            {/* recipePrepTime input */}
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <div style={{ width: "125px" }}>
                <FormRow
                  labelText="Prep Time"
                  type="text"
                  name="recipePrepTime"
                  value={recipePrepTime}
                  onIonChange={useHandleChange}
                />
              </div>
              <div style={{ paddingTop: "30px", paddingLeft: "30px" }}>
                {formatTimeAbridge(recipePrepTime)}
              </div>
            </div>
            {/* recipeCoolTime input */}
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <div style={{ width: "125px" }}>
                <FormRow
                  labelText="Cool Time"
                  type="text"
                  name="recipeCoolTime"
                  value={recipeCoolTime}
                  onIonChange={useHandleChange}
                />
              </div>
              <div style={{ paddingTop: "30px", paddingLeft: "30px" }}>
                {formatTimeAbridge(recipeCoolTime)}
              </div>
            </div>
            {/* Freeze Time input */}
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <div style={{ width: "125px" }}>
                <FormRow
                  labelText="Freeze Time"
                  type="text"
                  name="recipeFreezeTime"
                  value={recipeFreezeTime}
                  onIonChange={useHandleChange}
                />
              </div>
              <div style={{ paddingTop: "30px", paddingLeft: "30px" }}>
                {formatTimeAbridge(recipeFreezeTime)}
              </div>
            </div>
            {/* recipeMarinateTime input */}
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <div style={{ width: "125px" }}>
                <FormRow
                  labelText="Marinate Time"
                  type="text"
                  name="recipeMarinateTime"
                  value={recipeMarinateTime}
                  onIonChange={useHandleChange}
                />
              </div>
              <div style={{ paddingTop: "30px", paddingLeft: "30px" }}>
                {formatTimeAbridge(recipeMarinateTime)}
              </div>
            </div>
            {/* recipeRestTime input */}
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <div style={{ width: "125px" }}>
                <FormRow
                  labelText="Rest Time"
                  type="text"
                  name="recipeRestTime"
                  value={recipeRestTime}
                  onIonChange={useHandleChange}
                />
              </div>
              <div style={{ paddingTop: "30px", paddingLeft: "30px" }}>
                {formatTimeAbridge(recipeRestTime)}
              </div>
            </div>
            {/* recipeSoakTime input */}
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <div style={{ width: "125px" }}>
                <FormRow
                  labelText="Soak Time"
                  type="text"
                  name="recipeSoakTime"
                  value={recipeSoakTime}
                  onIonChange={useHandleChange}
                />
              </div>
              <div style={{ paddingTop: "30px", paddingLeft: "30px" }}>
                {formatTimeAbridge(recipeSoakTime)}
              </div>
            </div>
            {/* recipeMealType input */}
            {/* <FormRowSelect
              labelText="Meal Type"
              name="recipeMealType"
              placeholder="Meal Type"
              value={recipeMealType}
              onIonChange={useHandleChange}
              list={recipeMealTypes}
            /> */}
            {/* recipeCourseType input */}
            <FormRowSelect
              labelText="Course Type"
              name="recipeCourseType"
              placeholder="Course"
              value={recipeCourseType}
              onIonChange={useHandleChange}
              list={recipeCourseTypes}
            />
            {/* meat type dropdown input */}
            <FormRowSelect
              labelText="Meat Type"
              name="recipeMeatType"
              placeholder="Meat"
              value={recipeMeatType}
              onIonChange={useHandleChange}
              list={meatTypes}
            />
            {/* recipeCuisine input */}
            <FormRowSelect
              labelText="Cuisine"
              name="recipeCuisine"
              placeholder="Cuisine"
              value={recipeCuisine}
              onIonChange={useHandleChange}
              list={recipeCuisines}
            />
            {/* serving dropdown input */}
            <FormRowSelect
              labelText="Recipe Yield"
              name="recipeYield"
              placeholder="Recipe Yield"
              value={recipeYield}
              onIonChange={useHandleChange}
              list={recipeYieldList}
            />
            {/* personalizedYield input */}
            <FormRowSelect
              labelText="Personalized Yield"
              name="personalizedYield"
              placeholder="Personalized Yield"
              value={personalizedYield}
              onIonChange={useHandleChange}
              list={recipeYieldList}
            />
            <h2>Ingredients</h2>
            {recipeShoppingItems.map((recipeShoppingItem, index) => {
              const hidden = recipeShoppingItem.hidden;

              const editable =
                typeof recipeShoppingItem.item === "string" ||
                typeof recipeShoppingItem.item === "undefined" ||
                recipeShoppingItem.item == null
                  ? true
                  : false;

              return (
                <IonGrid>
                  <IonRow>
                    <IonCol size="2">
                      <FormRow
                        labelText="Q"
                        type="text"
                        name="quantity"
                        index={index}
                        value={recipeShoppingItem.quantity}
                        onIonChange={handleChangeShoppingItemRecipePage}
                      />
                    </IonCol>
                    <IonCol size="3">
                      <IonList>
                        <IonItem>
                          <IonSelect
                            interface="action-sheet"
                            name="unit"
                            index={index}
                            // refactor: for some reason using onChange in stead of onIonChange fixed my problem
                            onIonChange={handleChangeShoppingItemRecipePage}
                            // onIonChange={handleShoppingItemRecipeInput}
                            value={recipeShoppingItem.unit}
                          >
                            {shoppingItemUnitCategories.map((itemValue, i) => {
                              return (
                                <IonSelectOption
                                  key={i}
                                  value={itemValue}
                                  disabled={false}
                                >
                                  {itemValue}
                                </IonSelectOption>
                              );
                            })}
                          </IonSelect>
                        </IonItem>
                      </IonList>
                    </IonCol>
                    <IonCol size="4">
                      <FormRow
                        labelText="Display Name"
                        type="text"
                        name="name"
                        index={index}
                        // disabled={editable ? false : true}
                        value={recipeShoppingItem.name}
                        // value={
                        //   editable
                        //     ? recipeShoppingItem.name
                        //     : recipeShoppingItem.item.name
                        // }
                        onIonChange={handleChangeShoppingItemRecipePage}
                      />
                    </IonCol>
                    <IonCol size="2">
                      <FormRow
                        labelText="ID"
                        type="text"
                        name="item"
                        index={index}
                        i={index}
                        value={
                          editable
                            ? recipeShoppingItem.item
                            : recipeShoppingItem.item._id
                        }
                        onIonChange={handleChangeShoppingItemRecipePage}
                      />
                    </IonCol>
                    <IonCol size="1">
                      <IonIcon
                        icon={close}
                        index={index}
                        onClick={removeShoppingItemAddRecipePage}
                      ></IonIcon>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              );
            })}

            <IonButton onClick={addShoppingItemFiled}>Add</IonButton>
            <IonButton onClick={addShoppingItemFiledByItemId}>
              Add by item ID
            </IonButton>

            <h2>Nutrition</h2>
            {/* recipeCalories input */}
            <FormRow
              labelText="Calories"
              type="number"
              name="recipeCalories"
              value={recipeCalories}
              onIonChange={useHandleChange}
            />
            {/* recipeCarbohydrates input */}
            <FormRow
              labelText="Carbohydrates"
              type="number"
              name="recipeCarbohydrates"
              value={recipeCarbohydrates}
              onIonChange={useHandleChange}
            />
            {/* recipeFat input */}
            <FormRow
              labelText="Fat"
              type="number"
              name="recipeFat"
              value={recipeFat}
              onIonChange={useHandleChange}
            />
            {/* recipeProtein input */}
            <FormRow
              labelText="Protein"
              type="number"
              name="recipeProtein"
              value={recipeProtein}
              onIonChange={useHandleChange}
            />
            {/* recipeSodium input */}
            <FormRow
              labelText="Sodium"
              type="number"
              name="recipeSodium"
              value={recipeSodium}
              onIonChange={useHandleChange}
            />
            <h2>Utensils</h2>
            {/* recipeUtensils input */}
            {/* <FormRowSelect
              multiple={true}
              labelText="Utensils"
              name="recipeUtensils"
              placeholder="Utensils"
              value={recipeUtensils}
              onIonChange={useHandleChange}
              list={recipeUtensilsList}
            /> */}
            {recipeUtensils.map((recipeUtensil, index) => {
              return (
                <IonGrid>
                  <IonRow>
                    <IonCol size="7">
                      <FormRowSelect
                        labelText=""
                        position="floating"
                        name="utensil"
                        placeholder="utensils"
                        index={index}
                        value={recipeUtensil.utensil}
                        onIonChange={handleChangeUtensil}
                        list={recipeUtensilsList}
                      />
                    </IonCol>
                    <IonCol size="4">
                      <FormRow
                        labelText=""
                        type="text"
                        name="quantity"
                        placeholder="quantity"
                        index={index}
                        value={recipeUtensil.quantity}
                        onIonChange={handleChangeUtensil}
                      />
                    </IonCol>
                    <IonCol size="1">
                      <IonIcon
                        icon={close}
                        index={index}
                        onClick={removeUtensil}
                      ></IonIcon>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              );
            })}
            <IonButton onClick={addUtensil}>add</IonButton>
            <h2>Instructions</h2>
            {/* recipeNotes input */}
            <FormRow
              labelText="Notes"
              type="text"
              name="recipeNotes"
              value={recipeNotes}
              onIonChange={useHandleChange}
            />
            {/* recipeVideoUrl input */}
            <FormRow
              labelText="YouTube Video Instructions"
              type="text"
              name="recipeVideo"
              value={recipeVideo}
              onIonChange={useHandleChange}
            />

            {/* recipeInstructions input */}
            {recipeInstructions.map((recipeInstruction, index) => {
              return (
                <IonGrid>
                  <IonRow>
                    <IonCol size="3">
                      <div>Step {index + 1}</div>
                    </IonCol>
                    <IonCol size="1">
                      <IonIcon
                        icon={close}
                        index={index}
                        onClick={removeInstructionStep}
                      ></IonIcon>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    {recipeInstruction.media === "" ? (
                      <div></div>
                    ) : (
                      <IonImg src={recipeInstruction.media}></IonImg>
                    )}
                  </IonRow>
                  <IonRow>
                    <IonCol size="12">
                      <FormRow
                        labelText="Media"
                        type="text"
                        name="media"
                        index={index}
                        value={recipeInstruction.media}
                        onIonChange={handleChangeInstructions}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12">
                      <FormTextarea
                        labelText="Description"
                        name="description"
                        index={index}
                        autoGrow={true}
                        value={recipeInstruction.description}
                        onIonChange={handleChangeInstructions}
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              );
            })}
            <IonButton onClick={addInscructionStep}>add</IonButton>
          </IonList>
          {showAlert && <ChipAlert />}
          <IonButton expand="block" onClick={onSubmit} disabled={isLoading}>
            Add
          </IonButton>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default AddRecipe;
