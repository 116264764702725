import { useState, useEffect } from "react";
import { IonContent, IonPage, useIonViewDidEnter } from "@ionic/react";
import styled from "styled-components";
import {
  IonList,
  IonButton,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
  IonButtons,
} from "@ionic/react";
import { filter } from "ionicons/icons";
import {
  // RecipesContainer,
  // SearchContainer,
  Header,
  FormRowSelect,
  FormRow,
  RecipeCard,
  PageBtnContainer,
} from "../components";
import { useAppContext } from "../context/appContext";

const WebWidthCardContainer = styled.div`
  margin: auto;

  @media (min-width: 770px) {
    max-width: 740px;
  }

  @media (min-width: 1142px) {
    max-width: 1115px;
  }

  @media (min-width: 1512px) {
    max-width: 1485px;
  }

  @media (min-width: 1900px) {
    max-width: 1850px;
  }
`;

const CardWidthContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Search = () => {
  const {
    isLoading,
    handleChange,
    recipeFeaturedCategories,
    recipeCourseTypes,
    recipeCuisines,
    recipeMealTypes,
    recipeMeatTypes,
    SortOptions,
    // Search section
    searchRecipeName,
    searchRecipeSource,
    searchRecipeSourceUrl,
    searchRecipeTotalTime,
    searchRecipeCookTime,
    searchRecipeMarinateTime,
    searchRecipeRestTime,
    searchRecipeFeaturedCategory,
    searchRecipeCourseType,
    searchRecipeCuisine,
    searchRecipeMealType,
    searchRecipeMeatType,
    searchRecipeYield,
    searchRecipeCalories,
    searchRecipeCarbohydrates,
    searchRecipeFat,
    searchRecipeProtein,
    searchRecipeVideo,
    searchRecipeSortOption,
    clearSearchFilters,
    // Cards result section
    getSearchPageRecipes,
    searchRecipes,
    searchTotalRecipes,
    searchCurrentPage,
    searchNumOfPages,
    // Pagination section
  } = useAppContext();

  ////////////////////////////////////// SEARCH SECTION //////////////////////////////////////

  const handleSearch = (e) => {
    // isLoading check ensure we don't make additional request we're during the middle of a request so we make less unnecessary requests
    // if (isLoading) return;
    handleChange({ name: e.target.name, value: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    clearSearchFilters();
  };

  // filter modal
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  ////////////////////////////////////// CARDS RESULT SECTION //////////////////////////////////////
  // Bug: useIonViewDidEnter and useEffect run twice (make API call) on every load. useIonViewDidEnter works better than
  // useEffect because it runs when a user navigates away and back to the search page
  // useIonViewDidEnter(() => {
  //   console.log("useIonViewDidEnter");

  //   getSearchPageRecipes();
  // });

  useEffect(() => {
    handleChange({
      name: "discoverSelectedPage",
      value: "/my/discover/search",
    });

    getSearchPageRecipes();

    // eslint-disable-next-line
  }, [
    searchCurrentPage,
    searchRecipeName,
    searchRecipeSource,
    searchRecipeSourceUrl,
    searchRecipeTotalTime,
    searchRecipeCookTime,
    searchRecipeMarinateTime,
    searchRecipeRestTime,
    searchRecipeFeaturedCategory,
    searchRecipeCourseType,
    searchRecipeCuisine,
    searchRecipeMealType,
    searchRecipeMeatType,
    searchRecipeYield,
    searchRecipeCalories,
    searchRecipeCarbohydrates,
    searchRecipeFat,
    searchRecipeProtein,
    searchRecipeVideo,
    searchRecipeSortOption,
  ]);

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  if (searchRecipes.length === 0) {
    <h2>No recipes to display...</h2>;
  }

  ////////////////////////////////////// PAGINATION SECTION //////////////////////////////////////

  const pages = Array.from({ length: searchNumOfPages }, (_, index) => {
    return index + 1;
  });

  // console.log("pages");

  // I could do some logic here checking inf the searchCurrentPage is in the first 5 slots, if it is return certain page number. Same logic if it's in the last 5 pages.
  // if (pages.length > 5) {
  //   console.log("it's greater than 5");
  // }

  const nextPage = () => {
    let newPage = searchCurrentPage + 1;
    if (newPage > searchNumOfPages) {
      newPage = 1;
    }

    handleChange({ name: "searchCurrentPage", value: newPage });
  };

  const prevPage = () => {
    let newPage = searchCurrentPage - 1;
    if (newPage < 1) {
      newPage = searchNumOfPages;
    }

    handleChange({ name: "searchCurrentPage", value: newPage });
  };

  return (
    <IonPage>
      <Header />
      <IonContent className="ion-padding">
        <WebWidthCardContainer>
          <h1>Search</h1>

          {/* ////////////////////////////////////// SEARCH SECTION ////////////////////////////////////// */}
          <>
            <form>
              <div
                style={{
                  paddingBottom: "0",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <FormRow
                  // labelText="Name"
                  styleIonItem={{ width: "100%" }}
                  type="text"
                  placeholder="Search all recipes"
                  name="searchRecipeName"
                  value={searchRecipeName}
                  onIonChange={handleSearch}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "75px",
                  }}
                >
                  <IonButton fill="clear" onClick={() => setOpen(true)}>
                    <IonIcon
                      color="primary"
                      icon={filter}
                      size="large"
                      style={{ height: "26px" }}
                    ></IonIcon>
                  </IonButton>
                </div>
              </div>

              <IonList>{/* search by name */}</IonList>
            </form>
            <IonModal
              isOpen={open}
              onDidDismiss={closeModal}
              breakpoints={[0, 0.3, 0.5, 1]}
              initialBreakpoint={0.5}
              backdropBreakpoint={0.2}
            >
              <IonToolbar>
                <IonTitle>Filters</IonTitle>
                <IonButtons slot="end">
                  <IonButton color="primary" onClick={() => closeModal()}>
                    Close
                  </IonButton>
                </IonButtons>
              </IonToolbar>
              <IonList className="ion-padding">
                {/* search by source */}
                {/* <FormRow
          labelText="Source"
          type="text"
          name="searchRecipeSource"
          value={searchRecipeSource}
          onIonChange={handleSearch}
        /> */}
                {/* search by sourceUrl */}
                {/* <FormRow
          labelText="Source Url"
          type="text"
          name="searchRecipeSourceUrl"
          value={searchRecipeSourceUrl}
          onIonChange={handleSearch}
        /> */}
                {/* search by totalTime */}
                <FormRow
                  labelText="Total Time"
                  type="text"
                  placeholder="Less than minutes"
                  name="searchRecipeTotalTime"
                  value={searchRecipeTotalTime}
                  onIonChange={handleSearch}
                />
                {/* search by cookTime */}
                {/* <FormRow
          labelText="Cook Time"
          type="text"
          name="searchRecipeCookTime"
          value={searchRecipeCookTime}
          onIonChange={handleSearch}
        /> */}
                {/* search by marinateTime */}
                {/* <FormRow
          labelText="Marinate Time"
          type="text"
          name="searchRecipeMarinateTime"
          value={searchRecipeMarinateTime}
          onIonChange={handleSearch}
        /> */}
                {/* search by restTime */}
                {/* <FormRow
          labelText="Rest Time"
          type="text"
          name="searchRecipeRestTime"
          value={searchRecipeRestTime}
          onIonChange={handleSearch}
        /> */}
                {/* the ... is an example of the spread operator  https://www.javascripttutorial.net/es6/javascript-spread/ */}
                {/* search by featuredCategory */}
                {/* <FormRowSelect
          labelText="Featured Category"
          name="searchRecipeFeaturedCategory"
          placeholder="Featured Category"
          value={searchRecipeFeaturedCategory}
          onIonChange={handleSearch}
          list={["All", ...recipeFeaturedCategories]}
        /> */}
                {/* search by recipeCourseTypes */}
                <FormRowSelect
                  labelText="Course"
                  name="searchRecipeCourseType"
                  placeholder="Course Type"
                  value={searchRecipeCourseType}
                  onIonChange={handleSearch}
                  list={["All", ...recipeCourseTypes]}
                />
                {/* search by Cuisine */}
                <FormRowSelect
                  labelText="Cuisine"
                  name="searchRecipeCuisine"
                  placeholder="Cuisine"
                  value={searchRecipeCuisine}
                  onIonChange={handleSearch}
                  list={["All", ...recipeCuisines]}
                />
                {/* search by MealType */}
                {/* <FormRowSelect
          labelText="Meal Type"
          name="searchRecipeMealType"
          placeholder="Meal"
          value={searchRecipeMealType}
          onIonChange={handleSearch}
          list={["All", ...recipeMealTypes]}
        /> */}
                {/* search by meatType */}
                <FormRowSelect
                  labelText="Meat"
                  name="searchRecipeMeatType"
                  placeholder="Meat Type"
                  value={searchRecipeMeatType}
                  onIonChange={handleSearch}
                  list={["All", ...recipeMeatTypes]}
                />
                {/* search by Recipe Yield */}
                {/* <FormRow
          labelText="Recipe Yield"
          type="text"
          name="searchRecipeYield"
          value={searchRecipeYield}
          onIonChange={handleSearch}
        /> */}
                {/* search by calories */}
                {/* <FormRow
          labelText="Calories"
          type="text"
          name="searchRecipeCalories"
          value={searchRecipeCalories}
          onIonChange={handleSearch}
        /> */}
                {/* search by carbohydrates */}
                {/* <FormRow
          labelText="Carbohydrates"
          type="text"
          name="searchRecipeCarbohydrates"
          value={searchRecipeCarbohydrates}
          onIonChange={handleSearch}
        /> */}
                {/* search by fat */}
                {/* <FormRow
          labelText="Fat"
          type="fat"
          name="searchRecipeFat"
          value={searchRecipeFat}
          onIonChange={handleSearch}
        /> */}
                {/* search by protein */}
                {/* <FormRow
          labelText="Protein"
          type="text"
          name="searchRecipeProtein"
          value={searchRecipeProtein}
          onIonChange={handleSearch}
        /> */}
                {/* search by video */}
                {/* <FormRow
          labelText="Video"
          type="text"
          name="searchRecipeVideo"
          value={searchRecipeVideo}
          onIonChange={handleSearch}
        /> */}
                {/* <FormRowSelect
          labelText="Video"
          name="searchRecipeVideo"
          placeholder="Video"
          value={searchRecipeVideo}
          onIonChange={handleSearch}
          list={["Yes", "No"]}
        /> */}
                {/* sort order */}
                {/* <FormRowSelect
          labelText="Sort"
          name="searchRecipeSortOption"
          placeholder="Sort"
          value={searchRecipeSortOption}
          onIonChange={handleSearch}
          list={SortOptions}
        /> */}
              </IonList>
              <IonButton
                className="ion-padding"
                expand="block"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                Clear Filters
              </IonButton>
            </IonModal>
          </>
          {/* <SearchContainer /> */}
          {/* ////////////////////////////////////// CARDS RESULT SECTION ////////////////////////////////////// */}
          <h6
            className="ion-padding"
            style={{ paddingTop: "0px", paddingBottom: "0px" }}
          >
            {searchTotalRecipes} recipe{searchRecipes.length > 1 && "s"} found
          </h6>
          <CardWidthContainer>
            {/* { The ...{recipe} is another way to pass in all of the props */}
            {searchRecipes.map((recipe) => {
              return <RecipeCard key={recipe._id} {...recipe} />;
            })}
          </CardWidthContainer>

          {/* <RecipesContainer /> */}
          {/* ////////////////////////////////////// PAGINATION SECTION ////////////////////////////////////// */}
          {searchNumOfPages == 1 || searchNumOfPages == 0 ? (
            <></>
          ) : (
            <>
              <IonButton color="light" onClick={prevPage} disabled={isLoading}>
                Back
              </IonButton>
              {pages.map((pageNumber) => {
                return (
                  <IonButton
                    color={
                      pageNumber === searchCurrentPage ? "primary" : "light"
                    }
                    key={pageNumber}
                    onClick={() =>
                      handleChange({
                        name: "searchCurrentPage",
                        value: pageNumber,
                      })
                    }
                  >
                    {pageNumber}
                  </IonButton>
                );
              })}
              <IonButton color="light" onClick={nextPage} disabled={isLoading}>
                Next
              </IonButton>
            </>
          )}
        </WebWidthCardContainer>
      </IonContent>
    </IonPage>
  );
};

export default Search;
