import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRouterLink,
  IonList,
  IonItem,
  IonLabel,
} from "@ionic/react";

const Home = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Test Page</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {/* <IonHeader>
          <IonToolbar>
            <IonTitle size="large">Test Page</IonTitle>
          </IonToolbar>
        </IonHeader> */}
        <IonList>
          <IonItem>
            <IonLabel>
              <IonRouterLink routerLink="/my/home">Home</IonRouterLink>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>
              <IonRouterLink routerLink="/my/discover/menu">Menu</IonRouterLink>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>
              <IonRouterLink routerLink="/my/discover/search">
                Search
              </IonRouterLink>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>
              <IonRouterLink routerLink="/my/register">Register</IonRouterLink>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>
              <IonRouterLink routerLink="/my/addrecipe">
                Add Recipe
              </IonRouterLink>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>
              <IonRouterLink routerLink="/my/profile">Profile</IonRouterLink>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>
              <IonRouterLink routerLink="/my/StyledComponentesCourse">
                StyledComponentesCourse
              </IonRouterLink>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>
              <IonRouterLink routerLink="/my/StyledComponentesCourseHome">
                StyledComponentesCourseHome
              </IonRouterLink>
            </IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Home;
