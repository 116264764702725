import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppContext } from "../context/appContext";
import { FormRow } from "../components";
import styled from "styled-components";

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonBackButton,
  IonItem,
  IonInput,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  useIonToast,
  IonSelect,
  IonSelectOption,
  IonSegment,
  IonSegmentButton,
  IonIcon,
  IonChip,
  IonCheckbox,
  IonBadge,
} from "@ionic/react";

import { addCircleOutline, removeCircleOutline } from "ionicons/icons";

import { tabWeeks, currentWeekDays } from "../utils/FormattingUtils";

const WebWidthListContainer = styled.div`
  margin: auto;
  max-width: 750px;
`;

const SpaceSoiPhoneKeyboardDoesntCoverInputs = styled.div`
  .ios {
    height: 375px;
    background-color: white;
    display: block;
  }
`;

const EditServingPage = () => {
  const {
    handleChangeLevelTwo,
    handleChangeServingPageMeal,
    handleChangeEditServingPageServingAllocation,
    showAlert,
    alertColor,
    alertText,
    redirectFrom,
    isLoading,
    mealPlanServingSelection,
    createMealPlanRecipe,
    updateMealPlanRecipe,
    user,
    displayAlert,
  } = useAppContext();

  const history = useHistory();

  const [present] = useIonToast();

  const location = useLocation();
  const isServingAddPage = location.pathname.includes("serving/add");

  useEffect(() => {
    // if it is /serving/ it means it's a redirect meant from the EditServingPage
    if (redirectFrom === "/serving/") {
      if (location.pathname.includes("/my/serving/add")) {
        console.log("location.pathname.includes(/my/serving/add)");
        setTimeout(() => {
          history.goBack();
        }, 1000);
      } else if (location.pathname.includes("my/meal-plan/serving/add")) {
        setTimeout(() => {
          history.push("/my/meal-plan");
        }, 1000);
      } else if (location.pathname.includes("/my/serving/edit")) {
        setTimeout(() => {
          history.goBack();
        }, 1000);
      }
    }
  }, [redirectFrom, history]);

  const presentToast = () => {
    present({
      message: alertText,
      duration: 2000,
      position: "top",
      color: alertColor,
    });
  };

  if (showAlert) {
    console.log("presentToast()");
    presentToast();
  }

  const handleChangeLevelTwoEditServing = (e) => {
    if (e.target.value == "" || e.target.value > 0) {
      handleChangeLevelTwo({
        name: e.target.name,
        value: e.target.value,
        levelOneKey: e.target.levelOneKey,
      });
    } else if (e.target.value <= 0) {
      console.log();

      displayAlert("Serving has to be greater than zero");
    }
  };

  const handleChangeServingMeal = (e) => {
    handleChangeServingPageMeal({
      name: e.target.name,
      value: e.target.value,
      levelOneKey: e.target.levelOneKey,
    });
  };

  const handleChangeEditServingServingAllocationCheckbox = (e) => {
    const value = e.target.checked == true ? "1" : "";

    handleChangeEditServingPageServingAllocation({
      name: e.target.name,
      value: value,
      memberId: e.target.memberId,
      index: e.target.index,
      levelOneKey: e.target.levelOneKey,
    });
  };

  const handleChangeEditServingServingAllocationNumber = (e) => {
    if (e.target.value == "" || e.target.value > 0) {
      handleChangeEditServingPageServingAllocation({
        name: e.target.name,
        value: e.target.value,
        memberId: e.target.memberId,
        index: e.target.index,
        levelOneKey: e.target.levelOneKey,
      });
    } else if (e.target.value <= 0) {
      displayAlert("Serving has to be greater than zero");
    }
  };

  const handleChangeCheckboxOrNumberServingInput = (e) => {
    const constUpdatedValue =
      e.target.value === "checkbox" ? "number" : "checkbox";

    handleChangeLevelTwo({
      name: e.target.name,
      value: constUpdatedValue,
      levelOneKey: e.target.levelOneKey,
    });
  };

  const currentWeekDaysList = currentWeekDays(mealPlanServingSelection.week);

  const handleCreateMealPlanRecipe = () => {
    createMealPlanRecipe();
  };

  const handleUpdateMealPlanRecipe = () => {
    updateMealPlanRecipe();
  };

  const assignment = mealPlanServingSelection.assignment;

  let hideCheckboxOrNumberServingInput = false;

  // begin making a copy and then updating the servign allocation
  for (const i in assignment) {
    // const assignmentMemberUserId = assignment[i].memberUserId;

    // // create empty
    // updatedAssignment.push({
    //   memberUserId: assignmentMemberUserId,
    //   days: [[], [], [], [], [], [], []],
    // });

    let assignmentDays = assignment[i].days;

    for (const d in assignmentDays) {
      let day = assignmentDays[d];
      /// check for each item in the day, then push it into the thing

      // for each recipeAndMealPlanYieldToAdd in day, add it, except if it's a match for the field being edited
      for (const r in day) {
        // console.log("day[r]");
        // console.log(day[r]);

        // console.log("day[r].serving");
        // console.log(day[r].serving);

        // Check if at least one serving is not "" or "1" then, hide the checkbox input
        if (day[r].serving == "" || day[r].serving == 1) {
        } else {
          hideCheckboxOrNumberServingInput = true;
        }

        // if (index == d && memberId == assignmentMemberUserId) {
        // } else {
        //   updatedAssignment[i].days[d].push(day[r]);
        // }
      }

      // // if this is the field being changed, add it
      // if (index == d && memberId == assignmentMemberUserId) {
      //   // If the value is blank, don't add it since it needs to be removed. Else, add recipeAndMealPlanYieldToAdd
      //   if (value !== "") {
      //     updatedAssignment[i].days[d].push(recipeAndMealPlanYieldToAdd);
      //   }
      // }
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="#"></IonBackButton>
          </IonButtons>
          <IonTitle>
            {isServingAddPage === true ? <>Add </> : <>Update </>}{" "}
            {mealPlanServingSelection.name}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <WebWidthListContainer>
          <IonGrid style={{ width: "100%" }}>
            <IonRow>
              <IonCol size="12">
                <IonItem>
                  <IonLabel position="stacked">Week</IonLabel>
                  <IonSelect
                    interface="action-sheet"
                    name="week"
                    label="Stacked label"
                    labelPlacement="stacked"
                    levelOneKey="mealPlanServingSelection"
                    value={mealPlanServingSelection.week}
                    onIonChange={handleChangeLevelTwoEditServing}
                  >
                    <IonSelectOption
                      value={tabWeeks()[0].week.toString()}
                      disabled={false}
                    >
                      {tabWeeks()[0].date} (this week)
                    </IonSelectOption>
                    <IonSelectOption
                      value={tabWeeks()[1].week.toString()}
                      disabled={false}
                    >
                      {tabWeeks()[1].date} (next week)
                    </IonSelectOption>
                    <IonSelectOption
                      value={tabWeeks()[2].week.toString()}
                      disabled={false}
                    >
                      {tabWeeks()[2].date}
                    </IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="4">
                <FormRow
                  labelText="Total Servings"
                  type="number"
                  name="recipeServing"
                  levelOneKey="mealPlanServingSelection"
                  value={mealPlanServingSelection.recipeServing}
                  onIonChange={handleChangeLevelTwoEditServing}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <h4>Meal (optional)</h4>
                <IonSegment value={mealPlanServingSelection.meal}>
                  <IonSegmentButton
                    name="meal"
                    levelOneKey="mealPlanServingSelection"
                    value="Breakfast"
                    onClick={handleChangeServingMeal}
                    disabled={isLoading ? true : false}
                  >
                    <IonLabel>Breakfast</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    name="meal"
                    levelOneKey="mealPlanServingSelection"
                    value="Lunch"
                    onClick={handleChangeServingMeal}
                    disabled={isLoading ? true : false}
                  >
                    <IonLabel>Lunch</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    name="meal"
                    levelOneKey="mealPlanServingSelection"
                    value="Dinner"
                    onClick={handleChangeServingMeal}
                    disabled={isLoading ? true : false}
                  >
                    <IonLabel>Dinner</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    name="meal"
                    levelOneKey="mealPlanServingSelection"
                    value="Snack"
                    onClick={handleChangeServingMeal}
                    disabled={isLoading ? true : false}
                  >
                    <IonLabel>Snack</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
              </IonCol>
            </IonRow>
            <h4>Allocate Servings (optional)</h4>
            <IonChip
              // style={{ marginTop: "12px" }}
              className="width"
              color="primary"
              name="checkboxOrNumberServingInput"
              levelOneKey="mealPlanServingSelection"
              value={mealPlanServingSelection.checkboxOrNumberServingInput}
              onClick={handleChangeCheckboxOrNumberServingInput}
              disabled={
                hideCheckboxOrNumberServingInput === true ? true : false
              }
            >
              {mealPlanServingSelection.checkboxOrNumberServingInput ===
              "checkbox"
                ? "number"
                : "checkbox"}
            </IonChip>

            <IonRow>
              <IonCol size="3"></IonCol>
              {user.members.map((member, i) => {
                return (
                  <IonCol
                    style={{ textAlign: "center" }}
                    size={
                      mealPlanServingSelection.checkboxOrNumberServingInput ===
                      "checkbox"
                        ? "2"
                        : "4"
                    }
                  >
                    {member.firstName}
                  </IonCol>
                );
              })}
            </IonRow>
            {currentWeekDaysList.map((day, i) => {
              // const isEvenRow = i % 2 == 0 ? "#f4f5f8" : "white";
              const isEvenRow = i % 2 == 0 ? "white" : "white"; // maybe do alternating color rows in the future

              return (
                <IonRow
                  style={{
                    backgroundColor: isEvenRow,
                  }}
                >
                  <IonCol size="3">
                    <IonLabel>{day}</IonLabel>
                  </IonCol>

                  {assignment.map((member, m) => {
                    let servingValue = "";

                    // if the there is no recipeAndMealPlanYieldToAdd, then leave the value as blank
                    if (member.days[i].length >= 1) {
                      servingValue = member.days[i][0].serving;
                    }

                    return (
                      <>
                        {mealPlanServingSelection.checkboxOrNumberServingInput ===
                        "checkbox" ? (
                          <IonCol
                            size="2"
                            style={{ textAlign: "center", height: "40px" }}
                          >
                            <IonCheckbox
                              checked={servingValue === "" ? false : true}
                              levelOneKey="mealPlanServingSelection"
                              name="servingAllocation"
                              memberId={member.memberUserId}
                              index={i}
                              onIonChange={
                                handleChangeEditServingServingAllocationCheckbox
                              }
                            ></IonCheckbox>
                          </IonCol>
                        ) : (
                          <IonCol size="4" style={{ height: "59px" }}>
                            <IonItem>
                              <IonInput
                                type="number"
                                levelOneKey="mealPlanServingSelection"
                                name="servingAllocation"
                                memberId={member.memberUserId}
                                index={i}
                                value={servingValue}
                                onIonChange={
                                  handleChangeEditServingServingAllocationNumber
                                }
                              />
                            </IonItem>
                          </IonCol>
                        )}
                      </>
                    );
                  })}
                </IonRow>
              );
            })}
            <IonRow>
              <IonCol size="12"></IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                {isServingAddPage === true ? (
                  <IonButton
                    expand="block"
                    onClick={() => handleCreateMealPlanRecipe()}
                    disabled={isLoading ? true : false}
                  >
                    Add
                  </IonButton>
                ) : (
                  <IonButton
                    expand="block"
                    onClick={() => handleUpdateMealPlanRecipe()}
                    disabled={isLoading ? true : false}
                  >
                    Update
                  </IonButton>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
          <SpaceSoiPhoneKeyboardDoesntCoverInputs>
            {/* Adding the IonBadge is a hack. I need to add a ionic component so I can target that element and change the height */}
            <IonBadge></IonBadge>
          </SpaceSoiPhoneKeyboardDoesntCoverInputs>
        </WebWidthListContainer>
      </IonContent>
    </IonPage>
  );
};

export default EditServingPage;
