import { Redirect, Route, Switch } from "react-router-dom";
import { IonReactRouter } from "@ionic/react-router";
import { useAppContext } from "./context/appContext";
import { IonApp, setupIonicReact } from "@ionic/react";
// import { createGlobalStyle } from "styled-components";
import AppTabs from "./AppTabs";
import {
  Register,
  Login,
  Landing,
  NotFoundPage,
  EditServingPage,
} from "./pages";

setupIonicReact();

// const GlobalStyle = createGlobalStyle`
//   body {
//     background: black;
//     min-height: 100vh;
//     margin: 0;
//     color: black;
//     font-family: 'Kaushan Script'
//   }
// `;

const App = () => {
  const { user } = useAppContext();

  return (
    <>
      {/* <GlobalStyle /> */}
      <IonApp>
        <IonReactRouter>
          <Switch>
            <Route exact path="/">
              {user ? <Redirect to="/my/discover/menu" /> : <Login />}
            </Route>
            <Route exact path="/register">
              <Register />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/welcome">
              <Landing />
            </Route>
            {/* <Route exact path="/my/serving/add">
              <EditServingPage />
            </Route>
            <Route exact path="/my/serving/edit">
              <EditServingPage />
            </Route> */}
            <Route path="/my">{user ? <AppTabs /> : <Redirect to="/" />}</Route>
            <Route>
              <NotFoundPage />
            </Route>
          </Switch>
        </IonReactRouter>
      </IonApp>
    </>
  );
};

export default App;
