import { useAppContext } from "../context/appContext";
// import mongoose from "mongoose";
import { formatQuantity } from "format-quantity";

function useHandleRecipeInput() {
  const { handleChange } = useAppContext();

  const useHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({ name, value });
  };

  return { useHandleChange };
}

function useEditRecipeAfterEveryClickRecipeInput() {
  const { editRecipeAfterEveryClick, recipeShoppingItems } = useAppContext();

  const useEditRecipeAfterEveryClick = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (e.target.name === "procureShoppingItem") {
      name = "recipeShoppingItems";
      recipeShoppingItems[e.target.index][e.target.name] = e.target.checked;
      value = recipeShoppingItems;
    }

    // to do: find a way to display the serving multiplier somehow
    // if (
    //   e.target.name === "personalizedYield" &&
    //   typeof e.target.value == "string"
    // ) {
    // if (value.includes("⠀(1x)")) {
    //   value = value.replace("⠀(1x)", "");
    // }
    // }

    editRecipeAfterEveryClick({ name, value });
  };

  return { useEditRecipeAfterEveryClick };
}

function useAddShoppingItemFiled() {
  const { recipeShoppingItems, handleChange } = useAppContext();

  const addShoppingItemFiled = () => {
    let name = "recipeShoppingItems";

    const newField = {
      procureShoppingItem: false,
      quantity: "",
      unit: "",
      name: "",
    };

    recipeShoppingItems.push(newField);
    let value = recipeShoppingItems;

    handleChange({ name, value });
  };

  return { addShoppingItemFiled };
}

function useAddShoppingItemFiledByItemId() {
  const { recipeShoppingItems, handleChange } = useAppContext();

  const addShoppingItemFiledByItemId = () => {
    let name = "recipeShoppingItems";

    const newField = {
      procureShoppingItem: false,
      quantity: "",
      unit: "",
      name: "",
      item: "",
    };

    recipeShoppingItems.push(newField);
    let value = recipeShoppingItems;

    handleChange({ name, value });
  };

  return { addShoppingItemFiledByItemId };
}

function useHandleShoppingItemRecipeInput(e) {
  const { recipeShoppingItems, handleChange } = useAppContext();

  const handleShoppingItemRecipeInput = (e) => {
    let name = "recipeShoppingItems";

    if (e.target.name === "unit") {
      recipeShoppingItems[e.target.index][e.target.name] = e.target.value;
    } else if (e.target.name === "item") {
      recipeShoppingItems[e.target.i][e.target.name] = e.target.value;
    } else {
      recipeShoppingItems[e.target.index][e.target.name] = e.target.value;
    }

    let value = recipeShoppingItems;

    handleChange({ name, value });
  };
  return { handleShoppingItemRecipeInput };
}

function useHandleShoppingItemHiddenStatus(e) {
  const { recipeShoppingItems, handleChange } = useAppContext();

  const handleShoppingItemHiddenStatus = (e) => {
    let name = "recipeShoppingItems";

    if (recipeShoppingItems[e.target.index].hidden === true) {
      recipeShoppingItems[e.target.index].hidden = false;
    } else {
      recipeShoppingItems[e.target.index].hidden = true;
    }

    let value = recipeShoppingItems;

    handleChange({ name, value });
  };
  return { handleShoppingItemHiddenStatus };
}

function useRemoveShoppingItemField(e) {
  const { recipeShoppingItems, handleChange } = useAppContext();

  const removeShoppingItemField = (e) => {
    let name = "recipeShoppingItems";

    const cloneRecipeShoppingItems = Array.from(recipeShoppingItems);

    cloneRecipeShoppingItems.splice(e.target.index, 1);
    let value = cloneRecipeShoppingItems;

    handleChange({ name, value });
  };
  return { removeShoppingItemField };
}

function makePlural(unit, quantity) {
  const pluralUnits = [
    "bunch",
    "cup",
    "quart",
    "can",
    "clove",
    "dash",
    "package",
    "piece",
    "pinch",
    "slice",
    "stick",
    "leaf",
  ];

  if (pluralUnits.includes(unit) && quantity > 1) {
    if (unit === "pinch") {
      return "pinches";
    } else if (unit === "bunch") {
      return "bunches";
    } else {
      return `${unit}s`;
    }
  } else {
    return unit;
  }
}

function makeUtensilsPlural(utensil, quantity) {
  if (quantity > 1) {
    if (
      utensil === "Large Baking Dish" ||
      utensil === "Medium Baking Dish" ||
      utensil === "Small Baking Dish"
    ) {
      return `${utensil}es`;
    } else {
      return `${utensil}s`;
    }
  } else {
    return utensil;
  }
}

function calculatedQuantity(personalizedYield, recipeYield, quantity) {
  let result = formatQuantity((personalizedYield / recipeYield) * quantity);

  if (result === null) {
    result = quantity;
    return result;
  } else if (result === 0) {
    return "";
  } else {
    return result;
  }
}

function handleInstructionVariables(
  instruction,
  recipeShoppingItems,
  personalizedYield
) {
  const regExCheckForVariables = new RegExp(/{{.{1,500}}}/, "gm");

  const checkForVariablesResult = [
    ...instruction.matchAll(regExCheckForVariables),
  ];

  // check if the instruction contains variables
  if (checkForVariablesResult.length >= 1) {
    const words = instruction.split(" ");
    let newInstruction = [];

    // iterate through every word
    for (let w in words) {
      const checkIfWordIsVariable = [
        ...words[w].matchAll(regExCheckForVariables),
      ];

      // check if word is a variable
      if (checkIfWordIsVariable.length >= 1) {
        // check if the word ends with a comma, period, etc
        let variableEndingCharacter = "";
        const variableHasEndingCharacterRegEx = new RegExp(
          /(?<=}})\S{1,100}/,
          "gm"
        );
        const variableEndingCharacterResult = [
          ...words[w].matchAll(variableHasEndingCharacterRegEx),
        ];

        if (variableEndingCharacterResult.length >= 1) {
          variableEndingCharacter = variableEndingCharacterResult[0][0];
        }

        // start to find the variable id
        const wholeShoppingItemIdRegEx = new RegExp(
          /[0-9a-zA-Z]{1,200}(?=}})/,
          "gm"
        );

        const wholeShoppingItemIdRegExResult = [
          ...words[w].matchAll(wholeShoppingItemIdRegEx),
        ];

        // Found id of the variable
        const wholeShoppingItemId = wholeShoppingItemIdRegExResult[0][0];

        // prep the parts
        const parts = ["-name-", "-unit-", "-quantity-"];
        let wholeShoppingItemPart = "";

        // find the part of the variable
        for (let p in parts) {
          if (words[w].includes(parts[p]))
            wholeShoppingItemPart = parts[p].slice(1, -1);
        }

        for (let i in recipeShoppingItems) {
          // found the matching shoppingItem
          if (recipeShoppingItems[i]._id === wholeShoppingItemId) {
            let variableResult = recipeShoppingItems[i][wholeShoppingItemPart];
            let shoppingItemUnit = recipeShoppingItems[i].unit;
            let shoppingItemQuantity = recipeShoppingItems[i].quantity;
            const shoppingItemHidden = recipeShoppingItems[i].hidden;

            // check if quantity
            if (
              wholeShoppingItemPart === "quantity" ||
              wholeShoppingItemPart === "unit"
            ) {
              const shoppingItemQuantityFractionMatch = words[w].match(
                /(?<={{)[0-9||\/]{1,5}/gm
              );

              function fractionToNumber(fraction) {
                return new Function("return " + fraction)();
              }

              function truncateNumber(num, fixed) {
                const re = new RegExp(
                  "^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?"
                );
                return num.toString().match(re)[0];
              }

              if (shoppingItemQuantityFractionMatch !== null) {
                shoppingItemQuantity = truncateNumber(
                  shoppingItemQuantity *
                    personalizedYield *
                    fractionToNumber(shoppingItemQuantityFractionMatch[0]),
                  2
                );
              }

              if (wholeShoppingItemPart === "quantity") {
                variableResult = shoppingItemQuantity;
              }

              if (wholeShoppingItemPart === "unit") {
                variableResult = makePlural(
                  shoppingItemUnit,
                  shoppingItemQuantity,
                  personalizedYield
                );
              }
            }

            if (shoppingItemHidden === true) {
              newInstruction.push(
                <span style={{ textDecoration: "line-through" }}>
                  {variableResult + variableEndingCharacter}
                </span>
              );
            } else {
              newInstruction.push(
                <span>{variableResult + variableEndingCharacter}</span>
              );
            }

            newInstruction.push(" ");
          }
        }
      } else {
        newInstruction.push(words[w]);
        newInstruction.push(" ");
      }
    }

    return newInstruction;
  } else {
    // no variables exist
    return instruction;
  }
}

function useHandleShoppingItemInput(e) {
  const { shoppingItem, handleChange } = useAppContext();

  const handleShoppingItemInput = (e) => {
    let name = "shoppingItem";

    if (e.target.name === "item") {
      shoppingItem[e.target.i][e.target.name] = e.target.value;
    } else {
      shoppingItem[e.target.name] = e.target.value;
    }

    let value = shoppingItem;

    handleChange({ name, value });
  };
  return { handleShoppingItemInput };
}

/////////////////////// MEAL PLAN ///////////////////////

// generateEmptySchedule for BE and FE
function generateEmptySchedule(members) {
  let emptySchedule = [];

  for (let i in members) {
    const emptyMemberSchedule = {
      memberUserId: members[i]._id,
      days: [[], [], [], [], [], [], []],
    };

    emptySchedule.push(emptyMemberSchedule);
  }

  return emptySchedule;
}

// Iterate through each assignment to make sure the serving allocation is not greater than the Serving
function checkIfServingAllocationIsGreaterThanRecipeServing(
  assignment,
  recipeServing
) {
  let totalServingsAllocated = 0;

  for (const i in assignment) {
    const assignmentDays = assignment[i].days;

    for (const d in assignmentDays) {
      let day = assignmentDays[d];

      // if length is not 0, that means there is a recipeAndMealPlanYieldToAdd
      if (day.length !== 0) {
        let recipeAndMealPlanYieldToAdd = day[0];

        totalServingsAllocated =
          totalServingsAllocated + Number(recipeAndMealPlanYieldToAdd.serving);
      }
    }
  }

  if (totalServingsAllocated > recipeServing) {
    // return true;
    return {
      result: "isGreater",
      totalServingsAllocated: totalServingsAllocated,
    };
  } else {
    return { result: "" };
  }
}

// function to remove the recipe to the schedule
function removeAllPopulatedRecipefromSchedule(schedule, recipeId) {
  // iterate though each member
  for (let member in schedule) {
    let memberScheduleSevenDays = schedule[member].days;

    for (let dayNumber in memberScheduleSevenDays) {
      let recipesForADay = memberScheduleSevenDays[dayNumber];

      for (let recipeIndex in recipesForADay) {
        let scheduleRecipe = recipesForADay[recipeIndex];

        if (scheduleRecipe.recipe._id == recipeId) {
          // if it the recipe that needs to be removed is a match, then remove it via splice
          recipesForADay.splice(recipeIndex, recipeIndex + 1);
        }
      }
    }
  }
}

export {
  useHandleRecipeInput,
  useEditRecipeAfterEveryClickRecipeInput,
  useAddShoppingItemFiled,
  useAddShoppingItemFiledByItemId,
  useHandleShoppingItemRecipeInput,
  useHandleShoppingItemHiddenStatus,
  useRemoveShoppingItemField,
  makePlural,
  makeUtensilsPlural,
  handleInstructionVariables,
  useHandleShoppingItemInput,
  calculatedQuantity,
  generateEmptySchedule,
  checkIfServingAllocationIsGreaterThanRecipeServing,
  removeAllPopulatedRecipefromSchedule,
};
