import { useState, useEffect, useRef } from "react";
import { useAppContext } from "../context/appContext";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonButton,
  IonList,
  IonRouterLink,
  IonLabel,
  IonInput,
  IonCard,
  IonItem,
  IonImg,
} from "@ionic/react";

import { FormRow, ChipAlert } from "../components";

const initialState = {
  password: null,
  email: localStorage.getItem("e"),
};

const WebWidthListContainer = styled.div`
  margin: auto;
  max-width: 750px;
`;
const IonListStyled = styled(IonList)`
  ion-item::part(native) {
    padding-left: 0;
  }

  .native-input {
    padding-left: 8px;
  }
`;

function loadImage() {
  alert("Image is loaded");
}
const Login = () => {
  const history = useHistory();
  const [values, setValues] = useState(initialState);
  const { user, isLoading, showAlert, displayAlert, loginUser } =
    useAppContext();

  const handleChange = (e) => {
    // console.log("handleChange:");
    // console.log([e.target.name], e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    // console.log("e");
    // console.log(e);

    // console.log("onSubmit:");
    // console.log([e.target.name], e.target.value);

    setValues({ ...values, [e.target.name]: e.target.value });

    const { email, password } = values;

    // console.log("email:");
    // console.log(email);
    // console.log("password:");
    // console.log(password);

    if (!email || !password) {
      displayAlert();
      return;
    }

    const currentUser = { email, password };

    loginUser(currentUser);
  };

  useEffect(() => {
    // console.log("setTimeout(() =>  history.push()");

    if (user) {
      setTimeout(() => {
        history.push("/");
      }, 1000);
    }
  }, [user, history]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          {/* <IonTitle>Zestfully</IonTitle> */}
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {/* <IonLabel position="stacked">Email</IonLabel>
        <input
          autocomplete="username"
          email="email"
          value={values.email}
          name="email"
          onChange={handleChange}
        />
        <IonLabel position="stacked">Password</IonLabel>
        <input
          type="password"
          value={values.password}
          name="password"
          onChange={handleChange}
        /> */}

        <WebWidthListContainer>
          <div class="background-image">
            {/* <div className="container"> */}
            {/* <h1 style={{ fontWeight: "700" }}>Zestfully</h1> */}

            <IonImg
              style={{
                width: "250px",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "50px",
              }}
              src="https://my-recipa-app-images.s3.us-west-1.amazonaws.com/app-assets/zestfully-logo.jpg"
            ></IonImg>

            <h6
              style={{
                textAlign: "center",
                color: "gray",
                fontStyle: "italic",
              }}
            >
              Save time and money with delicous personalized recipes that help
              you achieve your health goals.
            </h6>
            <h1 style={{ marginTop: "50px" }} className="ion-text-center">
              Log In
            </h1>
            {/* </div> */}
          </div>
          <IonListStyled>
            {/* Start Original  */}
            {/* email input */}
            {/* <FormRow
              labelText="Email"
              type="email"
              name="email"
              value={values.email}
              onIonChange={handleChange}
            /> */}
            {/* password input */}
            {/* <FormRow
                labelText="Password"
                type="password"
                name="password"
                value={values.password}
                onIonChange={handleChange}
              /> */}
            {/* End Original  */}

            {/* Start Test 2  */}
            <IonLabel position="stacked">Email</IonLabel>
            <IonInput
              id="emailId"
              onload="loadImage()"
              type="email"
              value={values.email}
              name="email"
              onIonChange={handleChange}
            />
            <IonLabel position="stacked">Password</IonLabel>
            <IonInput
              type="password"
              value={values.password}
              name="password"
              onIonChange={handleChange}
            />

            {/* End Test 2  */}

            {showAlert && <ChipAlert />}
            <IonButton
              expand="block"
              class="ion-margin-top"
              onClick={onSubmit}
              // disabled={isLoading}
            >
              Login
            </IonButton>
          </IonListStyled>

          <p className="ion-text-center">
            Not a member yet?
            <IonRouterLink routerLink="/register"> Sign up</IonRouterLink>
          </p>
        </WebWidthListContainer>
      </IonContent>
    </IonPage>
  );
};

export default Login;
