import { useAppContext } from "../context/appContext";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonImg,
  IonRouterLink,
} from "@ionic/react";
import { Header } from "../components";

import { tabWeeks } from "../utils/FormattingUtils";

import RecipeCard from "../components/RecipeCard.js";

const WebWidthCardContainer = styled.div`
  margin: auto;

  @media (min-width: 770px) {
    max-width: 740px;
  }

  @media (min-width: 1142px) {
    max-width: 1115px;
  }

  @media (min-width: 1512px) {
    max-width: 1485px;
  }

  @media (min-width: 1900px) {
    max-width: 1850px;
  }
`;

const CardWidthContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Menu = () => {
  const {
    getMenu,
    menuRecipes,
    isLoading,
    week,
    handleChange,
    discoverSelectedPage,
  } = useAppContext();

  const history = useHistory();

  // useIonViewDidEnter(() => {
  //   getMenu();
  // });

  useEffect(() => {
    getMenu();
    // eslint-disable-next-line
  }, [week]);

  const updateWeek = (e) => {
    handleChange({ name: "week", value: e.target.value });
  };

  const updateDiscoverSelectedPage = (e) => {
    handleChange({
      name: "discoverSelectedPage",
      value: e.target.value,
    });

    history.push(e.target.value);
  };

  let menuRecipesChosenInWeeklyMenuCount = 0;
  let remainingNotSelectedMenuRecipesCount = 0;

  for (const recipe in menuRecipes) {
    if (menuRecipes[recipe].recipeChosenInWeeklyMenu === true) {
      menuRecipesChosenInWeeklyMenuCount =
        menuRecipesChosenInWeeklyMenuCount + 1;
    } else {
      remainingNotSelectedMenuRecipesCount =
        remainingNotSelectedMenuRecipesCount + 1;
    }
  }

  return (
    <IonPage>
      <Header />
      {/* <IonHeader>
        <IonToolbar>
          <IonSegment value={discoverSelectedPage}>
            <IonSegmentButton
              value="/my/discover/menu"
              onClick={updateDiscoverSelectedPage}
              disabled={isLoading ? true : false}
            >
              Curated for You
            </IonSegmentButton>
            <IonSegmentButton
              value="/my/discover/search"
              onClick={updateDiscoverSelectedPage}
              disabled={isLoading ? true : false}
            >
              Search
            </IonSegmentButton>
            <IonSegmentButton
              // value="/my/discover/search"
              onClick={updateDiscoverSelectedPage}
              disabled={isLoading ? true : false}
            >
              Your Creations
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader> */}
      <IonContent className="ion-padding">
        <WebWidthCardContainer>
          <div>
            <h1>Your Curated Feed</h1>
            {/* {recipes.length === 0 ? "" : <h1>Recipes Curated For You</h1>} */}
            <IonSegment value={week}>
              <IonSegmentButton
                value={tabWeeks()[0].week}
                onClick={updateWeek}
                disabled={isLoading ? true : false}
              >
                {tabWeeks()[0].date}
              </IonSegmentButton>
              <IonSegmentButton
                value={tabWeeks()[1].week}
                onClick={updateWeek}
                disabled={isLoading ? true : false}
              >
                {tabWeeks()[1].date}
              </IonSegmentButton>
              <IonSegmentButton
                value={tabWeeks()[2].week}
                onClick={updateWeek}
                disabled={isLoading ? true : false}
              >
                {tabWeeks()[2].date}
              </IonSegmentButton>
            </IonSegment>
            {menuRecipes.length === 0 ? (
              <div>
                <IonImg
                  crossorigin="anonymous"
                  style={{
                    height: "110px",
                    margin: "auto",
                    marginTop: "100px",
                  }}
                  src="https://my-recipa-app-images.s3.us-west-1.amazonaws.com/app-assets/recipe-book.svg"
                  alt="recipe book"
                ></IonImg>
                <h3 style={{ textAlign: "center", color: "#808080" }}>
                  Menu Not Ready
                </h3>
                <h5
                  style={{
                    textAlign: "center",
                    color: "#808080",
                    marginTop: "0",
                  }}
                >
                  You're tasty menu is coming soon!
                </h5>
              </div>
            ) : (
              ""
            )}
            {/* NO LONGER NEEDED  */}
            {/* {recipes.length === 0 ? "" : <h1>Your Weekly Recipes</h1>} */}
            {/* <CardWidthContainer>
              {recipes.map((recipe) => {
                if (recipe.recipeChosenInWeeklyMenu === true) {
                  return <RecipeCard key={recipe._id} {...recipe} />;
                }
              })}
            </CardWidthContainer> */}
            {/* {menuRecipesChosenInWeeklyMenuCount === 0 && recipes.length != 0 ? (
              <div>
                <IonImg
                  crossorigin="anonymous"
                  style={{
                    width: "100px",
                    margin: "auto",
                    marginTop: "50px",
                  }}
                  src="https://my-recipa-app-images.s3.us-west-1.amazonaws.com/app-assets/empty-box.svg"
                  alt="empty box"
                ></IonImg>
                <h3 style={{ textAlign: "center", color: "#808080" }}>
                  No Recipes Selected
                </h3>
                <h5
                  style={{
                    textAlign: "center",
                    color: "#808080",
                    marginTop: "0",
                    marginBottom: "50px",
                  }}
                >
                  Select your weekly recipes. Leave empty if you want to skip
                  this week.
                </h5>
              </div>
            ) : null} */}

            <CardWidthContainer>
              {menuRecipes.map((recipe) => {
                if (recipe.recipeChosenInWeeklyMenu === false) {
                  return <RecipeCard key={recipe._id} {...recipe} />;
                }
              })}
            </CardWidthContainer>
            {remainingNotSelectedMenuRecipesCount === 0 &&
            menuRecipes.length != 0 ? (
              <div>
                <IonImg
                  crossorigin="anonymous"
                  style={{ height: "110px", margin: "auto", marginTop: "50px" }}
                  src="https://my-recipa-app-images.s3.us-west-1.amazonaws.com/app-assets/recipe-book.svg"
                  alt="recipe book"
                ></IonImg>
                <h3 style={{ textAlign: "center", color: "#808080" }}>
                  All available recipes added.
                </h3>
                <h5
                  style={{
                    textAlign: "center",
                    color: "#808080",
                    marginTop: "0",
                    marginBottom: "50px",
                  }}
                >
                  You're added all the available recipes to your menu. Are you
                  sure you want to do that?
                </h5>
              </div>
            ) : null}
          </div>
        </WebWidthCardContainer>
      </IonContent>
    </IonPage>
  );
};

export default Menu;
