import "./ExploreContainer.css";
import styled from "styled-components";

import { IonButton, IonRouterLink } from "@ionic/react";
// import useIonToast from "@ionic/react";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

// interface ContainerProps {}

// Sample code: Styled components
const Wrapper = styled.div`
  /*
  color: red;

  p {
    color: red;
  }

  IonButton {
    color: red;
    width: 100px;
  }
  */
`;
const ExploreContainer = () => {
  // const [presentToast] = useIonToast();
  // Sample lesson code - Toast
  // const handleClick = () => {
  //   presentToast({
  //     message: "Hello World!",
  //     duration: 1500,
  //   });
  // };

  return (
    <Wrapper>
      <div className="container">
        <strong>
          Save time and money with delivious personalized recipes that help you
          achive your health goals.
        </strong>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <div className="ion-padding">
          <IonRouterLink routerLink="/register">
            <IonButton color="primary">Sign Up or Login</IonButton>
          </IonRouterLink>
        </div>
      </div>
    </Wrapper>
  );
};

// Lesson code - YouTube styled components
// return (
//   <div className="container">
//     <strong>
//       Save time and money with delivious personalized recipes that help you
//       achive your health goals.
//     </strong>
//     <p>
//       Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
//       tempor incididunt ut labore et dolore magna aliqua.
//     </p>
//     <div className="ion-padding">
//       <IonButton color="primary">Sign Up</IonButton>
//       <IonButton color="primary">Log in</IonButton>
//       {/* <IonButton color="warning" onClick={handleClick}>
//         <IonIcon icon={playIcon} slot="start" />
//         Toast Test
//       </IonButton> */}
//     </div>
//   </div>
// );
// };

export default ExploreContainer;
