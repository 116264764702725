import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
} from "@ionic/react";

import { StyledButton } from "../components/styled-component-course";

const StyledComponentesCourse = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>StyledComponentesCourse</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <p>StyledComponentesCourse</p>
        <StyledButton>primary</StyledButton>
        <StyledButton secondary>secondary</StyledButton>
        <StyledButton large>secondary</StyledButton>
      </IonContent>
    </IonPage>
  );
};

export default StyledComponentesCourse;
