export const DISPLAY_ALERT = "SHOW_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";

export const BEGIN_REDIRECT = "BEGIN_REDIRECT";
export const END_REDIRECT = "END_REDIRECT";

export const GENERIC_ERROR = "GENERIC_ERROR";

export const REGISTER_USER_BEGIN = "REGISTER_USER_BEGIN";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";

export const LOGIN_USER_BEGIN = "LOGIN_USER_BEGIN";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const LOGOUT_USER = "LOGOUT_USER";

export const UPDATE_USER_BEGIN = "UPDATE_USER_BEGIN";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const HANDLE_CHANGE_MEMBER_INFO = "HANDLE_CHANGE_MEMBER_INFO";

export const HANDLE_CHANGE = "HANDLE_CHANGE";

export const HANDLE_CHANGE_LEVEL_TWO = "HANDLE_CHANGE_LEVEL_TWO";

export const CLEAR_VALUES = "CLEAR_VALUES";

// Add Recipe Page
export const CREATE_RECIPE_BEGIN = "CREATE_RECIPE_BEGIN";
export const CREATE_RECIPE_SUCCESS = "CREATE_RECIPE_SUCCESS";
export const CREATE_RECIPE_ERROR = "CREATE_RECIPE_ERROR";

export const CLEAR_RECIPE_VALUES = "CLEAR_RECIPE_VALUES";

export const REMOVE_SHOPPING_ITEM_BEGIN_RECIPE_PAGE =
  "REMOVE_SHOPPING_ITEM_BEGIN_RECIPE_PAGE";

export const HANDLE_CHANGE_SHOPPING_ITEM_RECIPE_PAGE =
  "HANDLE_CHANGE_SHOPPING_ITEM_RECIPE_PAGE";

export const ADD_INSTRUCTION_STEP = "ADD_INSTRUCTION_STEP";

export const REMOVE_INSTRUCTION_STEP = "REMOVE_INSTRUCTION_STEP";

export const HANDLE_CHANGE_INSTRUCTION_CHANGE_ADD_RECIPE =
  "HANDLE_CHANGE_INSTRUCTION_CHANGE_ADD_RECIPE";

export const ADD_UTENSIL = "ADD_UTENSIL";

export const REMOVE_UTENSIL = "REMOVE_UTENSIL";

export const HANDLE_CHANGE_UTENSIL_CHANGE_ADD_RECIPE =
  "HANDLE_CHANGE_UTENSIL_CHANGE_ADD_RECIPE";

// Multi Page - Menu Page, View Recipe
export const GET_RECIPE_BEGIN = "GET_RECIPE_BEGIN";
export const GET_RECIPE_SUCCESS = "GET_RECIPE_SUCCESS";

export const SET_EDIT_RECIPE = "SET_EDIT_RECIPE";

export const DELETE_RECIPE_BEGIN = "DELETE_RECIPE_BEGIN";
export const DELETE_RECIPE_SUCCESS = "DELETE_RECIPE_SUCCESS";

export const EDIT_RECIPE_BEGIN = "EDIT_RECIPE_BEGIN";
export const EDIT_RECIPE_SUCCESS = "EDIT_RECIPE_SUCCESS";
export const EDIT_RECIPE_ERROR = "EDIT_RECIPE_ERROR";

// Menu Pages
export const GET_MENU_BEGIN = "GET_MENU_BEGIN";
export const GET_MENU_SUCCESS = "GET_MENU_SUCCESS";

// Search Page
export const GET_SEARCH_PAGE_RECIPES_BEGIN = "GET_SEARCH_PAGE_RECIPES_BEGIN";
export const GET_SEARCH_PAGE_RECIPES_SUCCESS =
  "GET_SEARCH_PAGE_RECIPES_SUCCESS";

export const SEARCH_CLEAR_FILTERS = "SEARCH_CLEAR_FILTERS";

// Collection Page
export const GET_COLLECTION_PAGE_RECIPES_BEGIN =
  "GET_COLLECTION_PAGE_RECIPES_BEGIN";
export const GET_COLLECTION_PAGE_RECIPES_SUCCESS =
  "GET_COLLECTION_PAGE_RECIPES_SUCCESS";

export const COLLECTION_CLEAR_FILTERS = "COLLECTION_CLEAR_FILTERS";

export const CREATE_RECIPE_IN_COLLECTION_PAGE_BEGIN =
  "CREATE_RECIPE_IN_COLLECTION_PAGE_BEGIN";
export const CREATE_RECIPE_IN_COLLECTION_PAGE_SUCCESS =
  "CREATE_RECIPE_IN_COLLECTION_PAGE_SUCCESS";
export const CREATE_RECIPE_IN_COLLECTION_PAGE_ERROR =
  "CREATE_RECIPE_IN_COLLECTION_PAGE_ERROR";

export const EDIT_RECIPE_COLLECTION_PAGE_BEGIN =
  "EDIT_RECIPE_COLLECTION_PAGE_BEGIN";
export const EDIT_RECIPE_COLLECTION_PAGE_SUCCESS =
  "EDIT_RECIPE_COLLECTION_PAGE_SUCCESS";
export const EDIT_RECIPE_COLLECTION_PAGE_ERROR =
  "EDIT_RECIPE_COLLECTION_PAGE_ERROR";

// Set Edit Serving Recipe
export const SET_EDIT_SERVING_ADD_RECIPE = "SET_EDIT_SERVING_ADD_RECIPE";

export const SET_EDIT_SERVING_UPDATE_RECIPE = "SET_EDIT_SERVING_UPDATE_RECIPE";

export const HANDLE_CHANGE_EDIT_SERVINGS_PAGE_MEAL =
  "HANDLE_CHANGE_EDIT_SERVINGS_PAGE_MEAL";

export const HANDLE_CHANGE_EDIT_SERVINGS_PAGE_SERVING_ALLOCATION =
  "HANDLE_CHANGE_EDIT_SERVINGS_PAGE_SERVING_ALLOCATION";

// Meal Plan Pages
export const GET_MEAL_PLAN_BEGIN = "GET_MEAL_PLAN_BEGIN";
export const GET_MEAL_PLAN_SUCCESS = "GET_MEAL_PLAN_SUCCESS";

export const CREATE_MEAL_PLAN_RECIPE_BEGIN = "CREATE_MEAL_PLAN_RECIPE_BEGIN";
export const CREATE_MEAL_PLAN_RECIPE_SUCCESS =
  "CREATE_MEAL_PLAN_RECIPE_SUCCESS";
export const CREATE_MEAL_PLAN_RECIPE_ERROR = "CREATE_MEAL_PLAN_RECIPE_ERROR";

export const REMOVE_MEAL_PLAN_RECIPE_BEGIN = "REMOVE_MEAL_PLAN_RECIPE_BEGIN";
export const REMOVE_MEAL_PLAN_RECIPE_SUCCESS =
  "REMOVE_MEAL_PLAN_RECIPE_SUCCESS";
export const REMOVE_MEAL_PLAN_RECIPE_ERROR = "REMOVE_MEAL_PLAN_RECIPE_ERROR";

export const UPDATE_MEAL_PLAN_RECIPE_BEGIN = "UPDATE_MEAL_PLAN_RECIPE_BEGIN";
export const UPDATE_MEAL_PLAN_RECIPE_SUCCESS =
  "UPDATE_MEAL_PLAN_RECIPE_SUCCESS";
export const UPDATE_MEAL_PLAN_RECIPE_ERROR = "UPDATE_MEAL_PLAN_RECIPE_ERROR";

export const UPDATE_MACROS_OR_SUMMARY_VIEW = "UPDATE_MACROS_OR_SUMMARY_VIEW";

export const CREATE_RECIPE_IN_MEAL_PLAN_AND_SET_SERVING_EDIT_PAGE_BEGIN =
  "CREATE_RECIPE_IN_MEAL_PLAN_AND_SET_SERVING_EDIT_PAGE_BEGIN";
export const CREATE_RECIPE_IN_MEAL_PLAN_AND_SET_SERVING_EDIT_PAGE_SUCCESS =
  "CREATE_RECIPE_IN_MEAL_PLAN_AND_SET_SERVING_EDIT_PAGE_SUCCESS";
export const CREATE_RECIPE_IN_MEAL_PLAN_AND_SET_SERVING_EDIT_PAGE_ERROR =
  "CREATE_RECIPE_IN_MEAL_PLAN_AND_SET_SERVING_EDIT_PAGE_ERROR";

export const EDIT_RECIPE_MEAL_PLAN_PAGE_BEGIN =
  "EDIT_RECIPE_MEAL_PLAN_PAGE_BEGIN";
export const EDIT_RECIPE_MEAL_PLAN_PAGE_SUCCESS =
  "EDIT_RECIPE_MEAL_PLAN_PAGE_SUCCESS";
export const EDIT_RECIPE_MEAL_PLAN_PAGE_ERROR =
  "EDIT_RECIPE_MEAL_PLAN_PAGE_ERROR";

// SHOPPING LIST PAGE

export const GET_SHOPPING_BEGIN = "GET_SHOPPING_BEGIN";
export const GET_SHOPPING_SUCCESS = "GET_SHOPPING_SUCCESS";

export const UPDATE_SHOPPING_LIST_GROUP_CATEGORY =
  "UPDATE_SHOPPING_LIST_GROUP_CATEGORY";

export const UPDATE_SHOPPING_LIST_HIDE_CHECKED =
  "UPDATE_SHOPPING_LIST_HIDE_CHECKED";

export const CREATE_SHOPPING_ITEM_BEGIN = "CREATE_SHOPPING_ITEM_BEGIN";
export const CREATE_SHOPPING_ITEM_SUCCESS = "CREATE_SHOPPING_ITEM_SUCCESS";
export const CREATE_SHOPPING_ITEM_ERROR = "CREATE_SHOPPING_ITEM_ERROR";

export const CLEAR_SHOPPING_ITEM = "CLEAR_SHOPPING_ITEM";

export const SET_EDIT_SHOPPING_ITEM = "SET_EDIT_SHOPPING_ITEM";

export const CLEAR_SHOPPING_ITEM_VALUES = "CLEAR_SHOPPING_ITEM_VALUES";

export const EDIT_SHOPPING_LIST_SHOPPING_ITEM_BEGIN =
  "EDIT_SHOPPING_LIST_SHOPPING_ITEM_BEGIN";
export const EDIT_SHOPPING_LIST_SHOPPING_ITEM_SUCCESS =
  "EDIT_SHOPPING_LIST_SHOPPING_ITEM_SUCCESS";
export const EDIT_SHOPPING_LIST_SHOPPING_ITEM_ERROR =
  "EDIT_SHOPPING_LIST_SHOPPING_ITEM_ERROR";

export const EDIT_RECIPE_SHOPPING_ITEM_BEGIN =
  "EDIT_RECIPE_SHOPPING_ITEM_BEGIN";
export const EDIT_RECIPE_SHOPPING_ITEM_SUCCESS =
  "EDIT_RECIPE_SHOPPING_ITEM_SUCCESS";
export const EDIT_RECIPE_SHOPPING_ITEM_ERROR =
  "EDIT_RECIPE_SHOPPING_ITEM_ERROR";

export const REMOVE_SHOPPING_LIST_SHOPPING_ITEM_BEGIN =
  "REMOVE_SHOPPING_LIST_SHOPPING_ITEM_BEGIN";
export const REMOVE_SHOPPING_LIST_SHOPPING_ITEM_SUCCESS =
  "REMOVE_SHOPPING_LIST_SHOPPING_ITEM_SUCCESS";
export const REMOVE_SHOPPING_LIST_SHOPPING_ITEM_ERROR =
  "REMOVE_SHOPPING_LIST_SHOPPING_ITEM_ERROR";

export const REMOVE_RECIPE_SHOPPING_ITEM_BEGIN =
  "REMOVE_RECIPE_SHOPPING_ITEM_BEGIN";
export const REMOVE_RECIPE_SHOPPING_ITEM_SUCCESS =
  "REMOVE_RECIPE_SHOPPING_ITEM_SUCCESS";
export const REMOVE_RECIPE_SHOPPING_ITEM_ERROR =
  "REMOVE_RECIPE_SHOPPING_ITEM_ERROR";

export const UPDATE_SHOPPING_ITEM_RECIPE_QUANTITY =
  "UPDATE_SHOPPING_ITEM_RECIPE_QUANTITY";

export const CHECK_BOX_SHOPPING_ITEM_BEGIN = "CHECK_BOX_SHOPPING_ITEM_BEGIN";
export const CHECK_BOX_SHOPPING_ITEM_SUCCESS =
  "CHECK_BOX_SHOPPING_ITEM_SUCCESS";
export const CHECK_BOX_SHOPPING_ITEM_ERROR = "CHECK_BOX_SHOPPING_ITEM_ERROR";

// VIEW RECIPE PAGE

export const SET_EDIT_SHOPPING_ITEM_VIEW_RECIPE_PAGE =
  "SET_EDIT_SHOPPING_ITEM_VIEW_RECIPE_PAGE";

export const EDIT_RECIPE_SHOPPING_ITEM_BEGIN_VIEW_RECIPE_PAGE =
  "EDIT_RECIPE_SHOPPING_ITEM_BEGIN";
export const EDIT_RECIPE_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE =
  "EDIT_RECIPE_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE";
export const EDIT_RECIPE_SHOPPING_ITEM_ERROR_VIEW_RECIPE_PAGE =
  "EDIT_RECIPE_SHOPPING_ITEM_ERROR";

export const ADD_RECIPE_SHOPPING_ITEM_BEGIN_VIEW_RECIPE_PAGE =
  "ADD_RECIPE_SHOPPING_ITEM_BEGIN";
export const ADD_RECIPE_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE =
  "ADD_RECIPE_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE";
export const ADD_RECIPE_SHOPPING_ITEM_ERROR_VIEW_RECIPE_PAGE =
  "ADD_RECIPE_SHOPPING_ITEM_ERROR";

export const REMOVE_RECIPE_SHOPPING_ITEM_BEGIN_VIEW_RECIPE_PAGE =
  "REMOVE_RECIPE_SHOPPING_ITEM_BEGIN_VIEW_RECIPE_PAGE";
export const REMOVE_RECIPE_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE =
  "REMOVE_RECIPE_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE";
export const REMOVE_RECIPE_SHOPPING_ITEM_ERROR_VIEW_RECIPE_PAGE =
  "REMOVE_RECIPE_SHOPPING_ITEM_ERROR_VIEW_RECIPE_PAGE";

export const UPDATE_SHOPPING_ITEM_RECIPE_QUANTITY_SUCCESS =
  "UPDATE_SHOPPING_ITEM_RECIPE_QUANTITY_SUCCESS";
export const UPDATE_SHOPPING_ITEM_RECIPE_QUANTITY_ERROR =
  "UPDATE_SHOPPING_ITEM_RECIPE_QUANTITY_ERROR";

export const CHECK_BOX_SHOPPING_ITEM_BEGIN_VIEW_RECIPE_PAGE =
  "CHECK_BOX_SHOPPING_ITEM_BEGIN_VIEW_RECIPE_PAGE";
export const CHECK_BOX_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE =
  "CHECK_BOX_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE";
export const CHECK_BOX_SHOPPING_ITEM_ERROR_VIEW_RECIPE_PAGE =
  "CHECK_BOX_SHOPPING_ITEM_ERROR_VIEW_RECIPE_PAGE";
