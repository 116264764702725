import { useState, useEffect } from "react";
import { useAppContext } from "../context/appContext";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonButton,
  IonList,
  IonRouterLink,
  useIonToast,
} from "@ionic/react";
import { FormRow, ChipAlert } from "../components";

const initialState = {
  name: "",
  email: "",
  password: "",
  phoneNumber: "",
};

const WebWidthListContainer = styled.div`
  margin: auto;
  max-width: 750px;
`;

const IonListStyled = styled(IonList)`
  ion-item::part(native) {
    padding-left: 0;
  }

  .native-input {
    padding-left: 8px;
  }
`;

const Register = () => {
  const history = useHistory();
  const [values, setValues] = useState(initialState);
  const {
    user,
    isLoading,
    showAlert,
    alertColor,
    alertText,
    displayAlert,
    registerUser,
  } = useAppContext();

  const [present] = useIonToast();

  const presentToast = () => {
    present({
      message: alertText,
      duration: 2000,
      position: "top",
      color: alertColor,
    });
  };

  if (showAlert) {
    presentToast();
  }

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { firstName, email, password, phoneNumber } = values;

    if (!email || !password || !phoneNumber) {
      displayAlert();
      return;
    }

    const currentUser = { firstName, email, password, phoneNumber };

    registerUser(currentUser);
  };

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        history.push("/");
      }, 2000);
    }
  }, [user, history]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Zestfully</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <WebWidthListContainer>
          <h1 style={{ marginTop: "50px" }} className="ion-text-center">
            Sign Up
          </h1>
          <IonListStyled>
            {/* name input */}
            <FormRow
              labelText="First Name"
              type="text"
              name="firstName"
              value={values.firstName}
              onIonChange={handleChange}
            />
            {/* email input */}
            <FormRow
              labelText="Email"
              type="off"
              name="email"
              value={values.email}
              onIonChange={handleChange}
            />
            {/* password input */}
            <FormRow
              labelText="Password"
              type="password"
              name="password"
              value={values.password}
              onIonChange={handleChange}
            />
            {/* phoneNumber input */}
            {/* labelText="Phone" */}
            <FormRow
              labelText="Phone"
              name="phoneNumber"
              type="off"
              value={values.phoneNumber}
              onIonChange={handleChange}
            />
            <IonButton
              expand="block"
              class="ion-margin-top"
              onClick={onSubmit}
              disabled={isLoading}
            >
              Sign Up
            </IonButton>
          </IonListStyled>
          <p className="ion-text-center">
            Already a member?
            <IonRouterLink routerLink="/login"> Log in</IonRouterLink>
          </p>
        </WebWidthListContainer>
      </IonContent>
    </IonPage>
  );
};

export default Register;
