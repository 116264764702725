/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-comment-textnodes */
import styled from "styled-components";
import { useEffect, useState } from "react";
import moment from "moment";
import { useLocation, useHistory } from "react-router-dom";
import { FormRowSelect } from "../components";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonRouterLink,
  IonButton,
  IonChip,
  IonActionSheet,
} from "@ionic/react";

import { useAppContext } from "../context/appContext";
import { formatTimeAbridge } from "../utils/FormattingUtils";
import { recipeYieldList } from "../utils/Constants.js";

const RedText = styled.div`
  // that's the standard variable color. I added it since I made that a link so it become blue
  color: var(
    --ion-card-color,
    var(--ion-item-color, var(--ion-color-step-550, #737373))
  );
`;

const IonCardStyled = styled(IonCard)`
  @media (min-width: 500px) {
    width: 350px;
  }
`;

const CardActionsWrapperStyled = styled.div`
  ion-item:nth-child(2).md {
    color: #3880ff;
    display: inline-block;
    width: 145px;
    margin-bottom: -25px;
  }

  .md ion-label {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    color: #3880ff;
  }

  ion-item.md::part(native) {
    border-bottom: 0;
  }

  ion-item:nth-child(2).ios {
    color: #3880ff;
    display: inline-block;
    width: 150px;
    margin-bottom: -22px;
  }

  .ios ion-label {
    font-size: 17px;
    font-weight: 400;
    color: #3880ff;
  }
  /* bug: I can't figure out how to remove the underline from the dropdown in iOS  */
  ion-item.ios::part(native) {
    .item-inner {
      border-bottom: 0;
    }
  }
`;

const IonCardHeaderStyled = styled(IonCardHeader)`
  ion-card-title.ios {
    margin-bottom: 4px;
  }
  ion-card-subtitle.ios {
    margin-bottom: 0;
  }
`;

const ImgStyled = styled.img`
  object-fit: cover;

  @media (min-width: 300px) {
    width: 329px;
    height: 219px;
  }

  @media (min-width: 350px) {
    width: 374px;
    height: 231px;
  }

  @media (min-width: 400px) {
    width: 380px;
    height: 252px;
  }

  @media (min-width: 450px) {
    width: 430px;
    height: 285px;
  }

  @media (min-width: 500px) {
    width: 350px;
    height: 235px;
  }
`;

const RecipeCard = ({
  _id,
  name,
  featuredMedia,
  shoppingItems,
  createdAt,
  totalTime,
  cookTime,
  calories,
  protein,
  personalizedYield,
  MealType,
  rating,
  reviewCount,
  recipeChosenInWeeklyMenu,
}) => {
  const {
    setEditServingAddRecipe,
    setEditServingUpdateServing,
    deleteRecipe,
    setRecipeEditMenu,
    menuNutrition,
    getRecipe,
    removeMealPlanRecipe,
    recipeShoppingItems,
    user,
    recipeRating,
    handleChange,
    handleChangeLevelTwo,
  } = useAppContext();

  const history = useHistory();

  let date = moment(createdAt);
  date = date.format("MMM Do, YYYY");

  const imageStyle = {
    maxHeight: "500px",
  };

  const location = useLocation();
  const isMenuPage = location.pathname.includes("/menu");
  const isSearchPage = location.pathname.includes("/search");
  const isMealPlanPage = location.pathname.includes("/meal-plan");
  const isCollectionPage = location.pathname.includes("/collection");

  const [open, setOpen] = useState(false);

  const handleChangeLevelTwoEditServing = (e) => {
    console.log("value: e.target.value");
    console.log(e.target.value);

    handleChangeLevelTwo({
      name: e.target.name,
      value: e.target.value,
      levelOneKey: e.target.levelOneKey,
    });
  };

  const handleSetEditServingAddRecipe = (id, personalizedYield, name) => {
    setEditServingAddRecipe(id, personalizedYield, name);
    history.push("/my/serving/add");
  };

  const shoppingItemListLength = shoppingItems.length - 1;

  const shoppingItemList = shoppingItems.map((shoppingItem, index) => {
    // // if only one shoppingItem on the list, capitalize and don't include ","
    // if (shoppingItemListLength === 0) {
    //   return `${
    //     shoppingItem.name[0].toUpperCase() + shoppingItem.name.substring(1)
    //   }`;
    // }

    // // Capitalize the first item
    // if (index === 0) {
    //   return `${
    //     shoppingItem.name[0].toUpperCase() + shoppingItem.name.substring(1)
    //   }, `;
    // }

    // dispaly the ShoppingItemName or the ItemName
    let shoppingOrItemName = "";

    if (typeof shoppingItem.name === "undefined" || shoppingItem.name === "") {
      if (typeof shoppingItem.item !== "undefined") {
        // it's null if the backend returns null since the item id doesn't exist
        if (shoppingItem.item == null) {
          shoppingOrItemName = "not found";
        } else {
          shoppingOrItemName = shoppingItem.item.name;
        }
      }
    } else {
      shoppingOrItemName = shoppingItem.name;
    }

    // don't include a comma on the last item
    if (shoppingItemListLength === index) {
      return `${shoppingOrItemName}`;
    }

    return `${shoppingOrItemName}, `;
  });

  const selectionChangeIdAndStatus = {
    recipeId: _id,
    recipeChosenInWeeklyMenu: recipeChosenInWeeklyMenu ? false : true,
    menuNutrition: menuNutrition,
  };

  const setServingChangeIdAndStatus = (e) => {
    const servingChangeIdAndStatus = {
      recipeId: _id,
      personalizedYield: e.target.value,
      menuNutrition: menuNutrition,
    };
    setRecipeEditMenu(servingChangeIdAndStatus);
  };

  // "/my/discover/collection/recipe/"

  let recipeViewRouterLink = "";

  if (isMenuPage) {
    recipeViewRouterLink = `/my/discover/menu/recipe/${_id}`;
  } else if (isSearchPage) {
    recipeViewRouterLink = `/my/discover/search/recipe/${_id}`;
  } else if (isCollectionPage) {
    recipeViewRouterLink = `/my/discover/collection/recipe/${_id}`;
  } else if (isMealPlanPage) {
    recipeViewRouterLink = `/my/meal-plan/recipe/${_id}`;
  }
  // else {
  //   recipeViewRouterLink = `/my/recipe/${_id}`;
  // }

  return (
    <>
      <IonCardStyled>
        <IonRouterLink
          onClick={() => handleChange({ name: "editRecipeId", value: _id })}
          routerLink={recipeViewRouterLink}
        >
          <ImgStyled
            src={
              featuredMedia === ""
                ? "https://my-recipa-app-images.s3.us-west-1.amazonaws.com/app-assets/imageless-recipe-or-food.jpg"
                : featuredMedia
            }
          />

          <IonCardHeaderStyled>
            <IonCardTitle>{name}</IonCardTitle>
            {/* <IonCardSubtitle>Main Course Only</IonCardSubtitle> */}
            <IonCardSubtitle>
              {/* {`${formatTimeAbridge(totalTime)}`} */}
              {totalTime === null ? (
                <></>
              ) : (
                `${formatTimeAbridge(totalTime)} | `
              )}

              {personalizedYield === null ? (
                <></>
              ) : (
                `${personalizedYield} servings`
              )}
              {calories === null ? (
                <></>
              ) : (
                ` | ${calories.toLocaleString()} Kcal`
              )}
              {protein === null ? <></> : ` | ${protein} P`}
              {rating === null ? <></> : `   | ${rating} ★ (${reviewCount})`}
            </IonCardSubtitle>
          </IonCardHeaderStyled>
          {/* DON' DELETE - This our the ingredients */}
          {/* <IonCardContent>
          <RedText>{shoppingItemList}</RedText>
        </IonCardContent> */}
        </IonRouterLink>
        <CardActionsWrapperStyled>
          {isMealPlanPage === true ? (
            <IonRouterLink
              onClick={() =>
                setEditServingUpdateServing(_id, personalizedYield, name)
              }
              routerLink="/my/serving/edit"
            >
              <IonButton
                fill="clear"
                // name="recipeId"
                // levelOneKey="mealPlanServingSelection"
                value={_id}
              >
                Update
              </IonButton>
            </IonRouterLink>
          ) : (
            // <IonRouterLink
            //   onClick={() => setEditServingAddRecipe(_id)}
            //   routerLink="/my/serving/add"
            // >
            <IonButton
              fill="clear"
              name="recipeId"
              levelOneKey="mealPlanServingSelection"
              value={_id}
              onClick={() =>
                handleSetEditServingAddRecipe(_id, personalizedYield, name)
              }
            >
              Add
            </IonButton>
            // </IonRouterLink>
          )}
          {isCollectionPage === true ? (
            <IonButton
              fill="clear"
              name="removeMealPlanRecipe"
              onClick={() => setOpen(true)}
            >
              Delete
            </IonButton>
          ) : (
            <></>
          )}
          {isMealPlanPage === true ? (
            <IonButton
              fill="clear"
              name="removeMealPlanRecipe"
              onClick={() => removeMealPlanRecipe(_id, name)}
            >
              Remove
            </IonButton>
          ) : (
            <></>
          )}
          {/* <IonRouterLink
          onClick={() => getRecipe(_id)}
          routerLink="/my/edit-recipe-or-food"
        >
          <IonButton fill="clear">Edit</IonButton>
        </IonRouterLink> */}
          {/* <FormRowSelect
          position={"Default label"}
          style={{ paddingLeft: "0" }}
          labelText="Serving"
          name="personalizedYield"
          value={personalizedYield}
          onIonChange={setServingChangeIdAndStatus}
          list={recipeYieldList}
        /> */}
          {user._id === "6382aed8c8c03dff526e8280" ? (
            <>
              {/* DON'T DELETE - For Admin only */}
              {/* <IonButton fill="clear" onClick={() => deleteRecipe(_id)}>
              Delete
            </IonButton> */}
            </>
          ) : (
            <></>
          )}

          {user._id === "6382aed8c8c03dff526e8280" ? (
            <IonRouterLink
              onClick={() => getRecipe(_id)}
              routerLink="/my/addrecipe"
            >
              <IonButton fill="clear">Admin Edit</IonButton>
            </IonRouterLink>
          ) : (
            <></>
          )}
        </CardActionsWrapperStyled>
      </IonCardStyled>
      {/* ////////////////////////////////////// ACION SHEEET DELETE CONFIRMATION ////////////////////////////////////// */}
      <IonActionSheet
        isOpen={open}
        buttons={[
          {
            text: "Delete",
            handler: () => deleteRecipe(_id, name, "isCollectionPage"),
            role: "destructive",
            data: {
              action: "Confirm permanent delete",
            },
          },
          {
            text: "Cancel",
            role: "cancel",
            data: {
              action: "cancel",
            },
          },
        ]}
        onDidDismiss={() => setOpen(false)}
      ></IonActionSheet>
    </>
  );
};
export default RecipeCard;
