import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../context/appContext";
import styled from "styled-components";

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonList,
  IonCol,
  IonButton,
  useIonToast,
  IonBadge,
} from "@ionic/react";

import { FormRow, ChipAlert } from "../components";

const WebWidthListContainer = styled.div`
  margin: auto;
  max-width: 750px;
`;

const IonListStyled = styled(IonList)`
  ion-item::part(native) {
    padding-left: 0;
  }

  .native-input {
    /* padding-left: 8px; */
  }
`;

const SpaceSoiPhoneKeyboardDoesntCoverInputs = styled.div`
  .ios {
    height: 325px;
    background-color: white;
    display: block;
  }
`;

const PersonalInfo = () => {
  const {
    user,
    isLoading,
    showAlert,
    alertColor,
    alertText,
    displayAlert,
    updateUser,
    handleChangeMemberInfo,
  } = useAppContext();

  const history = useHistory();

  const [present] = useIonToast();

  const presentToast = () => {
    present({
      message: alertText,
      duration: 2000,
      position: "top",
      color: alertColor,
    });
  };

  if (showAlert) {
    presentToast();
  }

  const [address, setAddress] = useState(user?.address);
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber);

  const members = user.members;

  const image = useState(user?.image);

  const onSubmit = (e) => {
    e.preventDefault();

    if (!members[0].firstName) {
      displayAlert("Please provide a first name for member 1");
      return;
    }

    updateUser({
      address,
      phoneNumber,
      members,
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/my/profile" />
          </IonButtons>
          <IonTitle>Personal Info Page</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <WebWidthListContainer>
          <h1 className="ion-text-center">Personal Info Page</h1>
          <IonListStyled>
            {/* Address input */}
            {/* <FormRow
              labelText="Address"
              type="text"
              name="address"
              value={address}
              onIonChange={(e) => setAddress(e.target.value)}
            /> */}
            {/* phoneNumber input */}
            <FormRow
              labelText="Phone"
              type="text"
              name="phoneNumber"
              value={phoneNumber}
              onIonChange={(e) => setPhoneNumber(e.target.value)}
            />

            {user?.members.map((member, index) => {
              return (
                <>
                  <h3>Member {index + 1}</h3>
                  {/* fist name input */}
                  <FormRow
                    labelText="First Name"
                    type="text"
                    name="firstName"
                    value={user?.members[index].firstName}
                    index={index}
                    onIonChange={handleChangeMemberInfo}
                  />
                  {/* Last Name input */}
                  <FormRow
                    labelText="Last Name"
                    type="text"
                    name="lastName"
                    value={user?.members[index].lastName}
                    index={index}
                    onIonChange={handleChangeMemberInfo}
                  />
                  {/* calories input */}
                  <FormRow
                    labelText="calories"
                    type="text"
                    name="calories"
                    value={user?.members[index].calories}
                    index={index}
                    onIonChange={handleChangeMemberInfo}
                  />
                  {/* protein input */}
                  <FormRow
                    labelText="protein"
                    type="text"
                    name="protein"
                    value={user?.members[index].protein}
                    index={index}
                    onIonChange={handleChangeMemberInfo}
                  />
                  {/* carbohydrates input */}
                  <FormRow
                    labelText="carbohydrates"
                    type="text"
                    name="carbohydrates"
                    value={user?.members[index].carbohydrates}
                    index={index}
                    onIonChange={handleChangeMemberInfo}
                  />
                  {/* fat input */}
                  <FormRow
                    labelText="fat"
                    type="text"
                    name="fat"
                    value={user?.members[index].fat}
                    index={index}
                    onIonChange={handleChangeMemberInfo}
                  />
                </>
              );
            })}
            {/* {showAlert && <ChipAlert />} */}
            <IonButton
              expand="block"
              class="ion-margin-top"
              onClick={onSubmit}
              disabled={isLoading}
            >
              Update
            </IonButton>
          </IonListStyled>
          <SpaceSoiPhoneKeyboardDoesntCoverInputs>
            {/* Adding the IonBadge is a hack. I need to add a ionic component so I can target that element and change the height */}
            <IonBadge></IonBadge>
          </SpaceSoiPhoneKeyboardDoesntCoverInputs>
        </WebWidthListContainer>
      </IonContent>
    </IonPage>
  );
};

export default PersonalInfo;
