import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useAppContext } from "../context/appContext";
import { FormRowSelect, FormTextarea } from "../components";

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonList,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonImg,
  IonInput,
  useIonToast,
} from "@ionic/react";

import { addCircleOutline, close, eye, eyeOff } from "ionicons/icons";
import { formatTimeAbridge } from "../utils/FormattingUtils";

import {
  useEditRecipeAfterEveryClickRecipeInput,
  useAddShoppingItemFiled,
  useHandleShoppingItemRecipeInput,
  useHandleShoppingItemHiddenStatus,
  useRemoveShoppingItemField,
  useHandleRecipeInput,
  handleInstructionVariables,
} from "../utils/Helpers.js";
import {
  recipeYieldList,
  shoppingItemUnitCategories,
} from "../utils/Constants.js";

const TwoColumnIonList = styled(IonList)`
  IonLabel {
    white-space: "normal";
  }

  .smallColumn {
    text-align: right;
    white-space: normal;
    flex: none;
    width: 100px;
  }
`;

const StyledIonCol = styled(IonCol)`
  padding-top: 0;
  padding-bottom: 0;

  ion-item::part(native) {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
  }
`;

const StyledIonColUnit = styled(IonCol)`
  padding-top: 0;
  padding-bottom: 0;

  ion-item {
    width: 95px;
  }

  ion-item::part(native) {
    padding-left: 0;
    padding-right: 0;
  }

  ion-select::part(icon) {
    margin-right: -5px;
  }
`;

const IonColRemoveButton = styled(IonCol)`
  padding-top: 0;
  padding-bottom: 0;

  padding-left: 0;
  padding-right: 0;
  width: 10px;

  ion-icon {
    /* margin-top: 30px; */
    margin-left: 10px;
    margin-bottom: 10px;
  }
`;

const StackedDetailsStyled = styled.div`
  display: flex;
  justify-content: "space-around";

  div {
    width: 33%;
    text-align: center;
  }

  .heading {
    font-weight: bold;
    margin-bottom: 0;
  }

  .value {
    margin-top: 5px;
  }
`;

const PersonalizeRecipe = () => {
  const {
    recipeName,
    editRecipeId,
    recipeRating,
    recipeReviewCount,
    recipeSource,
    recipeSourceUrl,
    recipeFeaturedMedia,
    recipeInstagramUrl,
    recipeFeaturedCategory,
    recipeTotalTime,
    recipeCookTime,
    recipeActiveTime,
    recipePrepTime,
    recipeCoolTime,
    recipeFreezeTime,
    recipeMarinateTime,
    recipeRestTime,
    recipeSoakTime,
    recipeYield,
    personalizedYield,
    recipeShoppingItems,
    recipeCalories,
    recipeCarbohydrates,
    recipeFat,
    recipeProtein,
    recipeInstructions,
    getRecipe,
    editRecipe,
    showAlert,
    alertText,
    alertColor,
  } = useAppContext();

  const history = useHistory();

  const { useEditRecipeAfterEveryClick } =
    useEditRecipeAfterEveryClickRecipeInput();
  const { addShoppingItemFiled } = useAddShoppingItemFiled();
  const { handleShoppingItemRecipeInput } = useHandleShoppingItemRecipeInput();
  const { handleShoppingItemHiddenStatus } =
    useHandleShoppingItemHiddenStatus();
  const { removeShoppingItemField } = useRemoveShoppingItemField();
  const { useHandleChange } = useHandleRecipeInput();

  const ShoppingItemsHeadingStyled = styled.div`
    display: flex;
    justify-content: "space-between";
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;

    p {
      margin-left: auto;
      margin-bottom: 0;
    }
  `;

  useEffect(() => {
    if (editRecipeId === "") {
      history.push("/");
    }
  }, [editRecipeId, history]);

  const onSubmit = () => {
    editRecipe();
    return;
  };

  const [present] = useIonToast();

  const presentToast = () => {
    present({
      message: alertText,
      duration: 1500,
      position: "top",
      color: alertColor,
    });
  };

  if (showAlert) {
    presentToast();
  }

  // Refactor: duplicate code in ViewRecipe
  let recipeYieldListWithOriginalLabel = [];

  function addLabelOriginalYield(yieldOption, index) {
    if (yieldOption === recipeYield) {
      recipeYieldListWithOriginalLabel.push(`${yieldOption}⠀(1x)`);
    } else {
      recipeYieldListWithOriginalLabel.push(yieldOption);
    }
  }

  recipeYieldList.forEach(addLabelOriginalYield);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Personalize</IonTitle>
          <IonButtons slot="primary">
            <IonButton
              fill="solid"
              routerLink="/my/recipe"
              onClick={() => onSubmit()}
            >
              Save
            </IonButton>
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton
              fill="outline"
              routerLink="/my/recipe"
              onClick={() => getRecipe(editRecipeId)}
            >
              Cancel
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="ion-padding" style={{ paddingBottom: "0" }}>
          <h1 style={{ fontWeight: "700" }}>{recipeName}</h1>

          <p style={{ marginTop: "0px" }}>
            {/* rating section */}
            {recipeRating === null ? (
              <></>
            ) : (
              <span>
                {recipeRating} ★ ({recipeReviewCount})
                <span style={{ color: "#D3D3D3" }}>
                  &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            )}
            {/* recipe source section */}
            By&nbsp;
            <a
              href={recipeSourceUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#000" }}
            >
              {recipeSource}
            </a>
            {/* recipe categories section */}
            <span style={{ color: "#D3D3D3" }}>
              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            </span>
            {recipeFeaturedCategory}
          </p>
        </div>
        {recipeFeaturedMedia === "" ? (
          <div></div>
        ) : (
          <IonImg src={recipeFeaturedMedia}></IonImg>
        )}
        {recipeInstagramUrl === "" ? (
          <div></div>
        ) : (
          <IonImg src={recipeInstagramUrl}></IonImg>
        )}
        <StackedDetailsStyled>
          <div>
            <p className="heading">Total Time</p>
            <p className="value">{formatTimeAbridge(recipeTotalTime)}</p>
          </div>
          {recipePrepTime === "" || typeof recipePrepTime === "undefined" ? (
            <></>
          ) : (
            <div>
              <p className="heading">Prep Time</p>
              <p className="value">{formatTimeAbridge(recipePrepTime)}</p>
            </div>
          )}
          {recipeCookTime === "" || typeof recipeCookTime === "undefined" ? (
            <></>
          ) : (
            <div>
              <p className="heading">Cook Time</p>
              <p className="value">{formatTimeAbridge(recipeCookTime)}</p>
            </div>
          )}
          {recipeActiveTime === "" ||
          typeof recipeActiveTime === "undefined" ? (
            <></>
          ) : (
            <div>
              <p className="heading">Active Time</p>
              <p className="value">{formatTimeAbridge(recipeActiveTime)}</p>
            </div>
          )}
          {recipeCoolTime === "" || typeof recipeCoolTime === "undefined" ? (
            <></>
          ) : (
            <div>
              <p className="heading">Cool Time</p>
              <p className="value">{formatTimeAbridge(recipeCoolTime)}</p>
            </div>
          )}
          {recipeFreezeTime === "" ||
          typeof recipeFreezeTime === "undefined" ? (
            <></>
          ) : (
            <div>
              <p className="heading">Freeze Time</p>
              <p className="value">{formatTimeAbridge(recipeFreezeTime)}</p>
            </div>
          )}
          {recipeMarinateTime === "" ||
          typeof recipeMarinateTime === "undefined" ? (
            <></>
          ) : (
            <div>
              <p className="heading">Marinate Time</p>
              <p className="value">{formatTimeAbridge(recipeMarinateTime)}</p>
            </div>
          )}
          {recipeRestTime === "" || typeof recipeRestTime === "undefined" ? (
            <></>
          ) : (
            <div>
              <p className="heading">Rest Time</p>
              <p className="value">{formatTimeAbridge(recipeRestTime)}</p>
            </div>
          )}
          {recipeSoakTime === "" || typeof recipeSoakTime === "undefined" ? (
            <></>
          ) : (
            <div>
              <p className="heading">Soak Time</p>
              <p className="value">{formatTimeAbridge(recipeSoakTime)}</p>
            </div>
          )}
        </StackedDetailsStyled>

        <ShoppingItemsHeadingStyled className="ion-padding">
          <h2 style={{ fontWeight: "700" }}>Ingredients</h2>
          <p>Serving</p>
          <FormRowSelect
            name="personalizedYield"
            value={
              recipeYield === personalizedYield
                ? `${personalizedYield}⠀(1x)`
                : personalizedYield
            }
            onIonChange={useHandleChange}
            list={recipeYieldListWithOriginalLabel}
          />
        </ShoppingItemsHeadingStyled>
        <IonList lines="full">
          <IonGrid>
            {recipeShoppingItems.map((recipeShoppingItem, index) => {
              let shoppingOrItemName = "";

              if (typeof recipeShoppingItem.name === "undefined") {
                shoppingOrItemName = recipeShoppingItem.item.name;
              } else {
                shoppingOrItemName = recipeShoppingItem.name;
              }

              const hidden = recipeShoppingItem.hidden;
              const editable =
                typeof recipeShoppingItem.item === "undefined" ? true : false;

              if (recipeShoppingItem.unit === "heading") {
                return (
                  <IonRow key={index} class="ion-align-items-end">
                    <StyledIonCol size="6">
                      <FormTextarea
                        style={{ marginTop: "0", fontWeight: "600" }}
                        key={index}
                        labelText="ㅤ"
                        name="name"
                        index={index}
                        autoGrow={true}
                        value={shoppingOrItemName}
                        onIonChange={handleShoppingItemRecipeInput}
                      />
                    </StyledIonCol>
                    <StyledIonCol size="2"></StyledIonCol>
                    <StyledIonColUnit size="3">
                      <FormRowSelect
                        labelText="ㅤ"
                        name="unit"
                        index={index}
                        value={recipeShoppingItem.unit}
                        onIonChange={handleShoppingItemRecipeInput}
                        list={shoppingItemUnitCategories}
                      />
                    </StyledIonColUnit>
                    <IonColRemoveButton size="1">
                      <IonIcon
                        icon={close}
                        index={index}
                        onClick={removeShoppingItemField}
                      ></IonIcon>
                    </IonColRemoveButton>
                  </IonRow>
                );
              } else {
                return (
                  <IonRow key={index} class="ion-align-items-end">
                    <StyledIonCol size="6">
                      <FormTextarea
                        style={
                          hidden
                            ? { textDecoration: "line-through", marginTop: "0" }
                            : { marginTop: "0" }
                        }
                        labelStyle={{ opacity: 1 }}
                        key={index}
                        labelText={index === 0 ? "Name" : "ㅤ"}
                        name="name"
                        index={index}
                        autoGrow={true}
                        disabled={editable ? false : true}
                        // value={
                        //   editable
                        //     ? recipeShoppingItem.name
                        //     : recipeShoppingItem.item.name
                        // }
                        value={shoppingOrItemName}
                        onIonChange={handleShoppingItemRecipeInput}
                      />
                    </StyledIonCol>
                    <StyledIonCol size="2">
                      <IonItem>
                        <IonLabel position="stacked" style={{ opacity: 1 }}>
                          {index === 0 ? "Qty" : "ㅤ"}
                        </IonLabel>
                        <IonInput
                          disabled={hidden ? "true" : "false"}
                          style={
                            hidden ? { textDecoration: "line-through" } : null
                          }
                          type="text"
                          name="quantity"
                          index={index}
                          value={recipeShoppingItem.quantity}
                          onIonChange={handleShoppingItemRecipeInput}
                        />
                      </IonItem>
                    </StyledIonCol>
                    <StyledIonColUnit size="3">
                      <FormRowSelect
                        labelStyle={{ opacity: 1 }}
                        style={
                          hidden ? { textDecoration: "line-through" } : null
                        }
                        disabled={hidden ? "true" : "false"}
                        labelText={index === 0 ? "Unit" : "ㅤ"}
                        name="unit"
                        index={index}
                        value={recipeShoppingItem.unit}
                        onIonChange={handleShoppingItemRecipeInput}
                        list={shoppingItemUnitCategories}
                      />
                    </StyledIonColUnit>
                    <IonColRemoveButton size="1">
                      <IonIcon
                        // icon={hidden === true ? eyeOff : eye}
                        icon={editable ? close : hidden === true ? eye : eyeOff}
                        index={index}
                        onClick={
                          editable
                            ? removeShoppingItemField
                            : handleShoppingItemHiddenStatus
                        }
                      ></IonIcon>
                    </IonColRemoveButton>
                  </IonRow>
                );
              }
            })}
          </IonGrid>
        </IonList>
        <IonIcon
          icon={addCircleOutline}
          onClick={addShoppingItemFiled}
          style={{ marginLeft: "8px" }}
        ></IonIcon>
        <h2
          className="ion-padding"
          style={{ paddingTop: "0", paddingBottom: "0", fontWeight: "700" }}
        >
          Nutrition
        </h2>
        <TwoColumnIonList lines="full">
          <IonItem>
            <IonLabel>Calories</IonLabel>
            <IonLabel className="smallColumn">{recipeCalories}</IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Fat</IonLabel>
            <IonLabel className="smallColumn">{recipeFat} g</IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Carbohydrates</IonLabel>
            <IonLabel className="smallColumn">{recipeCarbohydrates} g</IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Protein</IonLabel>
            <IonLabel className="smallColumn">{recipeProtein} g</IonLabel>
          </IonItem>
        </TwoColumnIonList>
        <h2
          className="ion-padding"
          style={{ paddingTop: "0", paddingBottom: "0", fontWeight: "700" }}
        >
          Instructions
        </h2>
        <IonList lines="full">
          {recipeInstructions.map((recipeInstruction, index) => {
            return (
              <div key={index}>
                {recipeInstruction.media === "" ? (
                  <div></div>
                ) : (
                  <IonItem>
                    <IonImg src={recipeInstruction.media}></IonImg>
                  </IonItem>
                )}
                <IonItem style={{ display: "flex", alignItems: "start" }}>
                  <p style={{ fontWeight: "700", marginRight: "10px" }}>
                    {index + 1}.
                  </p>
                  <p style={{ whiteSpace: "normal" }}>
                    {handleInstructionVariables(
                      recipeInstruction.description,
                      recipeShoppingItems,
                      personalizedYield
                    )}
                  </p>
                </IonItem>
              </div>
            );
          })}
        </IonList>
        {/* {showAlert && presentToast()} */}
      </IonContent>
    </IonPage>
  );
};

export default PersonalizeRecipe;
