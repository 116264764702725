// import mongoose from "mongoose";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppContext } from "../context/appContext";
import styled from "styled-components";
import { InstagramEmbed } from "react-social-media-embed";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonList,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonImg,
  IonItem,
  IonSelect,
  IonSelectOption,
  useIonToast,
  IonBadge,
} from "@ionic/react";
import { FormRow, FormRowSelect, FormTextarea, ChipAlert } from "../components";

import { close } from "ionicons/icons";

import { formatTimeAbridge } from "../utils/FormattingUtils";
import {
  useHandleRecipeInput,
  useAddShoppingItemFiled,
  useAddShoppingItemFiledByItemId,
} from "../utils/Helpers.js";
import {
  recipeYieldList,
  shoppingItemUnitCategories,
  meatTypes,
  recipeUtensilsList,
} from "../utils/Constants.js";

const WebWidthListContainer = styled.div`
  margin: auto;
  max-width: 750px;
`;

const IonImgStyled = styled(IonImg)`
  width: 100%;
  object-fit: cover;
  height: 200px;

  @media (min-width: 350px) {
    height: 250px;
  }

  @media (min-width: 400px) {
    height: 300px;
  }

  @media (min-width: 450px) {
    height: 350px;
  }

  @media (min-width: 500px) {
    height: 400px;
  }

  @media (min-width: 550px) {
    height: 450px;
  }

  @media (min-width: 600px) {
    height: 500px;
  }
`;

const SpaceSoiPhoneKeyboardDoesntCoverInputs = styled.div`
  .ios {
    height: 375px;
    background-color: white;
    display: block;
  }
`;

const CreateRecipeOrFood = () => {
  const {
    isLoading,
    showAlert,
    displayAlert,
    isEditing,
    handleChange,
    createRecipe,
    removeShoppingItemAddRecipePage,
    handleChangeShoppingItemRecipePage,
    addInscructionStep,
    removeInstructionStep,
    handleChangeInstructions,
    addUtensil,
    removeUtensil,
    handleChangeUtensil,
    recipeName,
    recipeRating,
    recipeReviewCount,
    recipeSource,
    recipeSourceUrl,
    recipeFeaturedMedia,
    recipeInstagramUrl,
    recipeFeaturedCategory,
    recipeFeaturedCategories,
    recipeTotalTime,
    recipeCookTime,
    recipeActiveTime,
    recipePrepTime,
    recipeCoolTime,
    recipeFreezeTime,
    recipeMarinateTime,
    recipeRestTime,
    recipeSoakTime,
    recipeDescription,
    recipeCuisine,
    recipeCuisines,
    recipeMealType,
    recipeMeatTypes,
    recipeUtensils,
    recipeVideo,
    recipeNotes,
    recipeCourseType,
    recipeCourseTypes,
    recipeMeatType,
    personalizedYield,
    recipeYield,
    recipeShoppingItems,
    recipeCalories,
    recipeCarbohydrates,
    recipeFat,
    recipeProtein,
    recipeSodium,
    recipeInstructions,
    handleChangeLevelTwo,
    alertColor,
    alertText,
    createRecipeInMealPlanPageAndSetServingEditPage,
    editRecipeCollectionPage,
    editRecipeMealPlanPage,
    createRecipeInCollectionPage,
    redirectFrom,
    editRecipeId,
  } = useAppContext();

  const history = useHistory();

  const location = useLocation();

  const isFromMealPlanPage = location.pathname.includes("/meal-plan");

  const [present] = useIonToast();

  const { useHandleChange } = useHandleRecipeInput();
  const { addShoppingItemFiled } = useAddShoppingItemFiled();
  const { addShoppingItemFiledByItemId } = useAddShoppingItemFiledByItemId();

  useEffect(() => {
    if (redirectFrom === "/my/meal-plan/create-recipe-or-food") {
      history.push("/my/meal-plan/serving/add");
    } else if (redirectFrom === "/my/meal-plan/edit-recipe-or-food") {
      history.goBack();
    } else if (
      redirectFrom === "/my/discover/collection/create-recipe-or-food"
    ) {
      history.push(
        `/my/discover/collection/create-recipe-or-food/recipe/${editRecipeId}`
      );

      // //////////// description here
      // setTimeout(() => {
      //   history.push(`/my/discover/collection/recipe/${editRecipeId}`);
      // }, 5000);
    } else if (redirectFrom === "/my/discover/collection/edit-recipe-or-food") {
      history.push(
        `/my/discover/collection/create-recipe-or-food/recipe/${editRecipeId}`
      );
      console.log("this redirect is happening in CreateRecipeOrFood");
    }
  }, [redirectFrom, history]);

  const presentToast = () => {
    present({
      message: alertText,
      duration: 2000,
      position: "top",
      color: alertColor,
    });
  };

  if (showAlert) {
    presentToast();
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (!recipeName) {
      displayAlert();
      return;
    }
    if (isEditing) {
      if (isFromMealPlanPage) {
        console.log(
          "editRecipeMealPlanPage(/my/meal-plan/edit-recipe-or-food)"
        );
        editRecipeMealPlanPage("/my/meal-plan/edit-recipe-or-food");
      } else {
        console.log();
        editRecipeCollectionPage("/my/discover/collection/edit-recipe-or-food");
      }
      return;
    }

    if (isFromMealPlanPage) {
      createRecipeInMealPlanPageAndSetServingEditPage();
    } else {
      createRecipeInCollectionPage();
    }
  };

  const handleChangeLevelTwoNonNegativeNumber = (e) => {
    if (e.target.value == "" || e.target.value > 0) {
      handleChange({
        name: e.target.name,
        value: e.target.value,
      });
    } else if (e.target.value <= 0 <= 0) {
      displayAlert(`Number has to be greater than zero`);
    }
  };

  const handleChangeLevelTwoNonNegativeNumberRecipeYieldAndPersonalizedYield = (
    e
  ) => {
    if (e.target.value == "" || e.target.value > 0) {
      handleChange({
        name: e.target.name,
        value: e.target.value,
      });

      handleChange({
        name: "personalizedYield",
        value: e.target.value,
      });
    } else if (e.target.value <= 0 <= 0) {
      displayAlert(`Number has to be greater than zero`);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>
            {isEditing ? `Edit ${recipeName}` : "Create Recipe or Food"}
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <WebWidthListContainer>
          <form className="ion-padding">
            {/* <h3>{isEditing ? "edit" : "add"}</h3> */}
            <IonList>
              {/* recipeFeaturedMedia input */}
              {recipeFeaturedMedia === "" ? (
                <div></div>
              ) : (
                <IonImgStyled src={recipeFeaturedMedia}></IonImgStyled>
              )}
              {/* recipeInstagramUrl input */}
              {recipeInstagramUrl === "" ||
              typeof recipeInstagramUrl === "undefined" ? (
                <div></div>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <InstagramEmbed url={recipeInstagramUrl} width={"100%"} />
                </div>
                // <div></div>
              )}
              {/* recipeName input */}
              <div style={{ marginTop: "10px" }}></div>
              <FormRow
                labelText="Name (Required*)"
                type="text"
                name="recipeName"
                value={recipeName}
                onIonChange={useHandleChange}
              />
              {/* serving input */}
              <FormRow
                labelText="Original Servings (Required*)"
                type="number"
                name="recipeYield"
                value={recipeYield}
                onIonChange={
                  handleChangeLevelTwoNonNegativeNumberRecipeYieldAndPersonalizedYield
                }
              />
              {/* {editRecipeId == "" ? (
                <></>
              ) : (
                <FormRow
                  labelText="Servings Allocated (Required*)"
                  type="number"
                  name="personalizedYield"
                  value={personalizedYield}
                  onIonChange={handleChangeLevelTwoNonNegativeNumber}
                />
              )} */}
              {/* recipe or food URL link */}
              <FormRow
                labelText="Recipe or Food URL Link"
                type="text"
                placeholder="www.recipe.com"
                name="recipeSourceUrl"
                value={recipeSourceUrl}
                onIonChange={useHandleChange}
              />
              {/* recipeInstagramUrl input */}
              {/* <FormRow
              labelText="Instagram URL Media"
              type="text"
              name="recipeInstagramUrl"
              value={recipeInstagramUrl}
              onIonChange={useHandleChange}
            /> */}
              {/* recipeCalories input */}
              <FormRow
                labelText="Calories"
                type="number"
                name="recipeCalories"
                value={recipeCalories}
                onIonChange={handleChangeLevelTwoNonNegativeNumber}
              />
              {/* recipeProtein input */}
              <FormRow
                labelText="Protein"
                type="number"
                name="recipeProtein"
                value={recipeProtein}
                onIonChange={handleChangeLevelTwoNonNegativeNumber}
              />
              {/* recipeFeaturedMedia input */}
              <FormRow
                labelText="Image URL Link"
                type="text"
                placeholder="www.image.jpeg"
                name="recipeFeaturedMedia"
                value={recipeFeaturedMedia}
                onIonChange={useHandleChange}
              />
              {/* personalizedYield input */}
              {/* <FormRowSelect
              labelText="Personalized Yield"
              name="personalizedYield"
              placeholder="Personalized Yield"
              value={personalizedYield}
              onIonChange={useHandleChange}
              list={recipeYieldList}
            /> */}
              {/* recipeNotes input */}
              {/* <FormRow
                labelText="Notes"
                type="text"
                placeholder="Quick notes, ingredients, instructions etc."
                name="recipeNotes"
                value={recipeNotes}
                onIonChange={useHandleChange}
              /> */}

              <FormTextarea
                labelText="Notes"
                placeholder="Quick notes, ingredients, instructions etc."
                name="recipeNotes"
                autoGrow={true}
                value={recipeNotes}
                onIonChange={useHandleChange}
              />
            </IonList>
            {/* {showAlert && <ChipAlert />} */}
            <IonButton expand="block" onClick={onSubmit} disabled={isLoading}>
              {isEditing ? "Save" : "Add"}
            </IonButton>

            {/* {isEditing ? (
              <IonButton expand="block" onClick={onSubmit} disabled={isLoading}>
                Save
              </IonButton>
            ) : (
              <IonButton expand="block" onClick={onSubmit} disabled={isLoading}>
                Save
              </IonButton>
            )} */}
          </form>
          <SpaceSoiPhoneKeyboardDoesntCoverInputs>
            {/* Adding the IonBadge is a hack. I need to add a ionic component so I can target that element and change the height */}
            <IonBadge></IonBadge>
          </SpaceSoiPhoneKeyboardDoesntCoverInputs>
        </WebWidthListContainer>
      </IonContent>
    </IonPage>
  );
};

export default CreateRecipeOrFood;
