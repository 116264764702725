import { Route, useLocation } from "react-router-dom";
import { useAppContext } from "../src/context/appContext";

import {
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
  IonLabel,
  IonIcon,
} from "@ionic/react";
import {
  compass as compassIcon,
  restaurant as restaurantIcon,
  bag as bagIcon,
  person as personIcon,
} from "ionicons/icons";
import {
  Home,
  Menu,
  MealPlan,
  EditServingPage,
  Search,
  Collection,
  Profile,
  Stats,
  AddRecipe,
  CreateRecipeOrFood,
  ViewRecipe,
  ShoppingItemPage,
  PersonalizeRecipe,
  PersonalInfo,
  ShoppingList,
  StyledComponentesCourse,
  StyledComponentesCourseHome,
} from "./pages";

setupIonicReact();

const AppTabs = () => {
  const { discoverSelectedPage } = useAppContext();

  const location = useLocation();

  const pathname = location.pathname;

  let isMainNavPages = false;

  if (
    pathname === "/my/discover/menu" ||
    pathname === "/my/discover/search" ||
    pathname === "/my/discover/collection" ||
    pathname === "/my/meal-plan" ||
    pathname === "/my/shopping-list" ||
    pathname === "/my/profile"
  ) {
    isMainNavPages = true;
  }

  const displayNone = {
    display: "none",
  };

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route exact path="/my/home">
          <Home />
        </Route>
        {/* ////////////////////////////////////// MENU PAGES ////////////////////////////////////// */}
        <Route exact path="/my/discover/menu">
          <Menu />
        </Route>
        <Route exact path="/my/discover/menu/recipe/:id">
          <ViewRecipe />
        </Route>
        {/* ////////////////////////////////////// SEARCH PAGES ////////////////////////////////////// */}
        <Route exact path="/my/discover/search">
          <Search />
        </Route>
        <Route exact path="/my/discover/search/recipe/:id">
          <ViewRecipe />
        </Route>

        {/* ////////////////////////////////////// COLLECTION PAGES ////////////////////////////////////// */}
        <Route exact path="/my/discover/collection">
          <Collection />
        </Route>
        <Route exact path="/my/discover/collection/create-recipe-or-food">
          <CreateRecipeOrFood />
        </Route>
        {/* "This route handles when a recipe is created to the Collection Page and I want the back button to go to the Collection Page and not the '/my/meal-plan/create-recipe-or-food' page" */}
        <Route
          exact
          path="/my/discover/collection/create-recipe-or-food/recipe/:id"
        >
          <ViewRecipe />
        </Route>
        <Route exact path="/my/discover/collection/recipe/:id">
          <ViewRecipe />
        </Route>
        <Route exact path="/my/discover/collection/edit-recipe-or-food">
          <CreateRecipeOrFood />
        </Route>
        <Route exact path="/my/discover/collection/recipe/shopping-item/edit">
          <ShoppingItemPage />
        </Route>
        <Route exact path="/my/discover/collection/recipe/shopping-item/add">
          <ShoppingItemPage />
        </Route>
        {/* ////////////////////////////////////// MEAL PLAN PAGE ////////////////////////////////////// */}
        <Route exact path="/my/meal-plan">
          <MealPlan />
        </Route>
        <Route exact path="/my/meal-plan/create-recipe-or-food">
          <CreateRecipeOrFood />
        </Route>
        <Route exact path="/my/meal-plan/edit-recipe-or-food">
          <CreateRecipeOrFood />
        </Route>
        <Route exact path="/my/stats">
          <Stats />
        </Route>
        <Route exact path="/my/addrecipe">
          <AddRecipe />
        </Route>
        <Route exact path="/my/profile">
          <Profile />
        </Route>
        {/* <Route exact path="/my/recipe/:id">
          <ViewRecipe />
        </Route> */}
        <Route exact path="/my/meal-plan/recipe/:id">
          <ViewRecipe />
        </Route>
        <Route exact path="/my/meal-plan/recipe/shopping-item/edit">
          <ShoppingItemPage />
        </Route>
        <Route exact path="/my/meal-plan/recipe/shopping-item/add">
          <ShoppingItemPage />
        </Route>
        {/* ////////////////////////////////////// RECIPE PAGE ////////////////////////////////////// */}
        {/* <Route exact path="/my/recipe/:id">
          <ViewRecipe />
        </Route> */}

        <Route exact path="/my/recipe/shopping-item">
          <ShoppingItemPage />
        </Route>
        <Route exact path="/my/recipe/shopping-item/add">
          <ShoppingItemPage />
        </Route>
        <Route exact path="/my/recipe/shopping-item/edit">
          <ShoppingItemPage />
        </Route>
        {/* ////////////////////////////////////// SHOPPING LIST PAGE ////////////////////////////////////// */}
        <Route exact path="/my/Shopping-list">
          <ShoppingList />
        </Route>
        <Route exact path="/my/shopping-list/shopping-item">
          <ShoppingItemPage />
        </Route>
        <Route exact path="/my/shopping-list/shopping-item/add">
          <ShoppingItemPage />
        </Route>
        <Route exact path="/my/shopping-list/shopping-item/edit">
          <ShoppingItemPage />
        </Route>
        {/* ////////////////////////////////////// Profile PAGE ////////////////////////////////////// */}

        <Route exact path="/my/personalize">
          <PersonalizeRecipe />
        </Route>
        <Route exact path="/my/personal-info">
          <PersonalInfo />
        </Route>
        <Route exact path="/my/StyledComponentesCourse">
          <StyledComponentesCourse />
        </Route>
        <Route exact path="/my/StyledComponentesCourseHome">
          <StyledComponentesCourseHome />
        </Route>
        <Route exact path="/my/serving/add">
          <EditServingPage />
        </Route>
        <Route exact path="/my/meal-plan/serving/add">
          <EditServingPage />
        </Route>
        <Route exact path="/my/serving/edit">
          <EditServingPage />
        </Route>
      </IonRouterOutlet>
      <IonTabBar style={isMainNavPages ? {} : displayNone} slot="bottom">
        {/* <IonTabButton tab="my menu" href="/my/discover/menu"> */}
        <IonTabButton tab="my menu" href={discoverSelectedPage}>
          <IonIcon icon={compassIcon} />
          <IonLabel>1 Discover</IonLabel>
        </IonTabButton>
        <IonTabButton tab="meal plan" href="/my/meal-plan">
          <IonIcon icon={restaurantIcon} />
          <IonLabel>2 Meals</IonLabel>
        </IonTabButton>
        <IonTabButton tab="my shopping" href="/my/shopping-list">
          <IonIcon icon={bagIcon} />
          <IonLabel>3 Shop</IonLabel>
        </IonTabButton>
        <IonTabButton tab="profile" href="/my/profile">
          <IonIcon icon={personIcon} />
          <IonLabel>Profile</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default AppTabs;
