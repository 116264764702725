import { useAppContext } from "../context/appContext";
import { useIonViewDidEnter } from "@ionic/react";
import styled from "styled-components";
import { useEffect } from "react";
import RecipeCard from "./RecipeCard.js";
import PageBtnContainer from "./PageBtnContainer.js";

const CardWidthContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const RecipesContainer = (
  {
    // getSearchPageRecipes,
    // searchRecipes,
    // isLoading,
    // searchTotalRecipes,
    // searchRecipeName,
    // searchRecipeSource,
    // searchRecipeSourceUrl,
    // searchRecipeTotalTime,
    // searchRecipeCookTime,
    // searchRecipeMarinateTime,
    // searchRecipeRestTime,
    // searchRecipeFeaturedCategory,
    // searchRecipeCourseType,
    // searchRecipeCuisine,
    // searchRecipeMealType,
    // searchRecipeMeatType,
    // searchRecipeYield,
    // searchRecipeCalories,
    // searchRecipeCarbohydrates,
    // searchRecipeFat,
    // searchRecipeProtein,
    // searchRecipeVideo,
    // searchRecipeSortOption,
    // searchCurrentPage,
    // searchNumOfPages,
  }
) => {
  const {
    getSearchPageRecipes,
    searchRecipes,
    isLoading,
    searchTotalRecipes,
    searchRecipeName,
    searchRecipeSource,
    searchRecipeSourceUrl,
    searchRecipeTotalTime,
    searchRecipeCookTime,
    searchRecipeMarinateTime,
    searchRecipeRestTime,
    searchRecipeFeaturedCategory,
    searchRecipeCourseType,
    searchRecipeCuisine,
    searchRecipeMealType,
    searchRecipeMeatType,
    searchRecipeYield,
    searchRecipeCalories,
    searchRecipeCarbohydrates,
    searchRecipeFat,
    searchRecipeProtein,
    searchRecipeVideo,
    searchRecipeSortOption,
    searchCurrentPage,
    searchNumOfPages,
  } = useAppContext();

  // Bug: useIonViewDidEnter and useEffect run twice (make API call) on every load. useIonViewDidEnter works better than
  // useEffect because it runs when a user navigates away and back to the search page
  // useIonViewDidEnter(() => {
  //   console.log("useIonViewDidEnter");

  //   getSearchPageRecipes();
  // });

  useEffect(() => {
    console.log("useEffect");
    // getSearchPageRecipes({ myRecipesOnly: true });

    getSearchPageRecipes();
    // eslint-disable-next-line
  }, [
    searchCurrentPage,
    searchRecipeName,
    searchRecipeSource,
    searchRecipeSourceUrl,
    searchRecipeTotalTime,
    searchRecipeCookTime,
    searchRecipeMarinateTime,
    searchRecipeRestTime,
    searchRecipeFeaturedCategory,
    searchRecipeCourseType,
    searchRecipeCuisine,
    searchRecipeMealType,
    searchRecipeMeatType,
    searchRecipeYield,
    searchRecipeCalories,
    searchRecipeCarbohydrates,
    searchRecipeFat,
    searchRecipeProtein,
    searchRecipeVideo,
    searchRecipeSortOption,
  ]);

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  if (searchRecipes.length === 0) {
    return <h2>No searchRecipes to display...</h2>;
  }

  return (
    <div>
      <h6
        className="ion-padding"
        style={{ paddingTop: "0px", paddingBottom: "0px" }}
      >
        {searchTotalRecipes} recipe{searchRecipes.length > 1 && "s"} found
      </h6>
      <CardWidthContainer>
        {/* { The ...{recipe} is another way to pass in all of the props */}
        {searchRecipes.map((recipe) => {
          return <RecipeCard key={recipe._id} {...recipe} />;
        })}
      </CardWidthContainer>
      {searchNumOfPages > 1 && <PageBtnContainer />}
    </div>
  );
};
export default RecipesContainer;
