import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";

const FormRowSelect = ({
  name,
  value,
  placeholder,
  onIonChange,
  labelText,
  list,
  index,
  style,
  position,
  disabled,
  labelStyle,
  multiple,
}) => {
  // this doesn't seem to be longer needed
  // if (position === "Default label") {
  //   position = "Default label";
  // } else {
  //   position = "stacked";
  // }

  return (
    <IonItem>
      {typeof position === "undefined" ? (
        <></>
      ) : (
        <IonLabel style={labelStyle} position={position}>
          {labelText}
        </IonLabel>
      )}
      <IonSelect
        multiple={multiple}
        style={style}
        interface="action-sheet"
        name={name}
        index={index}
        placeholder={placeholder}
        onIonChange={onIonChange}
        value={value}
        disabled={disabled}
      >
        {list.map((itemValue, index) => {
          return (
            <IonSelectOption key={index} value={itemValue} disabled={disabled}>
              {itemValue}
            </IonSelectOption>
          );
        })}
      </IonSelect>
    </IonItem>
  );
};

export default FormRowSelect;
