import React, { useReducer, useContext } from "react";
import reducer from "./reducers";
import axios from "axios";
import { formatQuantity } from "format-quantity";
import { numericQuantity } from "numeric-quantity";
import { currentWeek } from "../utils/FormattingUtils";
// import { generateEmptySchedule } from "../../../utils/helpers.js"; // "../utils/helpers.js";
import {
  generateEmptySchedule,
  removeAllPopulatedRecipefromSchedule,
  checkIfServingAllocationIsGreaterThanRecipeServing,
} from "../utils/Helpers.js";

import {
  DISPLAY_ALERT,
  CLEAR_ALERT,
  BEGIN_REDIRECT,
  END_REDIRECT,
  GENERIC_ERROR,
  REGISTER_USER_BEGIN,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGIN_USER_BEGIN,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  HANDLE_CHANGE_MEMBER_INFO,
  HANDLE_CHANGE,
  HANDLE_CHANGE_LEVEL_TWO,
  CLEAR_VALUES,
  CREATE_RECIPE_BEGIN,
  CREATE_RECIPE_SUCCESS,
  CREATE_RECIPE_ERROR,
  CLEAR_RECIPE_VALUES,
  REMOVE_SHOPPING_ITEM_BEGIN_RECIPE_PAGE,
  HANDLE_CHANGE_SHOPPING_ITEM_RECIPE_PAGE,
  ADD_INSTRUCTION_STEP,
  REMOVE_INSTRUCTION_STEP,
  HANDLE_CHANGE_INSTRUCTION_CHANGE_ADD_RECIPE,
  ADD_UTENSIL,
  REMOVE_UTENSIL,
  HANDLE_CHANGE_UTENSIL_CHANGE_ADD_RECIPE,
  GET_RECIPE_BEGIN,
  GET_RECIPE_SUCCESS,
  SET_EDIT_RECIPE,
  DELETE_RECIPE_BEGIN,
  DELETE_RECIPE_SUCCESS,
  EDIT_RECIPE_BEGIN,
  EDIT_RECIPE_SUCCESS,
  EDIT_RECIPE_ERROR,
  // Menu
  GET_MENU_BEGIN,
  GET_MENU_SUCCESS,
  // Search Page
  SEARCH_CLEAR_FILTERS,
  GET_SEARCH_PAGE_RECIPES_BEGIN,
  GET_SEARCH_PAGE_RECIPES_SUCCESS,
  // Collection Page
  GET_COLLECTION_PAGE_RECIPES_BEGIN,
  GET_COLLECTION_PAGE_RECIPES_SUCCESS,
  COLLECTION_CLEAR_FILTERS,
  CREATE_RECIPE_IN_COLLECTION_PAGE_BEGIN,
  CREATE_RECIPE_IN_COLLECTION_PAGE_SUCCESS,
  CREATE_RECIPE_IN_COLLECTION_PAGE_ERROR,
  EDIT_RECIPE_COLLECTION_PAGE_BEGIN,
  EDIT_RECIPE_COLLECTION_PAGE_SUCCESS,
  EDIT_RECIPE_COLLECTION_PAGE_ERROR,
  // Edit Serving Page
  SET_EDIT_SERVING_ADD_RECIPE,
  SET_EDIT_SERVING_UPDATE_RECIPE,
  HANDLE_CHANGE_EDIT_SERVINGS_PAGE_MEAL,
  HANDLE_CHANGE_EDIT_SERVINGS_PAGE_SERVING_ALLOCATION,
  // Meal Plan
  GET_MEAL_PLAN_BEGIN,
  GET_MEAL_PLAN_SUCCESS,
  CREATE_MEAL_PLAN_RECIPE_BEGIN,
  CREATE_MEAL_PLAN_RECIPE_SUCCESS,
  CREATE_MEAL_PLAN_RECIPE_ERROR,
  REMOVE_MEAL_PLAN_RECIPE_BEGIN,
  REMOVE_MEAL_PLAN_RECIPE_SUCCESS,
  REMOVE_MEAL_PLAN_RECIPE_ERROR,
  UPDATE_MEAL_PLAN_RECIPE_BEGIN,
  UPDATE_MEAL_PLAN_RECIPE_SUCCESS,
  UPDATE_MEAL_PLAN_RECIPE_ERROR,
  UPDATE_MACROS_OR_SUMMARY_VIEW,
  CREATE_RECIPE_IN_MEAL_PLAN_AND_SET_SERVING_EDIT_PAGE_BEGIN,
  CREATE_RECIPE_IN_MEAL_PLAN_AND_SET_SERVING_EDIT_PAGE_SUCCESS,
  CREATE_RECIPE_IN_MEAL_PLAN_AND_SET_SERVING_EDIT_PAGE_ERROR,
  EDIT_RECIPE_MEAL_PLAN_PAGE_BEGIN,
  EDIT_RECIPE_MEAL_PLAN_PAGE_SUCCESS,
  EDIT_RECIPE_MEAL_PLAN_PAGE_ERROR,
  // Shopping List
  GET_SHOPPING_BEGIN,
  GET_SHOPPING_SUCCESS,
  UPDATE_SHOPPING_LIST_GROUP_CATEGORY,
  UPDATE_SHOPPING_LIST_HIDE_CHECKED,
  CREATE_SHOPPING_ITEM_BEGIN,
  CREATE_SHOPPING_ITEM_SUCCESS,
  CREATE_SHOPPING_ITEM_ERROR,
  CLEAR_SHOPPING_ITEM,
  SET_EDIT_SHOPPING_ITEM,
  CLEAR_SHOPPING_ITEM_VALUES,
  EDIT_SHOPPING_LIST_SHOPPING_ITEM_BEGIN,
  EDIT_SHOPPING_LIST_SHOPPING_ITEM_SUCCESS,
  EDIT_SHOPPING_LIST_SHOPPING_ITEM_ERROR,
  EDIT_RECIPE_SHOPPING_ITEM_BEGIN,
  EDIT_RECIPE_SHOPPING_ITEM_SUCCESS,
  EDIT_RECIPE_SHOPPING_ITEM_ERROR,
  REMOVE_SHOPPING_LIST_SHOPPING_ITEM_BEGIN,
  REMOVE_SHOPPING_LIST_SHOPPING_ITEM_SUCCESS,
  REMOVE_SHOPPING_LIST_SHOPPING_ITEM_ERROR,
  REMOVE_RECIPE_SHOPPING_ITEM_BEGIN,
  REMOVE_RECIPE_SHOPPING_ITEM_SUCCESS,
  REMOVE_RECIPE_SHOPPING_ITEM_ERROR,
  CHECK_BOX_SHOPPING_ITEM_BEGIN,
  CHECK_BOX_SHOPPING_ITEM_SUCCESS,
  CHECK_BOX_SHOPPING_ITEM_ERROR,
  SET_EDIT_SHOPPING_ITEM_VIEW_RECIPE_PAGE,
  EDIT_RECIPE_SHOPPING_ITEM_BEGIN_VIEW_RECIPE_PAGE,
  EDIT_RECIPE_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE,
  EDIT_RECIPE_SHOPPING_ITEM_ERROR_VIEW_RECIPE_PAGE,
  REMOVE_RECIPE_SHOPPING_ITEM_BEGIN_VIEW_RECIPE_PAGE,
  REMOVE_RECIPE_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE,
  REMOVE_RECIPE_SHOPPING_ITEM_ERROR_VIEW_RECIPE_PAGE,
  UPDATE_SHOPPING_ITEM_RECIPE_QUANTITY_SUCCESS,
  UPDATE_SHOPPING_ITEM_RECIPE_QUANTITY_ERROR,
  CHECK_BOX_SHOPPING_ITEM_BEGIN_VIEW_RECIPE_PAGE,
  CHECK_BOX_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE,
  CHECK_BOX_SHOPPING_ITEM_ERROR_VIEW_RECIPE_PAGE,
  ADD_RECIPE_SHOPPING_ITEM_BEGIN_VIEW_RECIPE_PAGE,
  ADD_RECIPE_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE,
  ADD_RECIPE_SHOPPING_ITEM_ERROR_VIEW_RECIPE_PAGE,
} from "./actions";

const token = localStorage.getItem("token");
const user = localStorage.getItem("user");
const userAddress = localStorage.getItem("address");

const initialState = {
  isLoading: false,
  // isLoading: true,
  showAlert: false,
  alertText: "",
  alertType: "",
  redirectFrom: "",
  redirectTo: "",
  user: user ? JSON.parse(user) : null,
  token: token,
  userAddress: userAddress || "",
  isEditing: false,
  editRecipeId: "",
  // "recipe: null" is my test to format the initial state like "user: user"
  recipe: null,
  recipeName: "",
  recipeChosenInWeeklyMenu: false,
  recipeRating: "",
  recipeReviewCount: "",
  recipeSource: "",
  recipeSourceUrl: "",
  recipeFeaturedCategory: "",
  recipeFeaturedMedia: "",
  recipeInstagramUrl: "",
  recipeFeaturedCategories: [
    "",
    "Hall of Fame",
    "Top 1%",
    "Top 10%",
    "High Protein",
    "Easy",
    "Other",
  ],
  recipeTotalTime: null,
  recipeCookTime: null,
  recipeActiveTime: null,
  recipePrepTime: null,
  recipeCoolTime: null,
  recipeFreezeTime: null,
  recipeMarinateTime: null,
  recipeRestTime: null,
  recipeSoakTime: null,
  recipeDescription: "",
  recipeCuisine: "",
  recipeCuisines: [
    "",
    "American",
    // "Amish and Mennonite",
    // "Argentinian",
    // "Australian and New Zealander",
    // "Austrian",
    // "Bangladeshi",
    // "Belgian",
    // "Brazilian",
    // "Cajun and Creole",
    // "Canadian",
    // "Chilean",
    "Chinese",
    // "Colombian",
    // "Cuban",
    // "Danish",
    // "Dutch",
    "Filipino",
    // "Finnish",
    // "French",
    // "German",
    "Greek",
    // "Hawaiian",
    "Indian",
    // "Indonesian",
    // "Israeli",
    "Italian",
    // "Jamaican",
    "Japanese",
    // "Jewish",
    // "Korean",
    // "Lebanese",
    // "Malaysian",
    // "Mediterranean",
    "Mexican",
    // "Norwegian",
    // "Pakistani",
    // "Persian",
    // "Peruvian",
    // "Polish",
    // "Portuguese",
    // "Puerto Rican",
    // "Russian",
    // "Scandinavian",
    // "Soul Food",
    // "South African",
    // "Southern Recipes",
    // "Spanish",
    // "Swedish",
    // "Swiss",
    "Tex-Mex",
    // "Thai",
    // "Turkish",
    // "Vietnamese",
    "Other",
  ],
  recipeMealType: "",
  recipeMealTypes: [
    "",
    "Appetizer",
    "Breakfast",
    "Dessert",
    "Drink",
    "Mostly meat",
    "Protein shake",
    "Salad",
    "Sandwich",
    "Pasta",
    "Soup",
    "Other",
  ],
  recipeMeatTypes: [
    "",
    "beef",
    "lamb",
    "pork",
    "goat",
    "chicken",
    "turkey",
    "duck",
    "goose",
    "fish",
    "prawns",
    "crab",
    "lobster",
    "mussels",
    "oysters",
    "scallops",
    "clams",
    "squid",
    "octopus",
    "salmon",
    "tofu",
    "vegetarian",
    "Other",
  ],
  recipeCourseType: "",
  recipeCourseTypes: [
    "",
    "Appetizer",
    "Side dish",
    "Main dish",
    "Snack",
    "Dessert",
    "Other",
  ],
  recipeMeatType: "",
  personalizedYield: "",
  recipeYield: "",
  // recipeShoppingItems: [
  //   {
  //     quantity: "",
  //     unit: "",
  //     name: "",
  //   },
  //   {
  //     quantity: "",
  //     unit: "",
  //     name: "",
  //   },
  // ],

  recipeShoppingItems: [],
  recipeCalories: null,
  recipeCarbohydrates: null,
  recipeFat: null,
  recipeProtein: null,
  recipeSodium: null,
  recipeUtensils: [],
  recipeVideo: "",
  recipeInstructions: [],
  recipeNotes: "",
  recipeCreatedBy: "",
  jobAddress: userAddress || "",
  // recipes: [],
  // Discover Pages
  discoverSelectedPage: "/my/discover/menu",
  // Search & Collection Page
  SortOptions: ["Latest", "Oldest"],
  // Search page
  searchRecipes: [],
  searchTotalRecipes: 0,
  searchNumOfPages: 1,
  searchCurrentPage: 1,
  searchRecipeName: "",
  searchRecipeSource: "",
  searchRecipeSourceUrl: "",
  searchRecipeTotalTime: "",
  searchRecipeCookTime: "",
  searchRecipeMarinateTime: "",
  searchRecipeRestTime: "",
  searchRecipeFeaturedCategory: "",
  searchRecipeCourseType: "",
  searchRecipeCuisine: "",
  searchRecipeMealType: "",
  searchRecipeMeatType: "",
  searchRecipeYield: "",
  searchRecipeCalories: "",
  searchRecipeCarbohydrates: "",
  searchRecipeFat: "",
  searchRecipeProtein: "",
  searchRecipeVideo: "",
  searchRecipeSortOption: "Latest",
  searchRecipeSortOptions: ["Latest", "Oldest"],
  // Collection page
  collectionRecipes: [],
  collectionTotalRecipes: 0,
  collectionNumOfPages: 1,
  collectionCurrentPage: 1,
  collectionRecipeName: "",
  collectionRecipeSource: "",
  collectionRecipeSourceUrl: "",
  collectionRecipeTotalTime: "",
  collectionRecipeCookTime: "",
  collectionRecipeMarinateTime: "",
  collectionRecipeRestTime: "",
  collectionRecipeFeaturedCategory: "",
  collectionRecipeCourseType: "",
  collectionRecipeCuisine: "",
  collectionRecipeMealType: "",
  collectionRecipeMeatType: "",
  collectionRecipeYield: "",
  collectionRecipeCalories: "",
  collectionRecipeCarbohydrates: "",
  collectionRecipeFat: "",
  collectionRecipeProtein: "",
  collectionRecipeVideo: "",
  collectionRecipeSortOption: "Latest",
  // Uncategorized
  recipeEditable: false,
  week: currentWeek(),
  menuId: "",
  // menu
  menuRecipes: [],
  menu: false,
  // Meal Plan
  mealPlanRecipes: [],
  mealPlanSchedule: [],
  mealPlanServingSelection: {},
  mealPlanMacrosOrSummaryView: "summary",
  mealPlanMacroSelectedMember: "",
  // Shopping List
  shoppingList: [],
  shoppingListGroupCategory: "recipe",
  shoppingListHideCheckedShoppingItem: true,
  shoppingItem: {
    quantity: "",
    unit: "",
    name: "",
    aisle: "",
    note: "",
  },
};

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Axios
  const authFetch = axios.create({
    baseURL: "/api/v1",
  });

  // request
  authFetch.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = `Bearer ${state.token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // response
  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        console.log(
          "getSearchPageRecipes authFetch.interceptors.response.use ****************************************"
        );
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const displayAlert = (msg) => {
    dispatch({
      type: DISPLAY_ALERT,
      payload: { msg: msg },
    });
    clearAlert();
  };

  // const clearAlert = () => {
  //   setTimeout(() => {
  //     dispatch({ type: CLEAR_ALERT });
  //   }, 1);
  // };

  const clearAlert = () => {
    setTimeout(() => {
      dispatch({ type: CLEAR_ALERT });
    }, 3000);
  };

  // const beginRedirect = (msg) => {
  //   dispatch({
  //     type: BEGIN_REDIRECT,
  //     payload: {
  //       redirectFrom: "/my/create-recipe-or-food",
  //       redirectTo: "/my/serving/add",
  //     },
  //   });
  //   endRedirect();
  // };

  const endRedirect = () => {
    setTimeout(() => {
      dispatch({ type: END_REDIRECT });
    }, 500);
  };

  const addUserToLocalStorage = ({ user, token, address }) => {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("token", token);
    localStorage.setItem("address", address);
  };

  const removeUserFromLocalStorage = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("address");
  };

  const registerUser = async (currentUser) => {
    dispatch({ type: REGISTER_USER_BEGIN });
    try {
      const response = await axios.post("/api/v1/auth/register", currentUser);
      const { user, token, address } = response.data;
      dispatch({
        type: REGISTER_USER_SUCCESS,
        payload: { user, token, address },
      });

      addUserToLocalStorage({ user, token, address });
    } catch (error) {
      dispatch({
        type: REGISTER_USER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }

    clearAlert();
  };

  const loginUser = async (currentUser) => {
    dispatch({ type: LOGIN_USER_BEGIN });
    try {
      const { data } = await axios.post("/api/v1/auth/login", currentUser);

      const { user, token, address } = data;
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: { user, token, address },
      });

      localStorage.setItem("e", user.email);
      addUserToLocalStorage({ user, token, address });
    } catch (error) {
      dispatch({
        type: LOGIN_USER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }

    clearAlert();
  };

  const logoutUser = () => {
    dispatch({ type: LOGOUT_USER });
    removeUserFromLocalStorage();
  };

  const updateUser = async (currentUser) => {
    dispatch({ type: UPDATE_USER_BEGIN });
    try {
      const { data } = await authFetch.patch("/auth/updateUser", currentUser);
      const { user, address, token } = data;
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: { user, address, token },
      });
      addUserToLocalStorage({ user, address, token });
    } catch (error) {
      if (error.response.status !== 401) {
        dispatch({
          type: UPDATE_USER_ERROR,
          payload: { msg: error.response.data.msg },
        });
      }
    }
    clearAlert();
  };

  const handleChange = ({ name, value }) => {
    console.log("name");
    console.log(name);
    console.log("value");
    console.log(value);

    dispatch({ type: HANDLE_CHANGE, payload: { name, value } });
  };

  const handleChangeLevelTwo = ({ name, value, levelOneKey }) => {
    dispatch({
      type: HANDLE_CHANGE_LEVEL_TWO,
      payload: { name, value, levelOneKey },
    });
  };

  const clearValues = () => {
    dispatch({ type: CLEAR_VALUES });
  };

  // function to reuse CreateRecipe
  async function createRecipeRequest() {
    const {
      recipeName: name,
      recipeRating: rating,
      recipeReviewCount: reviewCount,
      recipeSource: source,
      recipeSourceUrl: sourceUrl,
      recipeFeaturedMedia: featuredMedia,
      recipeInstagramUrl: instagramUrl,
      recipeFeaturedCategory: featuredCategory,
      recipeTotalTime: totalTime,
      recipeCookTime: cookTime,
      recipeActiveTime: activeTime,
      recipePrepTime: prepTime,
      recipeCoolTime: coolTime,
      recipeFreezeTime: freezeTime,
      recipeMarinateTime: marinateTime,
      recipeRestTime: restTime,
      recipeSoakTime: soakTime,
      recipeDescription: description,
      recipeCuisine: cuisine,
      recipeMealType: mealType,
      recipeCourseType: courseType,
      recipeMeatType: meatType,
      personalizedYield: personalizedYield,
      recipeYield: recipeYield,
      recipeShoppingItems: shoppingItems,
      recipeCalories: calories,
      recipeCarbohydrates: carbohydrates,
      recipeFat: fat,
      recipeProtein: protein,
      recipeSodium: sodium,
      recipeUtensils: utensils,
      recipeVideo: video,
      recipeNotes: notes,
      recipeInstructions: instructions,
    } = state;

    const createdRecipe = await authFetch.post("/recipes", {
      name,
      rating,
      reviewCount,
      source,
      sourceUrl,
      featuredMedia,
      instagramUrl,
      featuredCategory,
      totalTime,
      cookTime,
      activeTime,
      prepTime,
      coolTime,
      freezeTime,
      marinateTime,
      restTime,
      soakTime,
      description,
      cuisine,
      mealType,
      meatType,
      courseType,
      personalizedYield,
      recipeYield,
      shoppingItems,
      calories,
      carbohydrates,
      fat,
      protein,
      sodium,
      utensils,
      video,
      notes,
      instructions,
    });

    return createdRecipe;
  }

  const createRecipe = async () => {
    dispatch({ type: CREATE_RECIPE_BEGIN });
    try {
      const newRecipe = createRecipeRequest();

      dispatch({ type: CREATE_RECIPE_SUCCESS });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      // if not 401, do the following
      dispatch({
        type: CREATE_RECIPE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const clearRecipeValues = () => {
    // I need this time delay since when click the RecipeViewPage back button, it tirggers the clear values so fast that it makes the useEffect run again so the values get cleared and then re-populated with the recipe
    setTimeout(() => {
      dispatch({ type: CLEAR_RECIPE_VALUES });
    }, 1000);
  };

  const removeShoppingItemAddRecipePage = (e) => {
    const { recipeShoppingItems } = state;

    let updatedRecipeShoppingItems = [];

    for (let i = 0; i < recipeShoppingItems.length; i++) {
      if (i !== e.target.index) {
        updatedRecipeShoppingItems.push(recipeShoppingItems[i]);
      }
    }

    dispatch({
      type: REMOVE_SHOPPING_ITEM_BEGIN_RECIPE_PAGE,
      payload: {
        updatedRecipeShoppingItems,
      },
    });
  };

  const handleChangeShoppingItemRecipePage = (e) => {
    const { recipeShoppingItems } = state;

    // creating new array to avoid mutation
    let updatedRecipeShoppingItems = [];

    for (let i = 0; i < recipeShoppingItems.length; i++) {
      updatedRecipeShoppingItems.push(recipeShoppingItems[i]);
    }

    // updating the shopping item using index since shopping _ids might not exist.
    if (e.target.name === "unit") {
      updatedRecipeShoppingItems[e.target.index][e.target.name] =
        e.target.value;
    } else if (e.target.name === "item") {
      updatedRecipeShoppingItems[e.target.i][e.target.name] = e.target.value;
    } else {
      updatedRecipeShoppingItems[e.target.index][e.target.name] =
        e.target.value;
    }

    dispatch({
      type: HANDLE_CHANGE_SHOPPING_ITEM_RECIPE_PAGE,
      payload: {
        updatedRecipeShoppingItems,
      },
    });
  };

  const addInscructionStep = (e) => {
    const { recipeInstructions } = state;

    let updatedRecipeInstructions = [];

    for (let i = 0; i < recipeInstructions.length; i++) {
      updatedRecipeInstructions.push(recipeInstructions[i]);
    }

    const newField = {
      media: "",
      description: "",
    };

    updatedRecipeInstructions.push(newField);

    dispatch({
      type: ADD_INSTRUCTION_STEP,
      payload: {
        updatedRecipeInstructions,
      },
    });
  };

  const removeInstructionStep = (e) => {
    const { recipeInstructions } = state;

    let updatedRecipeInstructions = [];

    for (let i = 0; i < recipeInstructions.length; i++) {
      if (i !== e.target.index) {
        updatedRecipeInstructions.push(recipeInstructions[i]);
      }
    }

    dispatch({
      type: REMOVE_INSTRUCTION_STEP,
      payload: {
        updatedRecipeInstructions,
      },
    });
  };

  const handleChangeInstructions = (e) => {
    const { recipeInstructions } = state;

    let updatedRecipeInstructions = [];

    for (let i = 0; i < recipeInstructions.length; i++) {
      updatedRecipeInstructions.push(recipeInstructions[i]);
    }

    updatedRecipeInstructions[e.target.index][e.target.name] = e.target.value;

    dispatch({
      type: HANDLE_CHANGE_INSTRUCTION_CHANGE_ADD_RECIPE,
      payload: {
        updatedRecipeInstructions,
      },
    });
  };

  const addUtensil = (e) => {
    const { recipeUtensils } = state;

    let updatedRecipeUtensils = [];

    for (let i = 0; i < recipeUtensils.length; i++) {
      updatedRecipeUtensils.push(recipeUtensils[i]);
    }

    const newField = {
      utensil: "",
      quantity: "",
    };

    updatedRecipeUtensils.push(newField);

    dispatch({
      type: ADD_UTENSIL,
      payload: {
        updatedRecipeUtensils,
      },
    });
  };

  const removeUtensil = (e) => {
    const { recipeUtensils } = state;

    let updatedRecipeUtensils = [];

    for (let i = 0; i < recipeUtensils.length; i++) {
      if (i !== e.target.index) {
        updatedRecipeUtensils.push(recipeUtensils[i]);
      }
    }

    dispatch({
      type: REMOVE_UTENSIL,
      payload: {
        updatedRecipeUtensils,
      },
    });
  };

  const handleChangeUtensil = (e) => {
    const { recipeUtensils } = state;

    let updatedRecipeUtensils = [];

    for (let i = 0; i < recipeUtensils.length; i++) {
      updatedRecipeUtensils.push(recipeUtensils[i]);
    }

    updatedRecipeUtensils[e.target.index][e.target.name] = e.target.value;

    dispatch({
      type: HANDLE_CHANGE_UTENSIL_CHANGE_ADD_RECIPE,
      payload: {
        updatedRecipeUtensils,
      },
    });
  };

  // const handleChangeMemberInfo = (e) => {
  //   if (
  //     e.target.name === "calories" ||
  //     e.target.name === "protein" ||
  //     e.target.name === "carbohydrates" ||
  //     e.target.name === "fat"
  //   ) {
  //     if (e.target.value == "" || e.target.value > 0) {
  //       const { user } = state;

  //       let members = user.members;

  //       let updatedMembers = [];

  //       for (let i = 0; i < members.length; i++) {
  //         updatedMembers.push(members[i]);
  //       }

  //       updatedMembers[e.target.index][e.target.name] = e.target.value;

  //       dispatch({
  //         type: HANDLE_CHANGE_MEMBER_INFO,
  //         payload: {
  //           updatedMembers,
  //         },
  //       });
  //     } else if (e.target.value <= 0) {
  //       displayAlert("Macros have to be greater than zero");
  //     }
  //   } else {

  //   }
  // };

  const handleChangeMemberInfo = (e) => {
    function updateMemberField() {
      const { user } = state;

      let members = user.members;

      let updatedMembers = [];

      for (let i = 0; i < members.length; i++) {
        updatedMembers.push(members[i]);
      }

      updatedMembers[e.target.index][e.target.name] = e.target.value;

      dispatch({
        type: HANDLE_CHANGE_MEMBER_INFO,
        payload: {
          updatedMembers,
        },
      });
    }

    if (
      e.target.name === "calories" ||
      e.target.name === "protein" ||
      e.target.name === "carbohydrates" ||
      e.target.name === "fat"
    ) {
      if (e.target.value == "" || e.target.value > 0) {
        updateMemberField();
      } else if (e.target.value <= 0) {
        displayAlert("Macros have to be greater than zero");
      }
    } else {
      // this mean the e.target.name is firstName or LastName
      updateMemberField();
    }
  };

  const getRecipe = async (recipeId) => {
    dispatch({ type: GET_RECIPE_BEGIN });

    try {
      const { data } = await authFetch.get(`/recipes/${recipeId}`);
      const recipe = data.recipe;

      dispatch({
        type: GET_RECIPE_SUCCESS,
        payload: {
          recipe,
        },
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  // Not used anymore, but keep it here for reference since I really like the logic
  // const setEditRecipe = (id) => {
  //   dispatch({ type: SET_EDIT_RECIPE, payload: { id } });
  // };

  // function to reuse editRecipeRequest
  async function editRecipeRequest() {
    const {
      recipeName: name,
      recipeRating: rating,
      recipeReviewCount: reviewCount,
      recipeSource: source,
      recipeSourceUrl: sourceUrl,
      recipeFeaturedMedia: featuredMedia,
      recipeInstagramUrl: instagramUrl,
      recipeFeaturedCategory: featuredCategory,
      recipeTotalTime: totalTime,
      recipeCookTime: cookTime,
      recipeActiveTime: activeTime,
      recipePrepTime: prepTime,
      recipeCoolTime: coolTime,
      recipeFreezeTime: freezeTime,
      recipeMarinateTime: marinateTime,
      recipeRestTime: restTime,
      recipeSoakTime: soakTime,
      recipeDescription: description,
      recipeCuisine: cuisine,
      recipeMealType: mealType,
      recipeCourseType: courseType,
      recipeMeatType: meatType,
      recipeYield: recipeYield,
      personalizedYield: personalizedYield,
      recipeShoppingItems: shoppingItems,
      recipeCalories: calories,
      recipeCarbohydrates: carbohydrates,
      recipeFat: fat,
      recipeProtein: protein,
      recipeSodium: sodium,
      recipeUtensils: utensils,
      recipeVideo: video,
      recipeNotes: notes,
      recipeInstructions: instructions,
    } = state;

    const editRecipeRequest = await authFetch.patch(
      `/recipes/${state.editRecipeId}`,
      {
        name,
        rating,
        reviewCount,
        source,
        sourceUrl,
        featuredMedia,
        instagramUrl,
        featuredCategory,
        totalTime,
        cookTime,
        activeTime,
        prepTime,
        coolTime,
        freezeTime,
        marinateTime,
        restTime,
        soakTime,
        description,
        cuisine,
        mealType,
        meatType,
        courseType,
        personalizedYield,
        recipeYield,
        shoppingItems,
        calories,
        carbohydrates,
        fat,
        protein,
        sodium,
        utensils,
        video,
        notes,
        instructions,
      }
    );

    return editRecipeRequest;
  }

  const editRecipe = async () => {
    dispatch({ type: EDIT_RECIPE_BEGIN });
    try {
      const result = await editRecipeRequest();

      // const {
      //   recipeName: name,
      //   recipeRating: rating,
      //   recipeReviewCount: reviewCount,
      //   recipeSource: source,
      //   recipeSourceUrl: sourceUrl,
      //   recipeFeaturedMedia: featuredMedia,
      //   recipeInstagramUrl: instagramUrl,
      //   recipeFeaturedCategory: featuredCategory,
      //   recipeTotalTime: totalTime,
      //   recipeCookTime: cookTime,
      //   recipeActiveTime: activeTime,
      //   recipePrepTime: prepTime,
      //   recipeCoolTime: coolTime,
      //   recipeFreezeTime: freezeTime,
      //   recipeMarinateTime: marinateTime,
      //   recipeRestTime: restTime,
      //   recipeSoakTime: soakTime,
      //   recipeDescription: description,
      //   recipeCuisine: cuisine,
      //   recipeMealType: mealType,
      //   recipeCourseType: courseType,
      //   recipeMeatType: meatType,
      //   personalizedYield,
      //   recipeYield,
      //   recipeShoppingItems: shoppingItems,
      //   recipeCalories: calories,
      //   recipeCarbohydrates: carbohydrates,
      //   recipeFat: fat,
      //   recipeProtein: protein,
      //   recipeSodium: sodium,
      //   recipeUtensils: utensils,
      //   recipeVideo: video,
      //   recipeNotes: notes,
      //   recipeInstructions: instructions,
      // } = state;

      // const { data } = await authFetch.patch(`/recipes/${state.editRecipeId}`, {
      //   name,
      //   rating,
      //   reviewCount,
      //   source,
      //   sourceUrl,
      //   featuredMedia,
      //   instagramUrl,
      //   featuredCategory,
      //   totalTime,
      //   cookTime,
      //   prepTime,
      //   coolTime,
      //   freezeTime,
      //   marinateTime,
      //   restTime,
      //   soakTime,
      //   activeTime,
      //   description,
      //   cuisine,
      //   mealType,
      //   courseType,
      //   meatType,
      //   personalizedYield,
      //   recipeYield,
      //   shoppingItems,
      //   calories,
      //   carbohydrates,
      //   fat,
      //   protein,
      //   sodium,
      //   video,
      //   notes,
      //   utensils,
      //   instructions,
      // });

      const recipe = result.data.updatedRecipe;

      dispatch({
        type: EDIT_RECIPE_SUCCESS,
        payload: {
          recipe,
        },
      });

      // dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      // if not 401, like 400, do the following
      dispatch({
        type: EDIT_RECIPE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  // const editRecipePersonalizedYield = async (e) => {
  //   dispatch({ type: EDIT_RECIPE_BEGIN });
  //   try {
  //     const {
  //       recipeName: name,
  //       recipeRating: rating,
  //       recipeReviewCount: reviewCount,
  //       recipeSource: source,
  //       recipeSourceUrl: sourceUrl,
  //       recipeFeaturedMedia: featuredMedia,
  //       recipeInstagramUrl: instagramUrl,
  //       recipeFeaturedCategory: featuredCategory,
  //       recipeTotalTime: totalTime,
  //       recipeCookTime: cookTime,
  //       recipeActiveTime: activeTime,
  //       recipePrepTime: prepTime,
  //       recipeCoolTime: coolTime,
  //       recipeFreezeTime: freezeTime,
  //       recipeMarinateTime: marinateTime,
  //       recipeRestTime: restTime,
  //       recipeSoakTime: soakTime,
  //       recipeDescription: description,
  //       recipeCuisine: cuisine,
  //       recipeMealType: mealType,
  //       recipeCourseType: courseType,
  //       recipeMeatType: meatType,
  //       personalizedYield,
  //       recipeYield,
  //       recipeShoppingItems: shoppingItems,
  //       recipeCalories: calories,
  //       recipeCarbohydrates: carbohydrates,
  //       recipeFat: fat,
  //       recipeProtein: protein,
  //       recipeSodium: sodium,
  //       recipeVideo: video,
  //       recipeUtensils: utensils,
  //       recipeInstructions: instructions,
  //     } = state;

  //     let value = e.target.value;

  //     // to do: find a way to display the serving multiplier somehow
  //     // if (typeof e.target.value == "string") {
  //     //   if (value.includes("⠀(1x)")) {
  //     //     value = value.replace("⠀(1x)", "");
  //     //   }
  //     // }

  //     const { data } = await authFetch.patch(`/recipes/${state.editRecipeId}`, {
  //       name,
  //       rating,
  //       reviewCount,
  //       source,
  //       sourceUrl,
  //       featuredMedia,
  //       instagramUrl,
  //       featuredCategory,
  //       totalTime,
  //       cookTime,
  //       prepTime,
  //       coolTime,
  //       freezeTime,
  //       marinateTime,
  //       restTime,
  //       soakTime,
  //       activeTime,
  //       description,
  //       cuisine,
  //       mealType,
  //       courseType,
  //       meatType,
  //       personalizedYield: value,
  //       recipeYield,
  //       shoppingItems,
  //       calories,
  //       carbohydrates,
  //       fat,
  //       protein,
  //       sodium,
  //       video,
  //       utensils,
  //       instructions,
  //     });

  //     const recipe = data.updatedRecipe;

  //     dispatch({
  //       type: EDIT_RECIPE_SUCCESS,
  //       payload: {
  //         recipe,
  //       },
  //     });

  //     // dispatch({ type: CLEAR_VALUES });
  //   } catch (error) {
  //     if (error.response.status === 401) return;
  //     // if not 401, like 400, do the following
  //     dispatch({
  //       type: EDIT_RECIPE_ERROR,
  //       payload: { msg: error.response.data.msg },
  //     });
  //   }
  //   clearAlert();
  // };

  const editRecipeAfterEveryClick = async ({ name: key, value }) => {
    // bug: ionOnChange in the shoppingItem checkbox, if I remove this line I get an error on pageview first load
    if (state.editRecipeId !== "") {
      dispatch({ type: HANDLE_CHANGE, payload: { key, value } });

      dispatch({ type: EDIT_RECIPE_BEGIN });
      try {
        let {
          recipeName: name,
          recipeRating: rating,
          recipeReviewCount: reviewCount,
          recipeSource: source,
          recipeSourceUrl: sourceUrl,
          recipeFeaturedMedia: featuredMedia,
          recipeInstagramUrl: instagramUrl,
          recipeFeaturedCategory: featuredCategory,
          recipeTotalTime: totalTime,
          recipeCookTime: cookTime,
          recipeActiveTime: activeTime,
          recipePrepTime: prepTime,
          recipeCoolTime: coolTime,
          recipeFreezeTime: freezeTime,
          recipeMarinateTime: marinateTime,
          recipeRestTime: restTime,
          recipeSoakTime: soakTime,
          recipeDescription: description,
          recipeCuisine: cuisine,
          recipeMealType: mealType,
          recipeCourseType: courseType,
          recipeMeatType: meatType,
          personalizedYield,
          recipeYield,
          recipeShoppingItems: shoppingItems,
          recipeCalories: calories,
          recipeCarbohydrates: carbohydrates,
          recipeFat: fat,
          recipeProtein: protein,
          recipeSodium: sodium,
          recipeVideo: video,
          recipeNotes: notes,
          recipeUtensils: utensils,
          recipeInstructions: instructions,
          recipeChosenInWeeklyMenu,
        } = state;

        if (key === "personalizedYield") {
          personalizedYield = value;
        }

        const { data } = await authFetch.patch(
          `/recipes/${state.editRecipeId}`,
          {
            name,
            rating,
            reviewCount,
            source,
            sourceUrl,
            featuredMedia,
            instagramUrl,
            featuredCategory,
            totalTime,
            cookTime,
            prepTime,
            coolTime,
            freezeTime,
            marinateTime,
            restTime,
            soakTime,
            activeTime,
            description,
            cuisine,
            mealType,
            courseType,
            meatType,
            personalizedYield,
            recipeYield,
            shoppingItems,
            calories,
            carbohydrates,
            fat,
            protein,
            sodium,
            video,
            notes,
            utensils,
            instructions,
            recipeChosenInWeeklyMenu,
          }
        );

        const recipe = data.updatedRecipe;

        dispatch({
          type: EDIT_RECIPE_SUCCESS,
          payload: {
            recipe,
          },
        });
      } catch (error) {
        if (error.response.status === 401) return;
        // if not 401, like 400, do the following
        dispatch({
          type: EDIT_RECIPE_ERROR,
          payload: { msg: error.response.data.msg },
        });
      }
      clearAlert();
    }
  };

  const setRecipeEditMenu = async ({
    recipeId,
    recipeChosenInWeeklyMenu,
    personalizedYield,
  }) => {
    try {
      const { data } = await authFetch.patch(
        `/menu/selection/${state.menuId}`,
        {
          recipeId: recipeId,
          recipeChosenInWeeklyMenu: recipeChosenInWeeklyMenu,
          personalizedYield: personalizedYield,
        }
      );
      const { menu } = data;

      dispatch({
        type: GET_MENU_SUCCESS,
        payload: {
          recipes: menu.recipes,
          week: menu.week,
          menuId: menu._id,
        },
      });
    } catch (error) {
      if (error.response.status === 401) return;
      // if not 401, like 400, do the following
      dispatch({
        type: EDIT_RECIPE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const deleteRecipe = async (recipeId, name, page) => {
    dispatch({ type: DELETE_RECIPE_BEGIN });

    try {
      await authFetch.delete(`/recipes/${recipeId}`);
      if (page === "isCollectionPage") {
        console.log("page === isCollectionPage");
        getCollectionPageRecipes();
      } else {
        getSearchPageRecipes();
      }
      dispatch({ type: DELETE_RECIPE_SUCCESS, payload: { name } });
    } catch (error) {
      console.log(error.response);

      logoutUser();
    }
  };

  ////////////////////////////////////// MENU PAGE //////////////////////////////////////

  const getMenu = async () => {
    let { week } = state;

    let url = `/menu?week=${week}`;

    dispatch({ type: GET_MENU_BEGIN });

    try {
      const { data } = await authFetch.get(url);
      const { menu } = data;

      dispatch({
        type: GET_MENU_SUCCESS,
        payload: {
          recipes: menu.recipes,
          week: menu.week,
          menuId: menu._id,
        },
      });
    } catch (error) {
      // if not 401, like 400, do the following
      dispatch({
        type: EDIT_RECIPE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }

    // The MERN clears the alert since it takes 3 seconds for it to go away and it's not necessary.
    clearAlert();
  };

  ////////////////////////////////////// SEARCH PAGE //////////////////////////////////////

  const getSearchPageRecipes = async (myRecipesOnly) => {
    const {
      searchCurrentPage,
      searchRecipeName,
      searchRecipeSource,
      searchRecipeSourceUrl,
      searchRecipeTotalTime,
      searchRecipeCookTime,
      searchRecipeMarinateTime,
      searchRecipeRestTime,
      searchRecipeFeaturedCategory,
      searchRecipeCourseType,
      searchRecipeCuisine,
      searchRecipeMealType,
      searchRecipeMeatType,
      searchRecipeYield,
      searchRecipeCalories,
      searchRecipeCarbohydrates,
      searchRecipeFat,
      searchRecipeProtein,
      searchRecipeVideo,
      searchRecipeSortOption,
    } = state;

    // // myRecipesOnlyValue is to limit the getSearchPageRecipes to only the recipes created by the user
    // let myRecipesOnlyValue = false;

    // if (myRecipesOnly) {
    //   if (myRecipesOnly.myRecipesOnly === true) myRecipesOnlyValue = true;
    // }

    let url = `/recipes?page=${searchCurrentPage}&source=${searchRecipeSource}&sourceUrl=${searchRecipeSourceUrl}&totalTime=${searchRecipeTotalTime}&cookTime=${searchRecipeCookTime}&marinateTime=${searchRecipeMarinateTime}&restTime=${searchRecipeRestTime}&featuredCategory=${searchRecipeFeaturedCategory}&courseType=${searchRecipeCourseType}&cuisine=${searchRecipeCuisine}&mealType=${searchRecipeMealType}&meatType=${searchRecipeMeatType}&recipeYield=${searchRecipeYield}&calories=${searchRecipeCalories}&carbohydrates=${searchRecipeCarbohydrates}&fat=${searchRecipeFat}&protein=${searchRecipeProtein}&video=${searchRecipeVideo}&sort=${searchRecipeSortOption}&myRecipesOnly=${false}`;

    if (searchRecipeName) {
      url = url + `&name=${searchRecipeName}`;
    }

    dispatch({ type: GET_SEARCH_PAGE_RECIPES_BEGIN });

    try {
      const { data } = await authFetch.get(url);
      const { recipes, totalRecipes, numOfPages } = data;
      dispatch({
        type: GET_SEARCH_PAGE_RECIPES_SUCCESS,
        payload: {
          recipes,
          totalRecipes,
          numOfPages,
        },
      });
    } catch (error) {
      // console.log(error.response);
      // It's in the MERN course opinion, to logout user here if they get a 500 error
      console.log(
        "getSearchPageRecipes logoutUser ****************************************"
      );
      logoutUser();
    }

    // The MERN clears the alert since it takes 3 seconds for it to go away and it's not necessary.
    clearAlert();
  };

  const clearSearchFilters = () => {
    dispatch({ type: SEARCH_CLEAR_FILTERS });
  };

  ////////////////////////////////////// COLLECTION PAGE //////////////////////////////////////

  const getCollectionPageRecipes = async (myRecipesOnly) => {
    const {
      collectionCurrentPage,
      collectionRecipeName,
      collectionRecipeSource,
      collectionRecipeSourceUrl,
      collectionRecipeTotalTime,
      collectionRecipeCookTime,
      collectionRecipeMarinateTime,
      collectionRecipeRestTime,
      collectionRecipeFeaturedCategory,
      collectionRecipeCourseType,
      collectionRecipeCuisine,
      collectionRecipeMealType,
      collectionRecipeMeatType,
      collectionRecipeYield,
      collectionRecipeCalories,
      collectionRecipeCarbohydrates,
      collectionRecipeFat,
      collectionRecipeProtein,
      collectionRecipeVideo,
      collectionRecipeSortOption,
    } = state;

    // // myRecipesOnlyValue is to limit the getSearchPageRecipes to only the recipes created by the user
    // let myRecipesOnlyValue = false;

    // if (myRecipesOnly) {
    //   if (myRecipesOnly.myRecipesOnly === true) myRecipesOnlyValue = true;
    // }

    let url = `/recipes?page=${collectionCurrentPage}&source=${collectionRecipeSource}&sourceUrl=${collectionRecipeSourceUrl}&totalTime=${collectionRecipeTotalTime}&cookTime=${collectionRecipeCookTime}&marinateTime=${collectionRecipeMarinateTime}&restTime=${collectionRecipeRestTime}&featuredCategory=${collectionRecipeFeaturedCategory}&courseType=${collectionRecipeCourseType}&cuisine=${collectionRecipeCuisine}&mealType=${collectionRecipeMealType}&meatType=${collectionRecipeMeatType}&recipeYield=${collectionRecipeYield}&calories=${collectionRecipeCalories}&carbohydrates=${collectionRecipeCarbohydrates}&fat=${collectionRecipeFat}&protein=${collectionRecipeProtein}&video=${collectionRecipeVideo}&sort=${collectionRecipeSortOption}&myRecipesOnly=${true}`;

    if (collectionRecipeName) {
      url = url + `&name=${collectionRecipeName}`;
    }

    dispatch({ type: GET_COLLECTION_PAGE_RECIPES_BEGIN });

    try {
      const { data } = await authFetch.get(url);

      const { recipes, totalRecipes, numOfPages } = data;

      dispatch({
        type: GET_COLLECTION_PAGE_RECIPES_SUCCESS,
        payload: {
          recipes,
          totalRecipes,
          numOfPages,
        },
      });
    } catch (error) {
      // console.log(error.response);
      // It's in the MERN course opinion, to logout user here if they get a 500 error
      logoutUser();
    }

    // The MERN clears the alert since it takes 3 seconds for it to go away and it's not necessary.
    clearAlert();
  };

  const clearCollectionFilters = () => {
    dispatch({ type: COLLECTION_CLEAR_FILTERS });
  };

  const createRecipeInCollectionPage = async () => {
    dispatch({
      type: CREATE_RECIPE_IN_COLLECTION_PAGE_BEGIN,
    });

    // const schedule = generateEmptySchedule(state.user.members);

    try {
      const result = await createRecipeRequest();

      const newRecipe = result.data.recipe;

      dispatch({
        type: CREATE_RECIPE_IN_COLLECTION_PAGE_SUCCESS,
        payload: { newRecipe },
      });
      getCollectionPageRecipes(); /////////////

      // beginRedirect();
      endRedirect(); //////////////// What do I do with this?
    } catch (error) {
      if (error.response.status === 401) return;
      // if not 401, do the following
      dispatch({
        type: CREATE_RECIPE_IN_COLLECTION_PAGE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const editRecipeCollectionPage = async (redirectFrom) => {
    dispatch({
      type: EDIT_RECIPE_COLLECTION_PAGE_BEGIN,
    });

    try {
      const result = await editRecipeRequest();

      const updatedRecipe = result.data.updatedRecipe;

      dispatch({
        type: EDIT_RECIPE_COLLECTION_PAGE_SUCCESS,
        payload: { updatedRecipe, redirectFrom },
      });

      getCollectionPageRecipes();

      endRedirect();
    } catch (error) {
      if (error.response.status === 401) return;
      // if not 401, do the following
      dispatch({
        type: EDIT_RECIPE_COLLECTION_PAGE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  ////////////////////////////////////// EDIT SERVING PAGE //////////////////////////////////////

  const setEditServingAddRecipe = (id, personalizedYield, name) => {
    const schedule = generateEmptySchedule(state.user.members);

    dispatch({
      type: SET_EDIT_SERVING_ADD_RECIPE,
      payload: { id, schedule, personalizedYield, name },
    });
  };

  const setEditServingUpdateServing = (id, personalizedYield, name) => {
    let checkboxOrNumberServingInput = "checkbox";

    const mealPlanSchedule = state.mealPlanSchedule;

    let generatedRecipeSchedule = [];

    // iterate through meal plan schedule to set the Serving Page
    for (const i in mealPlanSchedule) {
      const mealPlanScheduleMemberUserId = mealPlanSchedule[i].memberUserId;

      // create empty
      generatedRecipeSchedule.push({
        memberUserId: mealPlanScheduleMemberUserId,
        days: [[], [], [], [], [], [], []],
      });

      let mealPlanScheduleDays = mealPlanSchedule[i].days;

      for (const d in mealPlanScheduleDays) {
        let day = mealPlanScheduleDays[d];

        // for each recipeAndMealPlanYieldToAdd in day, add it
        for (const r in day) {
          // this case is to replace someting that is not there
          // if it's the selected ID, inded, and memberId, then, replace it
          if (day[r].recipe._id == id) {
            generatedRecipeSchedule[i].days[d].push(day[r]);

            if (day[r].serving > 1) {
              checkboxOrNumberServingInput = "number";
            }
          }
        }
      }
    }

    dispatch({
      type: SET_EDIT_SERVING_UPDATE_RECIPE,
      payload: {
        id,
        generatedRecipeSchedule,
        personalizedYield,
        name,
        checkboxOrNumberServingInput,
      },
    });
  };

  const handleChangeServingPageMeal = ({ name, value, index, levelOneKey }) => {
    const recipeSchedule = state.mealPlanServingSelection.assignment;
    let updatedRecipeSchedule = [];

    // begin updatedRecipeSchedule by making a copy and then updating the meal
    for (const i in recipeSchedule) {
      const recipeScheduleMemberUserId = recipeSchedule[i].memberUserId;

      // create empty
      updatedRecipeSchedule.push({
        memberUserId: recipeScheduleMemberUserId,
        days: [[], [], [], [], [], [], []],
      });

      let recipeScheduleDays = recipeSchedule[i].days;

      for (const d in recipeScheduleDays) {
        let day = recipeScheduleDays[d];
        /// check for each item in the day, then push it into the thing

        // for each recipeAndMealPlanYieldToAdd in day, add it, except if it's a match for the field being edited
        for (const r in day) {
          const recipeAndMealPlanYieldToAdd = day[r];

          // create new object
          let updatedRecipeAndMealPlanYieldToAdd = {
            ...recipeAndMealPlanYieldToAdd,
            meal: value,
          };

          // push the new object to the updatedRecipeSchedule
          updatedRecipeSchedule[i].days[d].push(
            updatedRecipeAndMealPlanYieldToAdd
          );
        }
      }
    }

    dispatch({
      type: HANDLE_CHANGE_EDIT_SERVINGS_PAGE_MEAL,
      payload: { name, value, levelOneKey, index, updatedRecipeSchedule },
    });
  };

  const handleChangeEditServingPageServingAllocation = ({
    name,
    value,
    memberId,
    index,
    levelOneKey,
  }) => {
    const meal = state.mealPlanServingSelection.meal;

    const recipeSchedule = state.mealPlanServingSelection.assignment;

    let updatedRecipeSchedule = [];

    let recipeAndMealPlanYieldToAdd = {
      serving: value,
      meal: meal,
      recipe: state.mealPlanServingSelection.recipeId,
    };

    // begin making a copy and then updating the servign allocation
    for (const i in recipeSchedule) {
      const recipeScheduleMemberUserId = recipeSchedule[i].memberUserId;

      // create empty
      updatedRecipeSchedule.push({
        memberUserId: recipeScheduleMemberUserId,
        days: [[], [], [], [], [], [], []],
      });

      let recipeScheduleDays = recipeSchedule[i].days;

      for (const d in recipeScheduleDays) {
        let day = recipeScheduleDays[d];
        /// check for each item in the day, then push it into the thing

        // for each recipeAndMealPlanYieldToAdd in day, add it, except if it's a match for the field being edited
        for (const r in day) {
          if (index == d && memberId == recipeScheduleMemberUserId) {
          } else {
            updatedRecipeSchedule[i].days[d].push(day[r]);
          }
        }

        // if this is the field being changed, add it
        if (index == d && memberId == recipeScheduleMemberUserId) {
          // If the value is blank, don't add it since it needs to be removed. Else, add recipeAndMealPlanYieldToAdd
          if (value !== "") {
            updatedRecipeSchedule[i].days[d].push(recipeAndMealPlanYieldToAdd);
          }
        }
      }
    }

    dispatch({
      type: HANDLE_CHANGE_EDIT_SERVINGS_PAGE_SERVING_ALLOCATION,
      payload: {
        name,
        value,
        levelOneKey,
        index,
        updatedRecipeSchedule,
      },
    });
  };

  ////////////////////////////////////// MEAL PLAN PAGE //////////////////////////////////////

  const getMealPlan = async () => {
    let { week } = state;

    let url = `/meal-plan?week=${week}`;

    dispatch({ type: GET_MEAL_PLAN_BEGIN });

    try {
      const { data } = await authFetch.get(url);
      const { mealPlan } = data;

      dispatch({
        type: GET_MEAL_PLAN_SUCCESS,
        payload: {
          mealPlanRecipes: mealPlan.recipes,
          mealPlanSchedule: mealPlan.schedule,
          week: mealPlan.week,
        },
      });
    } catch (error) {
      // if not 401, like 400, do the following
      dispatch({
        type: GENERIC_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }

    // The MERN clears the alert since it takes 3 seconds for it to go away and it's not necessary.
    clearAlert();
  };

  const createMealPlanRecipe = async () => {
    dispatch({ type: CREATE_MEAL_PLAN_RECIPE_BEGIN });

    try {
      const {
        recipeId,
        recipeServing,
        recipeYield,
        meal,
        assignment,
        name,
        week,
      } = state.mealPlanServingSelection;

      const accountUserId = state.user._id;

      const members = state.user.members;
      let mealPlanRecipes = state.mealPlanRecipes;
      let mealPlanSchedule = state.mealPlanSchedule;

      const allocatedServingValidation =
        checkIfServingAllocationIsGreaterThanRecipeServing(
          assignment,
          recipeServing
        );

      if (allocatedServingValidation.result === "isGreater") {
        dispatch({
          type: CREATE_MEAL_PLAN_RECIPE_ERROR,
          payload: {
            msg: `${allocatedServingValidation.totalServingsAllocated} servings allocated is greater than ${recipeServing} total servings`,
          },
        });
      } else {
        // isRecipeServingUpdated to true so the createRecipe knows it should edit the personalizedYield in the back-end
        let isRecipeServingUpdated = null;

        if (recipeServing !== recipeYield) {
          isRecipeServingUpdated = true;
        } else {
          isRecipeServingUpdated = false;
        }

        const { data } = await authFetch.post("/meal-plan", {
          accountUserId,
          week,
          recipeId,
          recipeServing,
          isRecipeServingUpdated,
          meal,
          assignment,
          members,
        });

        const { newCreatedRecipe } = data;

        /////////// Refactor Warning: This function is not making a copy. It's editing the original object
        // function to add the recipe to the schedule
        function addPopulatedRecipeToSchedule(
          schedule,
          meal,
          populatedRecipe,
          assignment
        ) {
          // Iterate through each assignment to find what needs to get updated
          for (const i in assignment) {
            const assignmentMemberUserId = assignment[i].memberUserId;

            const assignmentDays = assignment[i].days;

            // Iterate through each member of the schedule
            for (const member in schedule) {
              // Find the member schedule which needs to get updated with the correspoinding member assignment
              if (schedule[member].memberUserId == assignmentMemberUserId) {
                let daysMemberSchedule = schedule[member].days;

                for (const d in assignmentDays) {
                  let day = assignmentDays[d];

                  // if length is not 0, that means there is a recipeAndMealPlanYieldToAdd
                  if (day.length !== 0) {
                    // // Add the recipeAndMealPlanYieldToAdd to the meal plan schedule
                    let recipeAndMealPlanYieldToAdd = day[0];

                    recipeAndMealPlanYieldToAdd.recipe = populatedRecipe;

                    daysMemberSchedule[d].push(recipeAndMealPlanYieldToAdd);
                  }
                }
              }
            }
          }
        }

        if (mealPlanRecipes != [] && mealPlanSchedule != []) {
          // Add recipe to schedule
          addPopulatedRecipeToSchedule(
            mealPlanSchedule,
            meal,
            newCreatedRecipe,
            assignment
          );

          // Add the recipe to unique mealPlanRecipes section.
          mealPlanRecipes.push(newCreatedRecipe);
        }

        dispatch({
          type: CREATE_MEAL_PLAN_RECIPE_SUCCESS,
          payload: { mealPlanRecipes, mealPlanSchedule, name },
        });

        getShopping();
        // beginRedirect();
        endRedirect();
      }
    } catch (error) {
      if (error.response.status === 401) return;
      // if not 401, do the following
      dispatch({
        type: CREATE_MEAL_PLAN_RECIPE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const updateMealPlanRecipe = async () => {
    dispatch({ type: UPDATE_MEAL_PLAN_RECIPE_BEGIN });

    try {
      const {
        recipeId,
        recipeServing,
        recipeYield,
        meal,
        assignment,
        name,
        week,
      } = state.mealPlanServingSelection;

      const accountUserId = state.user._id;
      const members = state.user.members;
      let mealPlanRecipes = state.mealPlanRecipes;
      let mealPlanSchedule = state.mealPlanSchedule;

      const allocatedServingValidation =
        checkIfServingAllocationIsGreaterThanRecipeServing(
          assignment,
          recipeServing
        );

      if (allocatedServingValidation.result === "isGreater") {
        dispatch({
          type: CREATE_MEAL_PLAN_RECIPE_ERROR,
          payload: {
            msg: `${allocatedServingValidation.totalServingsAllocated} servings allocated is greater than ${recipeServing} total servings`,
          },
        });
      } else {
        // isRecipeServingUpdated to true so the createRecipe knows it should edit the personalizedYield in the back-end
        let isRecipeServingUpdated = null;

        if (recipeServing !== recipeYield) {
          isRecipeServingUpdated = true;
        } else {
          isRecipeServingUpdated = false;
        }

        await authFetch.patch("/meal-plan", {
          accountUserId,
          week,
          recipeId,
          recipeServing,
          isRecipeServingUpdated,
          meal,
          assignment,
          members,
        });

        // create populated recipe
        let populatedRecipe = {};

        for (const r in mealPlanRecipes) {
          if (mealPlanRecipes[r]._id == recipeId) {
            populatedRecipe = mealPlanRecipes[r];
          }
        }

        let updatedMealPlanSchedule = [];

        function copyMealPlanSchedule(mealPlanSchedule) {
          // begin updatedRecipeSchedule by making a copy and then updating the meal
          for (const i in mealPlanSchedule) {
            const mealPlanScheduleMemberUserId =
              mealPlanSchedule[i].memberUserId;

            // create empty
            updatedMealPlanSchedule.push({
              memberUserId: mealPlanScheduleMemberUserId,
              days: [[], [], [], [], [], [], []],
            });

            let mealPlanScheduleDays = mealPlanSchedule[i].days;

            for (const d in mealPlanScheduleDays) {
              let day = mealPlanScheduleDays[d];
              /// check for each item in the day, then push it into the thing

              // for each recipeAndMealPlanYieldToAdd in day
              for (const r in day) {
                const recipeAndMealPlanYieldToAdd = day[r];

                // push recipeAndMealPlanYieldToAdd
                updatedMealPlanSchedule[i].days[d].push(
                  recipeAndMealPlanYieldToAdd
                );
              }
            }
          }
        }

        copyMealPlanSchedule(mealPlanSchedule);

        // function to add the recipe to the schedule
        function addPopulatedRecipeToSchedule(
          schedule,
          // meal, // i'm not using this anymore
          populatedRecipe,
          assignment
        ) {
          // Iterate through each assignment to find what needs to get updated
          for (const i in assignment) {
            const assignmentMemberUserId = assignment[i].memberUserId;

            const assignmentDays = assignment[i].days;

            // Iterate through each member of the schedule
            for (const member in schedule) {
              // Find the member schedule which needs to get updated with the correspoinding member assignment
              if (schedule[member].memberUserId == assignmentMemberUserId) {
                let daysMemberSchedule = schedule[member].days;

                for (const d in assignmentDays) {
                  let day = assignmentDays[d];

                  // if length is not 0, that means there is a recipeAndMealPlanYieldToAdd
                  if (day.length !== 0) {
                    // // Add the recipeAndMealPlanYieldToAdd to the meal plan schedule
                    let recipeAndMealPlanYieldToAdd = day[0];

                    recipeAndMealPlanYieldToAdd.recipe = populatedRecipe;

                    daysMemberSchedule[d].push(recipeAndMealPlanYieldToAdd);
                  }
                }
              }
            }
          }
        }

        if (mealPlanRecipes != [] && mealPlanSchedule != []) {
          removeAllPopulatedRecipefromSchedule(
            updatedMealPlanSchedule,
            recipeId
          );

          // Add recipe to schedule
          addPopulatedRecipeToSchedule(
            updatedMealPlanSchedule,
            // meal, // i'm not using this anymore
            populatedRecipe,
            assignment
          );
        }

        dispatch({
          type: UPDATE_MEAL_PLAN_RECIPE_SUCCESS,
          payload: { updatedMealPlanSchedule, name },
        });
        getMealPlan();

        // beginRedirect();
        endRedirect();
      }
    } catch (error) {
      if (error.response.status === 401) return;
      // if not 401, do the following
      dispatch({
        type: UPDATE_MEAL_PLAN_RECIPE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const removeMealPlanRecipe = async (recipeId, name) => {
    dispatch({ type: REMOVE_MEAL_PLAN_RECIPE_BEGIN });

    try {
      const accountUserId = state.user._id;
      const week = state.week;
      let mealPlanRecipes = state.mealPlanRecipes;
      let mealPlanSchedule = state.mealPlanSchedule;
      const recipeName = await authFetch.delete(
        `/meal-plan?accountUserId=${accountUserId}&week=${week}&recipeId=${recipeId}`
      );

      const updatedMealPlanRecipes = mealPlanRecipes.filter(
        (i) => i._id !== recipeId
      );

      // // function to remove the recipe to the schedule
      // function removeAllPopulatedRecipefromSchedule(schedule, recipeId) {
      //   // iterate though each member
      //   for (let member in schedule) {
      //     let memberScheduleSevenDays = schedule[member].days;

      //     for (let dayNumber in memberScheduleSevenDays) {
      //       let recipesForADay = memberScheduleSevenDays[dayNumber];

      //       for (let recipeIndex in recipesForADay) {
      //         let scheduleRecipe = recipesForADay[recipeIndex];

      //         if (scheduleRecipe.recipe._id == recipeId) {
      //           // if it the recipe that needs to be removed is a match, then remove it via splice
      //           recipesForADay.splice(recipeIndex, recipeIndex + 1);
      //         }
      //       }
      //     }
      //   }
      // }

      removeAllPopulatedRecipefromSchedule(mealPlanSchedule, recipeId);

      dispatch({
        type: REMOVE_MEAL_PLAN_RECIPE_SUCCESS,
        payload: { updatedMealPlanRecipes, mealPlanSchedule, name },
      });
    } catch (error) {
      if (error.response.status === 401) return;
      // if not 401, like 400, do the following
      dispatch({
        type: REMOVE_MEAL_PLAN_RECIPE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const updateMealPlanMacrosOrSummaryView = () => {
    dispatch({
      type: UPDATE_MACROS_OR_SUMMARY_VIEW,
    });
  };

  const createRecipeInMealPlanPageAndSetServingEditPage = async () => {
    dispatch({
      type: CREATE_RECIPE_IN_MEAL_PLAN_AND_SET_SERVING_EDIT_PAGE_BEGIN,
    });

    const schedule = generateEmptySchedule(state.user.members);

    try {
      const result = await createRecipeRequest();

      const newRecipe = result.data.recipe;

      dispatch({
        type: CREATE_RECIPE_IN_MEAL_PLAN_AND_SET_SERVING_EDIT_PAGE_SUCCESS,
        payload: { newRecipe, schedule },
      });
      // beginRedirect();
      endRedirect();
    } catch (error) {
      if (error.response.status === 401) return;
      // if not 401, do the following
      dispatch({
        type: CREATE_RECIPE_IN_MEAL_PLAN_AND_SET_SERVING_EDIT_PAGE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const editRecipeMealPlanPage = async (redirectFrom) => {
    dispatch({
      type: EDIT_RECIPE_MEAL_PLAN_PAGE_BEGIN,
    });

    try {
      const result = await editRecipeRequest();

      const updatedRecipe = result.data.updatedRecipe;

      dispatch({
        type: EDIT_RECIPE_MEAL_PLAN_PAGE_SUCCESS,
        payload: { updatedRecipe, redirectFrom },
      });

      getMealPlan();

      endRedirect();
    } catch (error) {
      if (error.response.status === 401) return;
      // if not 401, do the following
      dispatch({
        type: EDIT_RECIPE_MEAL_PLAN_PAGE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  ////////////////////////////////////// SHOPPING LIST PAGE //////////////////////////////////////

  const getShopping = async () => {
    let { week } = state;

    let url = `/shopping-list?week=${week}`;

    dispatch({ type: GET_SHOPPING_BEGIN });

    try {
      const { data } = await authFetch.get(url);

      dispatch({
        type: GET_SHOPPING_SUCCESS,
        payload: {
          shoppingList:
            data.shoppingListAndMenuRecipeShoppingItems.shoppingItems,
          week: week,
          shoppingListId:
            data.shoppingListAndMenuRecipeShoppingItems.shoppingListId,
        },
      });
    } catch (error) {
      console.log(error.response);
    }

    // The MERN clears the alert since it takes 3 seconds for it to go away and it's not necessary.
    clearAlert();
  };

  const updateShoppingListGroupCategory = (e) => {
    const updatedShoppingListGroupCategory = e.target.value;

    dispatch({
      type: UPDATE_SHOPPING_LIST_GROUP_CATEGORY,
      payload: { updatedShoppingListGroupCategory },
    });
  };

  const updateShoppingListHideCheckedShoppingItem = () => {
    dispatch({ type: UPDATE_SHOPPING_LIST_HIDE_CHECKED });
  };

  const createShoppingItem = async () => {
    dispatch({ type: CREATE_SHOPPING_ITEM_BEGIN });
    try {
      const { quantity, unit, name, aisle, note } = state.shoppingItem;

      const { data } = await authFetch.post("/shopping-list", {
        shoppingListId: state.user.shoppingList[0],
        shoppingItem: [
          {
            quantity,
            unit,
            name,
            aisle,
            note,
          },
        ],
      });

      const shoppingItemsLength = data.shoppingList.shoppingItems.length;

      let addedShoppingItem =
        data.shoppingList.shoppingItems[shoppingItemsLength - 1];

      // I append the shoppingListId so it has the same structure as all of th other shoppingItems
      addedShoppingItem.shoppingListId = state.user.shoppingList[0][0];

      const newShoppingList = [...state.shoppingList, addedShoppingItem];

      let shoppingList = null;
      let newUser = { ...state.user };

      // check if the users is returned by the API. It will only do so if there is no list since it will create a new list and provide the shopppingList ID
      if (typeof data.user !== "undefined") {
        shoppingList = data.user.shoppingList;
        newUser = { ...state.user, shoppingList };
      }

      dispatch({
        type: CREATE_SHOPPING_ITEM_SUCCESS,
        payload: { newShoppingList, newUser, addedShoppingItem },
      });
      dispatch({ type: CLEAR_SHOPPING_ITEM });
    } catch (error) {
      if (error.response.status === 401) return;
      // if not 401, do the following
      dispatch({
        type: CREATE_SHOPPING_ITEM_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const setEditShoppingItem = (id) => {
    dispatch({ type: SET_EDIT_SHOPPING_ITEM, payload: { id } });
  };

  const clearShoppingItemModal = () => {
    dispatch({ type: CLEAR_SHOPPING_ITEM_VALUES });
  };

  const editShoppingListShoppingItem = async () => {
    dispatch({ type: EDIT_SHOPPING_LIST_SHOPPING_ITEM_BEGIN });

    try {
      const { user, shoppingItem } = state;

      const { data } = await authFetch.patch(`/shopping-list/shopping-item`, {
        shoppingListId: user.shoppingList[0],
        shoppingItem: shoppingItem,
      });

      const updatedShoppingList = state.shoppingList;

      dispatch({
        type: EDIT_SHOPPING_LIST_SHOPPING_ITEM_SUCCESS,
        payload: {
          updatedShoppingList,
        },
      });

      // dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      // if not 401, like 400, do the following
      dispatch({
        type: EDIT_SHOPPING_LIST_SHOPPING_ITEM_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const editRecipeShoppingItem = async () => {
    dispatch({ type: EDIT_RECIPE_SHOPPING_ITEM_BEGIN });
    try {
      const { shoppingItem, shoppingList } = state;

      const { data } = await authFetch.patch(
        `shopping-list/recipes/shopping-item`,
        {
          recipeId: shoppingItem.recipeId,
          shoppingItem: shoppingItem,
        }
      );

      const updatedShoppingItem = data.updatedShoppingItem;

      const updatedShopping = shoppingList.map((s) =>
        s._id == shoppingItem._id ? updatedShoppingItem : s
      );

      dispatch({
        type: EDIT_RECIPE_SHOPPING_ITEM_SUCCESS,
        payload: {
          updatedShopping,
        },
      });

      // dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      // if not 401, like 400, do the following
      dispatch({
        type: EDIT_RECIPE_SHOPPING_ITEM_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const removeShoppingListShoppingItem = async () => {
    dispatch({ type: REMOVE_SHOPPING_LIST_SHOPPING_ITEM_BEGIN });

    try {
      const { shoppingItem, shoppingList } = state;

      await authFetch.delete(
        `/shopping-list/shopping-item/${shoppingItem.shoppingListId}/${shoppingItem._id}`
      );

      const updatedShopping = shoppingList.filter(
        (i) => i._id !== shoppingItem._id
      );

      dispatch({
        type: REMOVE_SHOPPING_LIST_SHOPPING_ITEM_SUCCESS,
        payload: {
          updatedShopping,
        },
      });

      // dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      // if not 401, like 400, do the following
      dispatch({
        type: REMOVE_SHOPPING_LIST_SHOPPING_ITEM_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const removeRecipeShoppingItem = async () => {
    dispatch({ type: REMOVE_RECIPE_SHOPPING_ITEM_BEGIN });

    try {
      const { shoppingItem, shoppingList } = state;

      await authFetch.delete(
        `/shopping-list/recipes/shopping-item/${shoppingItem.recipeId}/${shoppingItem._id}`
      );

      const updatedShopping = shoppingList.filter(
        (s) => s._id !== shoppingItem._id
      );

      dispatch({
        type: REMOVE_RECIPE_SHOPPING_ITEM_SUCCESS,
        payload: {
          updatedShopping,
        },
      });

      // dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      // if not 401, like 400, do the following
      dispatch({
        type: REMOVE_RECIPE_SHOPPING_ITEM_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const updatedShoppingItemRecipeQuantity = (e) => {
    const { shoppingItem } = state;

    let eTargetValue = numericQuantity(e.target.value);

    let updatedShoppingItemQuantity =
      eTargetValue /
      (shoppingItem.personalizedYield / shoppingItem.recipeYield);

    if (e.target.value === "") {
      updatedShoppingItemQuantity = e.target.value;
      dispatch({
        type: UPDATE_SHOPPING_ITEM_RECIPE_QUANTITY_SUCCESS,
        payload: {
          updatedShoppingItemQuantity,
        },
      });
    } else if (isNaN(updatedShoppingItemQuantity)) {
      updatedShoppingItemQuantity = e.target.value;
      dispatch({
        type: UPDATE_SHOPPING_ITEM_RECIPE_QUANTITY_ERROR,
        payload: {
          updatedShoppingItemQuantity,
        },
      });
    } else {
      dispatch({
        type: UPDATE_SHOPPING_ITEM_RECIPE_QUANTITY_SUCCESS,
        payload: {
          updatedShoppingItemQuantity,
        },
      });
    }
    clearAlert();
  };

  const checkBoxShoppingItem = async (e) => {
    dispatch({ type: CHECK_BOX_SHOPPING_ITEM_BEGIN });
    try {
      const { shoppingList } = state;

      const shoppingItemArray = shoppingList.filter(
        (i) => i._id === e.target._id
      );

      const shoppingItem = shoppingItemArray[0];

      shoppingItem.procureShoppingItem =
        shoppingItem.procureShoppingItem === true ? false : true;

      let result = "";

      if (shoppingItem.recipeId) {
        result = await authFetch.patch(`/recipes/shopping-item`, {
          recipeId: shoppingItem.recipeId,
          shoppingItem: shoppingItem,
        });
      } else {
        result = await authFetch.patch(`/shopping-list/shopping-item`, {
          shoppingListId: shoppingItem.shoppingListId,
          shoppingItem: shoppingItem,
        });
      }

      // const updatedShoppingList = result.data.shoppingList;
      const updatedShoppingList = "this does nothing";

      dispatch({
        type: CHECK_BOX_SHOPPING_ITEM_SUCCESS,
        payload: {
          updatedShoppingList,
        },
      });

      // dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      // if not 401, like 400, do the following
      dispatch({
        type: CHECK_BOX_SHOPPING_ITEM_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const setEditShoppingItemViewRecipePage = (id) => {
    dispatch({
      type: SET_EDIT_SHOPPING_ITEM_VIEW_RECIPE_PAGE,
      payload: { id },
    });
  };

  const editRecipeShoppingItemViewRecipePage = async () => {
    dispatch({ type: EDIT_RECIPE_SHOPPING_ITEM_BEGIN_VIEW_RECIPE_PAGE });
    try {
      const { shoppingItem, editRecipeId } = state;

      const { data } = await authFetch.patch(`/recipes/shopping-item`, {
        recipeId: editRecipeId,
        shoppingItem: shoppingItem,
      });

      const recipe = data.updatedRecipe;

      dispatch({
        type: EDIT_RECIPE_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE,
        payload: {
          recipe,
        },
      });

      // dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      // if not 401, like 400, do the following
      dispatch({
        type: EDIT_RECIPE_SHOPPING_ITEM_ERROR_VIEW_RECIPE_PAGE,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const addRecipeShoppingItemViewRecipePage = async () => {
    dispatch({ type: ADD_RECIPE_SHOPPING_ITEM_BEGIN_VIEW_RECIPE_PAGE });
    try {
      const { shoppingItem, editRecipeId } = state;

      const { data } = await authFetch.patch(`/recipes/shopping-item/add`, {
        recipeId: editRecipeId,
        shoppingItem: shoppingItem,
      });

      const recipe = data.updatedRecipe;

      dispatch({
        type: ADD_RECIPE_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE,
        payload: {
          recipe,
        },
      });

      // dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      // if not 401, like 400, do the following
      dispatch({
        type: ADD_RECIPE_SHOPPING_ITEM_ERROR_VIEW_RECIPE_PAGE,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const removeRecipeShoppingItemViewRecipePage = async () => {
    dispatch({ type: REMOVE_RECIPE_SHOPPING_ITEM_BEGIN_VIEW_RECIPE_PAGE });

    try {
      const { editRecipeId, shoppingItem } = state;

      const { data } = await authFetch.delete(
        `/recipes/shopping-item/${editRecipeId}/${shoppingItem._id}`
      );

      const recipe = data.recipe;

      dispatch({
        type: REMOVE_RECIPE_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE,
        payload: {
          recipe,
        },
      });
    } catch (error) {
      if (error.response.status === 401) return;
      // if not 401, like 400, do the following
      dispatch({
        type: REMOVE_RECIPE_SHOPPING_ITEM_ERROR_VIEW_RECIPE_PAGE,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const updatedShoppingItemRecipeQuantityViewRecipePage = (e) => {
    const { personalizedYield, recipeYield } = state;

    let eTargetValue = numericQuantity(e.target.value);

    let updatedShoppingItemQuantity =
      eTargetValue / (personalizedYield / recipeYield);

    if (e.target.value === "") {
      updatedShoppingItemQuantity = e.target.value;
      dispatch({
        type: UPDATE_SHOPPING_ITEM_RECIPE_QUANTITY_SUCCESS,
        payload: {
          updatedShoppingItemQuantity,
        },
      });
    } else if (isNaN(updatedShoppingItemQuantity)) {
      updatedShoppingItemQuantity = e.target.value;
      dispatch({
        type: UPDATE_SHOPPING_ITEM_RECIPE_QUANTITY_ERROR,
        payload: {
          updatedShoppingItemQuantity,
        },
      });
    } else {
      dispatch({
        type: UPDATE_SHOPPING_ITEM_RECIPE_QUANTITY_SUCCESS,
        payload: {
          updatedShoppingItemQuantity,
        },
      });
    }
    clearAlert();
  };

  const checkBoxShoppingItemViewRecipePage = async (e) => {
    dispatch({ type: CHECK_BOX_SHOPPING_ITEM_BEGIN_VIEW_RECIPE_PAGE });
    try {
      const { recipeShoppingItems, editRecipeId } = state;

      const shoppingItemArray = recipeShoppingItems.filter(
        (i) => i._id === e.target._id
      );

      const shoppingItem = shoppingItemArray[0];

      shoppingItem.procureShoppingItem =
        shoppingItem.procureShoppingItem === true ? false : true;

      const result = await authFetch.patch(`/recipes/shopping-item`, {
        recipeId: editRecipeId,
        shoppingItem: shoppingItem,
      });

      const recipe = result.data.updatedRecipe;

      dispatch({
        type: CHECK_BOX_SHOPPING_ITEM_SUCCESS_VIEW_RECIPE_PAGE,
        payload: {
          recipe,
        },
      });

      // dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      // if not 401, like 400, do the following
      dispatch({
        type: CHECK_BOX_SHOPPING_ITEM_ERROR_VIEW_RECIPE_PAGE,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        displayAlert,
        registerUser,
        loginUser,
        logoutUser,
        updateUser,
        handleChangeMemberInfo,
        handleChange,
        handleChangeLevelTwo,
        clearValues,
        createRecipe,
        clearRecipeValues,
        removeShoppingItemAddRecipePage,
        handleChangeShoppingItemRecipePage,
        addInscructionStep,
        removeInstructionStep,
        handleChangeInstructions,
        addUtensil,
        removeUtensil,
        handleChangeUtensil,
        getRecipe,
        // Not used anymore, but keep it here for reference since I really like the logic
        // setEditRecipe,
        editRecipeAfterEveryClick,
        setRecipeEditMenu,
        deleteRecipe,
        editRecipe,
        // Menu Page
        getMenu,
        // Search Page
        getSearchPageRecipes,
        clearSearchFilters,
        //  Collection Page
        clearCollectionFilters,
        getCollectionPageRecipes,
        createRecipeInCollectionPage,
        editRecipeCollectionPage,
        // Set Edit Recipe Page
        setEditServingAddRecipe,
        setEditServingUpdateServing,
        handleChangeServingPageMeal,
        handleChangeEditServingPageServingAllocation,

        // Meal Plan
        getMealPlan,
        createMealPlanRecipe,
        updateMealPlanRecipe,
        removeMealPlanRecipe,
        updateMealPlanMacrosOrSummaryView,
        createRecipeInMealPlanPageAndSetServingEditPage,
        editRecipeMealPlanPage,
        // Shopping List
        getShopping,
        updateShoppingListGroupCategory,
        updateShoppingListHideCheckedShoppingItem,
        createShoppingItem,
        setEditShoppingItem,
        clearShoppingItemModal,
        editShoppingListShoppingItem,
        editRecipeShoppingItem,
        removeShoppingListShoppingItem,
        removeRecipeShoppingItem,
        updatedShoppingItemRecipeQuantity,
        checkBoxShoppingItem,
        setEditShoppingItemViewRecipePage,
        editRecipeShoppingItemViewRecipePage,
        addRecipeShoppingItemViewRecipePage,
        removeRecipeShoppingItemViewRecipePage,
        checkBoxShoppingItemViewRecipePage,
        // editRecipePersonalizedYield,
        updatedShoppingItemRecipeQuantityViewRecipePage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, initialState, useAppContext };
