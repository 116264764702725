import { IonContent, IonPage, IonButton, IonRouterLink } from "@ionic/react";

const NotFoundPage = () => {
  return (
    <IonPage>
      <IonContent className="ion-padding" class="ion-text-center">
        <h1>404</h1>
        <h4>Sorry, we can't find that page :(</h4>
        <IonRouterLink routerLink="/">
          <IonButton color="primary">Go Home</IonButton>
        </IonRouterLink>
      </IonContent>
    </IonPage>
  );
};

export default NotFoundPage;
