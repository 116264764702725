import { IonItem, IonLabel, IonInput } from "@ionic/react";

const FormRow = ({
  type,
  name,
  value,
  onIonChange,
  labelText,
  index,
  i,
  styleIonItem,
  disabled,
  placeholder,
  levelOneKey,
}) => {
  return (
    <IonItem style={styleIonItem}>
      <IonLabel position="stacked">{labelText}</IonLabel>
      <IonInput
        index={index}
        i={i}
        type={type}
        value={value}
        placeholder={placeholder}
        name={name}
        levelOneKey={levelOneKey}
        disabled={disabled}
        onIonChange={onIonChange}
      />
    </IonItem>
  );
};

export default FormRow;
